//這是DEC進來的第一頁
import React, { Component } from 'react';
import {Button,Grid,Label,Menu,Tab} from 'semantic-ui-react';
import { ref, firebaseAuth } from '../firebase/firebaseConfig';
import {todayTitle} from './helper/SaveToDatabase';
import DEC from './DEC';
import SpecialTool from './helper/SpecialTool';
import {Link} from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';



class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'byCustomer':'',
      'byNation':''
    };
    
  }

  componentDidMount(){
    let today=todayTitle()[0];
    let year=todayTitle()[1];
    let month=todayTitle()[2];
    let day=todayTitle()[3];

    this.setState({'Today':today});
    let customer='';
    let nation='';
    ref.child('/status/'+year+'/'+month+'/'+day+'/byNation').on('value', (snapshot)=> {
      //先把database值拿出來
      
          if(snapshot.val()==null){
          
          }else{
              let PoStatus=snapshot.val();
              
              
              if(PoStatus['KR']){
              //再把訂單資料，依照國別分開處理
              let KRPO=PoStatus['KR'];
              //把key值取出來，這樣才知道是哪幾家客戶有下單
              let KRCustomers=Object.keys(KRPO);
              
              var KRGroup = [];
              Object.keys(KRCustomers).forEach(function(key) {                  
                KRGroup.push(<Link to={`DashBoard/${KRCustomers[key]}`}  target={"_blank"}><Label color='red' size='big'>{KRCustomers[key]}</Label></Link>)
              });
              
              this.setState({'KRCustomers':KRGroup});
              

              }

              if(PoStatus['SG']){
                  //再把訂單資料，依照國別分開處理
                  let SGPO=PoStatus['SG'];
                  //把key值取出來，這樣才知道是哪幾家客戶有下單
                  let SGCustomers=Object.keys(SGPO);
                  
                  //console.log(this.state.SGCustomers);
                  
                  var SGGroup = [];
                  Object.keys(SGCustomers).forEach(function(key) {                  
                    SGGroup.push(<Link to={`DashBoard/${SGCustomers[key]}`}  target={"_blank"} ><Label color='olive' size='big'>{SGCustomers[key]}</Label></Link>)
                  });
                  
                  this.setState({'SGCustomers':SGGroup});
                  

              }

              if(PoStatus['MY']){
                  //再把訂單資料，依照國別分開處理
                  let MYPO=PoStatus['MY'];
                  //把key值取出來，這樣才知道是哪幾家客戶有下單
                  let MYCustomers=Object.keys(MYPO);
                  
                  var MYGroup = [];
                  Object.keys(MYCustomers).forEach(function(key) {                  
                    MYGroup.push(<Link to={`DashBoard/${MYCustomers[key]}`} target={"_blank"}><Label color='blue' size='big'>{MYCustomers[key]}</Label></Link>)
                  });
                  
                  this.setState({'MYCustomers':MYGroup});
                  
              }

              if(PoStatus['ID']){
                  //再把訂單資料，依照國別分開處理
                  let IDPO=PoStatus['ID'];
                  //把key值取出來，這樣才知道是哪幾家客戶有下單
                  let IDCustomers=Object.keys(IDPO);
                  
                  var IDGroup = [];
                  Object.keys(IDCustomers).forEach(function(key) {                  
                    IDGroup.push(<Link to={`DashBoard/${IDCustomers[key]}`}  target={"_blank"}><Label color='pink' size='big'>{IDCustomers[key]}</Label></Link>)
                  });
                  
                  this.setState({'IDCustomers':IDGroup});
                  
              }

              if(PoStatus['MO']){
                //再把訂單資料，依照國別分開處理
                let MOPO=PoStatus['MO'];
                
                //把key值取出來，這樣才知道是哪幾家客戶有下單
                let MOCustomers=Object.keys(MOPO);
                
                
                var MOGroup = [];
                Object.keys(MOCustomers).forEach(function(key) {                  
                  MOGroup.push(<Link to={`DashBoard/${MOCustomers[key]}`}  target={"_blank"}><Label color='yellow' size='big'>{MOCustomers[key]}</Label></Link>)
                });
                //console.log(MOGroup)
                
                this.setState({'MOCustomers':MOGroup});
                
            }

            if(PoStatus['HK']){
              //再把訂單資料，依照國別分開處理
              let HKPO=PoStatus['HK'];
              
              //把key值取出來，這樣才知道是哪幾家客戶有下單
              let HKCustomers=Object.keys(HKPO);
              
              var HKGroup = [];
              Object.keys(HKCustomers).forEach(function(key) {                 
                HKGroup.push(<Link to={`DashBoard/${HKCustomers[key]}`}  target={"_blank"}><Label color='orange' size='big'>{HKCustomers[key]}</Label></Link>)
              });
              //console.log(HKGroup)
              
              this.setState({'HKCustomers':HKGroup});
              
            }

            if(PoStatus['MIX']){
              //再把訂單資料，依照國別分開處理
              let MIXPO=PoStatus['MIX'];
              
              //把key值取出來，這樣才知道是哪幾家客戶有下單
              let MIXCustomers=Object.keys(MIXPO);
              
              
              var MIXGroup = [];
              Object.keys(MIXCustomers).forEach(function(key) {                  
                MIXGroup.push(<Link to={`DashBoard/${MIXCustomers[key]}`}  target={"_blank"}><Label color='teal' size='big'>{MIXCustomers[key]}</Label></Link>)
              });
              
              
              this.setState({'MIXCustomers':MIXGroup});
              
            }




        }

          
    });
    // 取出上傳沒成功的客戶名單
    ref.child('/status/'+year+'/'+month+'/'+day+'/nonUpLoad/HK').on('value', (snapshot)=> {
      //先把database值拿出來
      
          if(snapshot.val()==null){
          
          }else{
              let HKCustomer=snapshot.val();
              //把key值取出來，這樣才知道是哪幾家客戶有下單
              let nonUpLoad=Object.keys(HKCustomer);
              var upLoadGroup = [];
              Object.keys(nonUpLoad).forEach(function(key) {                  
                upLoadGroup.push(<Label color='purple' size='big'>{nonUpLoad[key]}</Label>)
              });
              
              this.setState({'upLoadGroup':upLoadGroup});
          }

          
    });
     
  }



  render() {

    const panes = [
      { menuItem: (
        <Menu.Item style={{ 'font-weight': 'bold','font-size':'18px'}} position='left'>
            今日訂單狀況儀表板
        </Menu.Item>
        ), 

      render: () => 
        <Tab.Pane >
            <div style={{ padding: '5em 5em','font-size':'26px','font-weight':'bold','background-color': 'rgb(244, 173, 66)'}}>
               
                <Grid columns={2} textAlign='center' divided='vertically' style={{ 'font-size':'26px','font-weight':'bold'}}> 
                     <Grid.Row divided>
                      <Grid.Column style={{ padding: '0em 0em 3em 3em' }}>
                        <Label color='purple' tag style={{ 'font-size':'30px'}}>
                         香港API上傳狀況
                        </Label>
                        
                      </Grid.Column>
                      <Grid.Column textAlign='left'>
                        {this.state.upLoadGroup?
                          <div>{this.state.upLoadGroup}</div>
                          :
                          <div>一切正常</div>
                        }
                      </Grid.Column>      
                     </Grid.Row>
                    <Grid.Row divided>
                      <Grid.Column style={{ padding: '0em 0em 3em 3em' }}>
                        <Label color='orange' tag style={{ 'font-size':'30px'}}>
                        香港訂單狀況
                        </Label>
                        
                      </Grid.Column>
                      <Grid.Column textAlign='left'>
                        {this.state.HKCustomers?
                          <div>{this.state.HKCustomers}</div>
                          :
                          <div>目前沒有訂單</div>
                        }
                      </Grid.Column>      
                     </Grid.Row>
                    <Grid.Row divided>
                      <Grid.Column style={{ padding: '0em 0em 3em 3em' }}>
                        <Label color='red' tag style={{ 'font-size':'30px'}}>
                          韓國訂單狀況
                        </Label>
                        
                      </Grid.Column>
                      <Grid.Column textAlign='left'>
                        {this.state.KRCustomers?
                          <div>{this.state.KRCustomers}</div>
                          :
                          <div>目前沒有訂單</div>
                        }
                      </Grid.Column>      
                    </Grid.Row>

                    <Grid.Row divided>
                      <Grid.Column style={{ padding: '0em 0em 3em 3em' }}>
                        <Label color='olive' tag style={{ 'font-size':'30px'}}>
                          新加坡訂單狀況
                        </Label>
                        
                      </Grid.Column>
                      <Grid.Column textAlign='left'>
                        {this.state.SGCustomers?
                          <div>{this.state.SGCustomers}</div>
                          :
                          <div>目前沒有訂單</div>
                        }
                      </Grid.Column>      
                    </Grid.Row>

                    <Grid.Row divided>
                      <Grid.Column style={{ padding: '0em 0em 3em 3em' }}>
                        <Label color='blue' tag style={{ 'font-size':'30px'}}>
                        馬來西亞訂單狀況
                        </Label>
                      
                      </Grid.Column>
                      <Grid.Column textAlign='left'>
                        {this.state.MYCustomers?
                          <div>{this.state.MYCustomers}</div>
                          :
                          <div>目前沒有訂單</div>
                        }
                      </Grid.Column>      
                    </Grid.Row>

                    <Grid.Row divided>
                      <Grid.Column style={{ padding: '0em 0em 3em 3em' }}>
                        <Label color='pink' tag style={{ 'font-size':'30px'}}>
                        印尼訂單狀況
                        </Label>
                        
                      </Grid.Column>
                      <Grid.Column textAlign='left'>
                        {this.state.IDCustomers?
                          <div>{this.state.IDCustomers}</div>
                          :
                          <div>目前沒有訂單</div>
                        }
                      </Grid.Column>      
                    </Grid.Row>

                    <Grid.Row divided>
                      <Grid.Column style={{ padding: '0em 0em 3em 3em' }}>
                        <Label color='yellow' tag style={{ 'font-size':'30px'}}>
                        澳門訂單狀況
                        </Label>
                        
                      </Grid.Column>
                      <Grid.Column textAlign='left'>
                        {this.state.MOCustomers?
                          <div>{this.state.MOCustomers}</div>
                          :
                          <div>目前沒有訂單</div>
                        }
                      </Grid.Column>      
                     </Grid.Row>

                     <Grid.Row divided>
                      <Grid.Column style={{ padding: '0em 0em 3em 3em' }}>
                        <Label color='teal' tag style={{ 'font-size':'30px'}}>
                        萬用格式訂單狀況
                        </Label>
                        
                      </Grid.Column>
                      <Grid.Column textAlign='left'>
                        {this.state.MIXCustomers?
                          <div>{this.state.MIXCustomers}</div>
                          :
                          <div>目前沒有訂單</div>
                        }
                      </Grid.Column>      
                     </Grid.Row>

                    
                </Grid>
            
            </div>
        </Tab.Pane> 
      },

      { menuItem: (
        <Menu.Item style={{'font-weight': 'bold', 'font-size':'18px'}} position='left'>
             公司自製標籤儀表板
        </Menu.Item>
        ),
         render: () => <Tab.Pane><DEC/></Tab.Pane> },
      
      { menuItem: (
        <Menu.Item style={{'font-weight': 'bold', 'font-size':'18px'}} position='left'>
           <Link to="/IDService" target="_blank" style={{ 'color':'black'}}>印尼Key單系統</Link>
        </Menu.Item>
        ),
         
      },

      { menuItem: (
        <Menu.Item style={{ 'font-weight': 'bold','font-size':'18px'}} position='left'>
           特殊功能鍵
        </Menu.Item>
        ),
         render: () => <Tab.Pane><SpecialTool/></Tab.Pane> 
      },
      { menuItem: (
        <Menu.Item style={{ 'font-weight': 'bold','font-size':'18px'}} position='left'>
            <Link to="/ScanToCheck" target="_blank" style={{ 'color':'black'}}>點貨進單</Link>
        </Menu.Item>
        ),
       
      },

     
    ]

    

    return (
      <div  style={{ padding: '0em 5em' }}>
       <h1>您好!目前您已經進入跨境通的管理介面。</h1>
       <h2>今天日期是{this.state.Today}</h2>

       <Tab panes={panes} style={{'background-color': 'rgb(236, 147, 40)' }}/>
        
      </div>
    );
  }
}

export default App;
