import React, { Component } from 'react';
import {Button,Table,Form,Grid,Segment,Label,Radio} from 'semantic-ui-react';
import {ref, firebaseAuth} from '../../firebase/firebaseConfig';
import 'semantic-ui-css/semantic.min.css';


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
          //這個每個客人都要改
          'customerNumber':'20038',
          'queryOption':'phone',
          'queryKey':'',
          'prepareOrder':'',
          'finishOrder':'',
          'prepareOrderQueryMessage':'',
          'finishOrderQueryMessage':''
        };  

        this.changeQueryOption=this.changeQueryOption.bind(this);
        this.handleInputChange=this.handleInputChange.bind(this);
        this.handleQuery=this.handleQuery.bind(this);
    }

    componentDidMount (){
        var userid = firebaseAuth.currentUser.uid;
        //console.log(userid)
        this.setState({
          'userid':userid,
        })
            
    }


    changeQueryOption(e,{ value }){
        
        this.setState({
          'queryOption':value,
        }) 
      
    }

    handleInputChange(e){
        this.setState({ [e.target.name]: e.target.value });  
        //console.log(this.state)
    }


    handleQuery(e){
        if(this.state.queryOption=='phone'){
            //先找未寄送訂單資料夾
            ref.child('/infos/customers/'+this.state.customerNumber+'/OrderInfo/'+this.state.userid).orderByChild('receiverPhoneNumber').equalTo(this.state.queryKey).once('value', (snapshot)=> {
                if(snapshot.val()){
                    let queryResult=snapshot.val();
                    var dataOnScreen=[]
                    Object.keys(queryResult).forEach((key)=> {
                        var po=queryResult[key];
                        dataOnScreen.push(
                            <Table.Row >                            
                                <Table.Cell >
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.deliveryNumber}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.contact}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.receiverPhoneNumber}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.總箱數}</Grid>
                                </Table.Cell>
                                
                            </Table.Row>
                        )

                        this.setState({
                            prepareDataOnScreen:dataOnScreen,
                            queryKey:''
                          });

                    })
                }else{
                    this.setState({
                        prepareDataOnScreen:'查無資料',
                        queryKey:''
                    })
                } 
            })

            ref.child('/infos/customers/'+this.state.customerNumber+'/Shipped/'+this.state.userid).orderByChild('receiverPhoneNumber').equalTo(this.state.queryKey).once('value', (snapshot)=> {
                if(snapshot.val()){
                    let queryResult=snapshot.val();
                    var dataOnScreen=[]
                    Object.keys(queryResult).forEach((key)=> {
                        var po=queryResult[key];
                        dataOnScreen.push(
                            <Table.Row >                            
                                <Table.Cell >
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.deliveryNumber}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.contact}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.receiverPhoneNumber}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.總箱數}</Grid>
                                </Table.Cell>
                                
                            </Table.Row>
                        )

                        this.setState({
                            finishDataOnScreen:dataOnScreen,
                            queryKey:''
                          });

                    })
                }else{
                    this.setState({
                        finishDataOnScreen:'查無資料',
                        queryKey:''
                    })
                } 
            })

        }else{
            //下面這一段是要找客戶名稱
            ref.child('/infos/customers/'+this.state.customerNumber+'/OrderInfo/'+this.state.userid).orderByChild('contact').equalTo(this.state.queryKey).once('value', (snapshot)=> {
                if(snapshot.val()){
                    let queryResult=snapshot.val();
                    var dataOnScreen=[]
                    Object.keys(queryResult).forEach((key)=> {
                        var po=queryResult[key];
                        dataOnScreen.push(
                            <Table.Row >                            
                                <Table.Cell >
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.deliveryNumber}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.contact}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.receiverPhoneNumber}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.總箱數}</Grid>
                                </Table.Cell>
                                
                            </Table.Row>
                        )

                        this.setState({
                            prepareDataOnScreen:dataOnScreen,
                            queryKey:''
                          });

                    })
                }else{
                    this.setState({
                        prepareDataOnScreen:'查無資料',
                        queryKey:''
                    })
                } 
            })

            ref.child('/infos/customers/'+this.state.customerNumber+'/Shipped/'+this.state.userid).orderByChild('contact').equalTo(this.state.queryKey).once('value', (snapshot)=> {
                if(snapshot.val()){
                    let queryResult=snapshot.val();
                    var dataOnScreen=[]
                    Object.keys(queryResult).forEach((key)=> {
                        var po=queryResult[key];
                        dataOnScreen.push(
                            <Table.Row >                            
                                <Table.Cell >
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.deliveryNumber}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.contact}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.receiverPhoneNumber}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.總箱數}</Grid>
                                </Table.Cell>
                                
                            </Table.Row>
                        )

                        this.setState({
                            finishDataOnScreen:dataOnScreen,
                            queryKey:''
                          });

                    })
                }else{
                    this.setState({
                        finishDataOnScreen:'查無資料',
                        queryKey:''
                    })
                } 
            })


        } 
    }

    render() {
  
       return (
           
           <div style={{ 'padding-top': '0.5em','padding-left': '2em','padding-right': '2em','padding-bottom': '2em',width:'80%',margin: 'auto'}}>
             <Grid  divided='vertically' centered >
                 <Grid.Row columns={1}  style={{ height:180}}> 
                    <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                       <Form style={{'font-size':'16px'}}>
                             <Form.Group widths='equal' > 
                                 <Form.Field>
                                     <label style={{'font-size':'12px'}}>查詢類別</label>
                                    
                                     <Form.Group style={{ 'padding-right': '0em'}} >
                                            <Form.Field
                                                control={Radio}
                                                label='電話'
                                                value='phone'
                                                checked={this.state.queryOption=== 'phone'}
                                                onChange={this.changeQueryOption}
                                                style={{'font-size':'18px','font-weight':'bolder'}}
                                            />
                                            
                                            <Form.Field
                                                control={Radio}
                                                label='客戶名'
                                                value='name'
                                                checked={this.state.queryOption=== 'name'}
                                                onChange={this.changeQueryOption}
                                                style={{'font-size':'18px','font-weight':'bolder'}}
                                            />

                                     </Form.Group>
                                     <Form.Group style={{ 'padding-right': '0.3em', width:'60%'}} >
                                            <label size='mini' style={{'font-size':'12px','font-weight':'bolder',width:70}}>請輸入查詢內容</label>
                                            <input placeholder='請輸入' value={this.state.queryKey} size='large' name="queryKey" onChange={this.handleInputChange} style={{ padding: '0.3em', }}/>
                                            <Button icon='search' label='查詢'  color='red' onClick={this.handleQuery} style={{ 'font-size':'12px',width:120,'padding-left': '1em'}}/>
                                     </Form.Group>
                                     
                                     <Form.Group style={{ 'padding-right': '0.3em', width:'60%'}} >
                                     <a href="http://www.declogistics.com.tw/h/DataDetail?key=amqeg&cont=250398" target="_blank" style={{ 'font-weight':'bolder'}}>跨境通查單系統請點</a>
                                     </Form.Group>
                                 </Form.Field> 
                             </Form.Group>
                       </Form>
                    </Grid.Column>
                 </Grid.Row>

                 <Grid.Row columns={1}>  
                     <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                     
                       <Segment raised>  
                             <Label as='a' color='red' ribbon>
                             訂單已經寄送
                             </Label>
                             
                             <Table celled >
                                    <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>快遞單號</Grid></Table.HeaderCell>
                                        <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>收件人</Grid></Table.HeaderCell>
                                        <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>收件連絡電話</Grid></Table.HeaderCell>
                                        <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>件數</Grid></Table.HeaderCell>
                                
                                    </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {this.state.finishDataOnScreen}
                                    </Table.Body>

                            </Table>
                                 
                       </Segment>
                    
                     </Grid.Column>
                 </Grid.Row>
                 
                 <Grid.Row columns={1}>  
                     <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                     
                       <Segment raised>  
                             <Label as='a' color='pink' ribbon>
                             訂單尚未寄送
                             </Label>

                             <Table celled >
                                    <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>快遞單號</Grid></Table.HeaderCell>
                                        <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>收件人</Grid></Table.HeaderCell>
                                        <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>收件連絡電話</Grid></Table.HeaderCell>
                                        <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>件數</Grid></Table.HeaderCell>
                                
                                    </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {this.state.prepareDataOnScreen}
                                    </Table.Body>

                            </Table>
                             
                            
                                 
                       </Segment>
                    
                     </Grid.Column>
                 </Grid.Row>
             </Grid>
           </div>
    
        
    
    
    
    
       );
      
    }
    


}
export default App;