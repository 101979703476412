import React, { Component } from 'react';

import { firebaseAuth,ref,firebase} from './firebase/firebaseConfig';
import 'semantic-ui-css/semantic.min.css';
import Components from './Customers/helper/ComponentIndex';

import {Link} from 'react-router-dom';
import {
  Button,
  Input,
  Header,
  Segment,
} from 'semantic-ui-react';




class App extends Component {

  constructor(props) {
    super(props);
    this.state = {

      'haveAccount':'1',
      'create':"",
      'login':"",
      'companyCode':"",
      'userModule':"",

  };

    this.handleEnter=  this.handleEnter.bind(this);
  }

  componentDidMount(){
    
    firebase.auth().signOut()
  }
  
  handleEnter(){

          if(this.state.email&&this.state.password){
                var errorCode ="";
                var errorMessage="";
                var companyCode='';
                var userModule='';

                firebaseAuth.signInWithEmailAndPassword(this.state.email, this.state.password).catch(function(error) {
                    // Handle Errors here.
                    errorCode = error.code;
                    errorMessage = error.message;
                    console.log(errorCode)
                 }).then(()=>{
                   if (errorCode == 'auth/user-not-found') {
                    //關鍵寫法
                       this.setState({'create':'您的帳號好像不存在喔!!'})
                  }
                 });

                firebaseAuth.onAuthStateChanged((user)=>{
                      if (user){
                         this.setState({'login':1});

                          ref.child('/users/'+user.uid+'/companyCode').once('value').then((snapshot)=>{

                          if(snapshot.val()){
                            companyCode=snapshot.val();
                            this.setState({'companyCode':companyCode});
                          }

                         });



                          ref.child('/users/'+user.uid+'/userModule').once('value').then((snapshot)=>{

                            if(snapshot.val()){
                              userModule=snapshot.val()
                              this.setState({'userModule':userModule});
                            }else{
                              this.setState({'userModule':'Standard'});
                            }




                        });
                      }
                 });




          }else{
              this.setState({'create':'您的帳號、密碼不能空白喔!!'})
          };

          

  }


  render() {

    var MyComponent='C'+this.state.userModule;
    const ComponentToRender = Components[MyComponent];

    const { email, password } = this.state;

    return (
      <div>
        {this.state.login&&this.state.userModule?
            <Segment
            inverted
            textAlign='center'
            style={{ 'background-color': 'rgb(244, 173, 66)' }}
            vertical>

             <ComponentToRender/>

            </Segment>
            :
            <Segment
            inverted
            textAlign='center'
            style={{ 'background-color': 'rgb(244, 173, 66)' }}
            vertical>

                  <div>
                  <Header as='h1'>歡迎光臨跨境通 您的貨有我就通</Header>
                  <form onSubmit={e => e.preventDefault()}>
                    <Input
                      type="text"
                      placeholder="請輸入郵件帳號"
                      onChange={e => this.setState({ email: e.target.value })}
                      size='large'
                    />
                    <br />
                    <h6></h6>
                    <Input
                      type="password"
                      placeholder="請輸入密碼"
                      onChange={e => this.setState({ password: e.target.value })}
                      size='large'
                    />
                    <br />
                    <h6></h6>

                    <Button
                      type="submit"
                      onClick={this.handleEnter}
                      size='large'
                      style={{ color: 'gray' }}
                    >
                      登入
                    </Button>
                    
                    <Button
                      onClick={this.handleCreate}
                      size='large'>
                      <Link to="/CreateAccount"  style={{ color: 'gray' }}>創建新帳號</Link>
                    </Button>
                    {/*
                    
                    */}
                  </form>

                  </div>
                  <h1>{this.state.create}</h1>
            </Segment>
        }

       </div>



    )


 }
}

//歡迎光臨跨境通 您的貨有我就通

export default App;
