//firebaseConfig.js
//

import firebaseApp from "firebase";

// https://firebase.google.com/docs/web/setup?authuser=0

// See firebase setup in above google firebase documentation url
const config = {
    apiKey: "AIzaSyBXgdthCTZwcPOGoCFW6gmtrdNOpNfjP2A",
    authDomain: "superdelivery-928ad.firebaseapp.com",
    databaseURL: "https://superdelivery-928ad.firebaseio.com",
    projectId: "superdelivery-928ad",
    storageBucket: "superdelivery-928ad.appspot.com",
    messagingSenderId: "180300817126"
};

firebaseApp.initializeApp(config);

export const firebase = firebaseApp;
export const ref = firebaseApp.database().ref();
export const firebaseAuth = firebaseApp.auth();
export const firebaseStorage = firebaseApp.storage();
