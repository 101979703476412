import React, { Component } from 'react';
import {Button,Table,Form,Grid,Segment,Label,Loader,Modal} from 'semantic-ui-react';
import {todayTitle} from './SaveToDatabase';
import {ref,firebase} from '../../firebase/firebaseConfig';
import {LineClearLogoImg,textToBase64Barcode} from './Helper';
import pdfmake from 'pdfmake/build/pdfmake'
import pdffonts from 'pdfmake/build/vfs_fonts';
import 'semantic-ui-css/semantic.min.css';


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
          customerNumber:'20038',
          // customerNumber:'test',
          orderNumber:'',
          year:todayTitle()[1],
          month:todayTitle()[2],
          day:todayTitle()[3],
          todayOrderList: [],
          loading:''

        };  

        this.checkOrder=this.checkOrder.bind(this);
        this.rePrint=this.rePrint.bind(this);
        this.printTodayAllLabel=this.printTodayAllLabel.bind(this)
        this.print80071Label=this.print80071Label.bind(this)
        
    }

    componentDidMount (){
        //為了要打開就可以掃碼
        this.queryKey.focus()
        //為了把customer key拿出來
        //把未寄貨的資料秀出來
        var target=''
        ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip').on('value', (snapshot)=> {
             if(snapshot.val()){
                      let queryResult=snapshot.val();
                        
                      Object.keys(queryResult).forEach((key)=> {
                          target=key
                      })
                    
                      var waitToShip=[]
                      Object.keys(queryResult[target]).forEach((key)=>{
                        var po=queryResult[target][key];
                        waitToShip.push(
                            <Table.Row style={{'height':44 }} >                            
                                <Table.Cell >
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.deliveryNumber}</Grid>
                                </Table.Cell>
                              
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.總箱數}</Grid>
                                </Table.Cell>
                                                  
                            </Table.Row>
                        )
                      })
                      

                      this.setState({
                          customerkey:target,
                          waitToShip:waitToShip
                      })

             }
                
        })
        

        //顯示今日已進單
        ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/MY').on('value', (snapshot)=> {
          
          if(snapshot.val()){
              
              let target=snapshot.val();
              let queryResult=''
              Object.keys(target).forEach((key)=> {
                queryResult=target[key]
              })
              let dataOnScreenMY=[]
              var keyList=[]
              Object.keys(queryResult).forEach((key)=> {
                  keyList.push(key)
              })
              
              keyList=keyList.reverse()
              keyList.forEach((key)=>{
                var po=queryResult[key];
                dataOnScreenMY.push(
                    <Table.Row style={{'height':44 }} >                            
                        <Table.Cell >
                            <Grid centered style={{'padding-top':'0.3em' }}>{po.deliveryNumber}</Grid>
                        </Table.Cell>
                        <Table.Cell>
                            <Grid centered style={{'padding-top':'0.3em' }}>{po.contact}</Grid>
                        </Table.Cell>
                        <Table.Cell>
                            <Grid centered style={{'padding-top':'0.3em' }}>{po.receiverPhoneNumber}</Grid>
                        </Table.Cell>
                        <Table.Cell>
                            <Grid centered style={{'padding-top':'0.3em' }}>{po.總箱數}</Grid>
                        </Table.Cell>
                        <Table.Cell>
                              <Grid centered style={{'padding-top':'0.3em' }}><Button basic  style={{'font-size':'14px'}} name={po.deliveryNumber} onClick={this.rePrint}>印製標籤</Button></Grid>
                        </Table.Cell>                    
                    </Table.Row>
                )
                
                this.setState({
                    //改成對應國別
                    dataOnScreenMY:dataOnScreenMY,
                });
              });
          }
      
        })
        ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/SG').on('value', (snapshot)=> {
          
          if(snapshot.val()){
              
              let target=snapshot.val();
              let queryResult=''
              Object.keys(target).forEach((key)=> {
                queryResult=target[key]
              })
              let dataOnScreenSG=[]
              var keyList=[]
              Object.keys(queryResult).forEach((key)=> {
                  keyList.push(key)
              })
              keyList=keyList.reverse()
              keyList.forEach((key)=>{
                var po=queryResult[key];
                dataOnScreenSG.push(
                    <Table.Row style={{'height':44 }} >                            
                        <Table.Cell >
                            <Grid centered style={{'padding-top':'0.3em' }}>{po.deliveryNumber}</Grid>
                        </Table.Cell>
                        <Table.Cell>
                            <Grid centered style={{'padding-top':'0.3em' }}>{po.contact}</Grid>
                        </Table.Cell>
                        <Table.Cell>
                            <Grid centered style={{'padding-top':'0.3em' }}>{po.receiverPhoneNumber}</Grid>
                        </Table.Cell>
                        <Table.Cell>
                            <Grid centered style={{'padding-top':'0.3em' }}>{po.總箱數}</Grid>
                        </Table.Cell>
                        <Table.Cell>
                              <Grid centered style={{'padding-top':'0.3em' }}></Grid>
                        </Table.Cell>                    
                    </Table.Row>
                )
  
                this.setState({
                    //改成對應國別
                    dataOnScreenSG: dataOnScreenSG,
                });
              });
          }
      
        })
        
        ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/JP').on('value', (snapshot)=> {
          
          if(snapshot.val()){
              
              let target=snapshot.val();
              let queryResult=''
              Object.keys(target).forEach((key)=> {
                queryResult=target[key]
              })
              let dataOnScreenSG=[]
              var keyList=[]
              Object.keys(queryResult).forEach((key)=> {
                  keyList.push(key)
              })
              keyList=keyList.reverse()
              keyList.forEach((key)=>{
                var po=queryResult[key];
                dataOnScreenSG.push(
                    <Table.Row style={{'height':44 }} >                            
                        <Table.Cell >
                            <Grid centered style={{'padding-top':'0.3em' }}>{po.deliveryNumber}</Grid>
                        </Table.Cell>
                        <Table.Cell>
                            <Grid centered style={{'padding-top':'0.3em' }}>{po.contact}</Grid>
                        </Table.Cell>
                        <Table.Cell>
                            <Grid centered style={{'padding-top':'0.3em' }}>{po.receiverPhoneNumber}</Grid>
                        </Table.Cell>
                        <Table.Cell>
                            <Grid centered style={{'padding-top':'0.3em' }}>{po.總箱數}</Grid>
                        </Table.Cell>
                        <Table.Cell>
                              <Grid centered style={{'padding-top':'0.3em' }}></Grid>
                        </Table.Cell>                    
                    </Table.Row>
                )
  
                this.setState({
                    //改成對應國別
                    dataOnScreenSG: dataOnScreenSG,
                });
              });
          }
      
        })
            
    }


    checkOrder(e){
        this.setState({ [e.target.name]: e.target.value }); 
        const doTheRest=()=>{
              var key=e.target.value
              ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+this.state.customerkey+'/'+key).once('value', (snapshot)=> {
                  if(snapshot.val()){
                      let target=snapshot.val()
                      target.快遞單號=target.deliveryNumber
                      target.訂單單號=target.poNumber
                      target.收件人姓名=target.contact 
                      target.收件連絡電話=target.receiverPhoneNumber  
                      let nation=target.國別
                      let content='';
                      let contentCalculateBase=[];
                      for(var i=0;i<target.itemNumber;i++){
                              var number=i+1
                              var item=target['itemName'+number]+'*'+target['itemNumber'+number]+'  '
                              content=content+item
                              contentCalculateBase[target['itemName'+number]]={'Number':target['itemNumber'+number],'Amount':target['itemAmount'+number]}           
                      }

                      //把訂單轉為成單狀態給客人查單
                      ref.child('/infos/customers/'+this.state.customerNumber+'/Shipped/'+this.state.customerkey+'/'+target.deliveryNumber).set(target)

                      if (nation==='MY'){
                          //把訂單轉進跨境通要查的狀態
                          ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/MY/'+this.state.customerkey+'/'+target.deliveryNumber).set(target)
                          //把要計算的內容數量存進資料夾
                          ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/contentCalculateBase/MY/'+this.state.customerkey+'/'+target.deliveryNumber).set(contentCalculateBase)
                      }else if (nation==='SG'){
                          //把訂單轉進跨境通要查的狀態
                          ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/SG/'+this.state.customerkey+'/'+target.deliveryNumber).set(target)
                          //把要計算的內容數量存進資料夾
                          ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/contentCalculateBase/SG/'+this.state.customerkey+'/'+target.deliveryNumber).set(contentCalculateBase)
                      }else if (nation==='JP'){
                        //把訂單轉進跨境通要查的狀態
                        ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/JP/'+this.state.customerkey+'/'+target.deliveryNumber).set(target)
                        //把要計算的內容數量存進資料夾
                        ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/contentCalculateBase/JP/'+this.state.customerkey+'/'+target.deliveryNumber).set(contentCalculateBase)
                      }
                      //刪除prepare訂單裡面的資料
                      ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+this.state.customerkey+'/'+target.deliveryNumber).set({})
                      ref.child('/infos/customers/'+this.state.customerNumber+'/OrderInfo/'+this.state.customerkey+'/'+target.deliveryNumber).set({})
               
                      
                      // let todayOrderList=this.state.todayOrderList
                      // if(nation==='MY'){
                      //     todayOrderList.push(target)
                      // }

                      
                      let message=key+'完成進單'
                      this.setState({
                          message         : message,
                          // todayOrderList  : todayOrderList
                      })


                  }else{
                      let message=key+'沒有找到訂單或是已經進單'
                      this.setState({
                          'message' : message
                      })

                  }
              })

              this.setState({
                  queryKey:''
              })
              this.queryKey.focus()

        }
        if(e.target.value.slice(0, 3)==='ZND' && e.target.value.length===15){
               doTheRest() 
        }else if(e.target.value.slice(0, 2)==='MY' && e.target.value.length===14){
               doTheRest() 
        }else if(e.target.value.slice(0, 2)==='SG' && e.target.value.length===15){
               doTheRest()
        }else if( e.target.value.length===10){
               doTheRest()
        }
        // }else if(e.target.value.slice(0, 2)==='JP' && e.target.value.length===14){
        //   doTheRest()
        
    }

    rePrint(e){
      var targetDeliveryNumber=e.target.name
      ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/MY/'+this.state.customerkey+'/'+targetDeliveryNumber).on('value', (snapshot)=> {
        //console.log(snapshot.val())
        if(snapshot.val()){
          //console.log(snapshot.val())
           var target=snapshot.val()
           var content='';
           var contentCalculateBase=[];
           for(var i=0;i<target.itemNumber;i++){
                   var number=i+1
                   var item=target['itemName'+number]+'*'+target['itemNumber'+number]+'  '
                   content=content+item
                   contentCalculateBase[target['itemName'+number]]={'Number':target['itemNumber'+number],'Amount':target['itemAmount'+number]}           
           }
           var logo=''//LogoImg();
           var dataURL=textToBase64Barcode(target.deliveryNumber);
           var acNumber='N6900038'
           var currency='MYR'
           var amount=Number((target.amount/7.2).toFixed(1))
           pdfmake.vfs = pdffonts.pdfMake.vfs;
           let Label=[
                 { table: {
                   widths: ['auto', '*', '*', '*', '*', '*'],
                   //下面是要做一開始的logo、barcode、和國碼標
                   body: [
                     [
                       {
                       // you'll most often use dataURI images on the browser side
                       // if no width/height/fit is provided, the original size will be used
                       image:logo,
                       width:100, 
                       height:20,
                       alignment: 'center',
                       colSpan: 3},
                       '',
                       '',
                       { rowSpan: 2,
                         colSpan: 3,
                         text:  target['國別'],
                         style: 'head',  
                         width:100, 
                         alignment: 'center',          
                       },
                       '',
                       ''
           
                     ],
                     [{ image:dataURL,
                       colSpan: 3,
                       width:140, 
                       height:60,
                       alignment: 'center',
                     },
                     {},
                     {},
                     {},
                     {},
                     {}
                     ],
                     //這是馬來西亞標籤特別要加的
                     [{ text: 'A/C NO:'},
                     {text: acNumber,
                       colSpan: 5},
                     {},
                     {},
                     {},
                     {}
                     ],
                     [{ text: 'PO:'},
                     {text:target.poNumber,colSpan: 5},
                     {},
                     {},
                     {},
                     {}
                     ],
           
                     [{text: 'Shipper:'},
                     {text:'ROBINMAY',colSpan: 5},
                     {},
                     {},
                     {},
                     {}
                     ],
           
                     [{text: 'Consignee:'},
                     {text: target.contact,colSpan: 5},
                     {},
                     {},
                     {},
                     {}
                     ],
           
                     [{text: 'TEL:'},
                     {text: target.receiverPhoneNumber,colSpan: 5},
                     {},
                     {},
                     {},
                     {}
                     ],
           
                     [{text: 'Address:',rowSpan: 2},
                     {text: target['收件地址'],rowSpan: 2,colSpan: 5},
                     {},
                     {},
                     {},
                     {}
                     ],
                     [{},
                     {},
                     {},
                     {},
                     {},
                     {}
                     ],
           
                     [{text: 'Declared Value:'},
                     {text: currency+amount,colSpan: 5},
                     {},
                     {},
                     {},
                     {}
                     ],
           
                     [{text: 'Weight:'},
                     //KGS前面後一格，看起來比較好看
                     {text:target['總重量-公斤']+' KGS   -'+target['總箱數']+' Boxes',colSpan: 5},
                     {},
                     {},
                     {},
                     {}
                     ],
                     [{text: 'Description:'},
                     {text: content,colSpan: 5},
                     {},
                     {},
                     {},
                     {}
                     ],
           
                   ]
                 },
                },     
               ];
               
   
           pdfmake.fonts = {
             ch: {
             normal: 'kaiu.ttf',
             bold: 'kaiu.ttf',
             italics: 'kaiu.ttf',
             bolditalics: 'kaiu.ttf'
           }};
     
         var docDefinition = {
                 content:Label,
                 defaultStyle: {
                 font: 'ch',
                 fontSize: 11
                 } ,
                 styles: {
                   head: {
                     
                     fontSize: 66,
                     bold: true
                   },
     
                 },
     
                 // a string or { width: number, height: number }
                 // 下面是10*10的標籤設計
                 pageSize: { width: 283, height: 283},
                 
     
                 // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                 pageMargins: [ 3, 3,3, 0 ],
         };
         
         //準備出單印製
         pdfmake.createPdf(docDefinition).print();
   
         }
   
      })
   
   }

    printTodayAllLabel(){
      var todayOrderList=this.state.todayOrderList
      
      //這裡要開啟loading的指標符號
      this.setState({
        loading:true,
      })

      const infoUpload = {
        "Shipment": []
      }

      for (let i=0; i<todayOrderList.length; i++) {
        let order=todayOrderList[i]
        var weight=(Number(order['總重量-公斤'])/Number(order['總箱數'])).toFixed(1)
        let wayBill = [];
        for (let j=0; j<order['總箱數']; j++) {
            wayBill.push(
              {
                "Weight": weight,
                "VolumeWidth": "1",                                                         
                "VolumeHeight": "1",
                "VolumeLength": "1"

              }
            )
        }
        let info={
                    "ShipmentServiceType": "Standard Delivery",
                    "SenderName": "MR TIEN",
                    "RecipientName": order['收件人姓名'],
                    "ShipmentAddressFrom":  {
                        "CompanyName": "FREEGO TRADING AND LOGISTICS",
                        "UnitNumber": "26",
                        "Address": "No 26,Jalan KU5, Pusat Komersial Universiti,Seremban 3,70300,Seremban,Negeri Sembilan,Malaysia",
                        "Address2": "",
                        "PostalCode": "70300",
                        "City": "",
                        "State": "",
                        "Email": "",
                        "PhoneNumber": "60126368616",
                        "ICNumber": ""
                    },
                    "ShipmentAddressTo":   {
                        "CompanyName": "",
                        "UnitNumber": "5",
                        "Address": order['收件地址'],
                        "Address2": "",
                        //下面是測試版，正式上線要改成客人的郵遞區號
                        "PostalCode": order['收件人郵遞區號'],
                        "City": "",
                        "State": "",
                        "Email": "",
                        //下面是測試版，正式上線要改成客人的電話號碼
                        "PhoneNumber": order['收件連絡電話'],
                        "ICNumber": ""
                    },
                    //下面是測試版，正式上線要改成客人的電話號碼
                    "RecipientPhone": order['收件連絡電話'],
                    "ParcelType": "Package",
                    "ShipmentRef": "",
                    "ShipmentDescription": null,
                    "ShipmentType": "Lodge In",
                    "CODAmount": "",
                    "WayBill": wayBill,
                    "DONumber": ""
                }
        
        infoUpload['Shipment'].push(info)
        
      }



      //var bookingLineClear = firebase.functions().httpsCallable('bookingLineClearTest');
      var bookingLineClear = firebase.functions().httpsCallable('bookingLineClear');
     
      

      bookingLineClear({ data: infoUpload})
      .then((result) => {
            
            var LabelArr =[]
            var responseInfo=result['data']
            //目前的設計是會讓整包data傳回來，那要自行判斷有沒有成功，有就可以印標籤
            if(responseInfo['Message']==='Shipment creation successful.'){
              pdfmake.vfs = pdffonts.pdfMake.vfs;
              let logo=LineClearLogoImg();
              let labelInfo=responseInfo['ResponseData']
              for (let i=0; i<todayOrderList.length; i++) {
                  let barCodeInfo=labelInfo[i]['WayBill']
                  for (let j=0; j<todayOrderList[i]['總箱數']; j++) {
                      let label= [ 
                        { table: {
                          widths: ['auto', '*', '*', '*'],
                          //下面是要做一開始的logo、barcode、和國碼標
                          body: [
                            [
                              {
                              // you'll most often use dataURI images on the browser side
                              // if no width/height/fit is provided, the original size will be used
                              image:logo,
                              width:70, 
                              height:10,
                              alignment: 'left',
                              colSpan: 4,
                              border: [false, false, false, true],},
                              '',
                              '',
                              '',
                              
                  
                            ],
                            [
                              
                              {
                                
                                table: {
                                  
                                  body: [
                                    [{ 
                              
                                      qr: barCodeInfo[j], 
                                      fit: '80', 
                                      alignment: 'center', 
                                      margin: [0, 10, 0, 5],
                                      colSpan:3,
                                      border: [false, false, false, false],        
                                    },
                                    '',
                                    ''],
                                    [
                                      { text:'',
                                        border: [false, false, false, false]},
                                      { text:'',
                                      border: [false, false, false, false]},
                                      { text:'',
                                      border: [false, false, false, false]}
                                    ],
                                    [
                                        { text: 'PCS',
                                        fontSize: 6,
                                        alignment: 'left',
                                        bold: true,
                                        border: [true, true, false, false],
                                        },
                                        { text: '',
                                        
                                        border: [false, true, false, false],
                                        },
                                        { text: 'M',
                                        fontSize: 6,
                                        alignment: 'right',
                                        bold: true,
                                        border: [false, true, true, false],
                                        },
        
        
                                    ],
                                    [{  text: (j+1)+'/'+todayOrderList[i]['總箱數'],
                                        colSpan:3,
                                        fontSize: 18,
                                        alignment: 'center',
                                        bold: true,
                                        border: [true, false, true, true],
                                      },
                                      '',
                                      ''
                                    ],
                                    
                                  ],
                                 
                                },
                                border: [false, false, false, false],
                                layout:{
                                  hLineColor:'#999999',
                                  vLineColor:'#999999',
                                 
        
                                },
                                
                                
                                
                               }, 
                               {
                                
                                  table: {
                                    
                                    body: [
                                      [{ image:textToBase64Barcode(barCodeInfo[j],undefined,undefined,60),
                                        colSpan: 3,
                                        height:50,
                                        width:170,
                                        alignment: 'center',
                                        border: [false, false, false, false],
                                      },
                                      '',
                                      ''],
                                      //空白格
                                      [{text:'',
                                        height:1,
                                        border: [false, false, false, false],
                                        colSpan: 3,
                                       },'',''],
                                      //第一排
                                      [{text:'Origin Agent',
                                        alignment: 'left',
                                        bold: true,
                                        fontSize: 6,
                                        border: [true, true, true, false],
                                      }, 
                                      {text:'Origin Depot',
                                      alignment: 'left',
                                      bold: true,
                                      fontSize: 6,
                                      border: [true, true, true, false],
                                      }, 
                                      {text:'Origin Hub',
                                      alignment: 'left',
                                      bold: true,
                                        fontSize: 6,
                                        border: [true, true, true, false],
                                      }],
                                      //第二排(資料排)
                                      [
                                        { text:'',
                                        alignment: 'center',
                                        bold: true,
                                        fontSize: 15,
                                        border: [true, false, true, false],
                                        }, 
                                        { text:labelInfo[i]['PickupBranchCode'],
                                          alignment: 'center',
                                          bold: true,
                                          fontSize: 15,
                                          border: [true, false, true, false],
                                        }, 
                                        { text:labelInfo[i]['PickupHubCode'],
                                          alignment: 'center',
                                          bold: true,
                                          fontSize: 15,
                                          border: [true, false, true, false],
                                        }
                                      ],
                                      //第三排
                                      [
                                        { text:'Dest. Hub',
                                          alignment: 'left',
                                          fillColor: '#000000',
                                          color:'#ffffff',
                                          bold: true,
                                          fontSize: 6,
                                          border: [true, true, true, false],
                                        }, 
                                        { text:'Dest. Depot',
                                          alignment: 'left',
                                          fillColor: '#000000',
                                          color:'#ffffff',
                                          bold: true,
                                          fontSize: 6,
                                          border: [true, true, true, false],
                                        }, 
                                        { text:'Dest. Agent',
                                          alignment: 'left',
                                          fillColor: '#000000',
                                          color:'#ffffff',
                                          bold: true,
                                          fontSize: 6,
                                          border: [true, true, true, false],
                                        }
                                      ],
                                      //第四排(資料排)
                                      [
                                        { text:labelInfo[i]['DeliveryHubCode'],
                                          alignment: 'center',
                                          fillColor: '#000000',
                                          color:'#ffffff',
                                          
                                          bold: true,
                                          fontSize: 15,
                                          border: [true, false, true, true],
                                        }, 
                                        { text:labelInfo[i]['DeliveryBranchCode'],
                                          alignment: 'center',
                                          fillColor: '#000000',
                                          color:'#ffffff',
                                          bold: true,
                                          fontSize: 15,
                                          border: [true, false, true, true],
                                        }, 
                                        { text:'',
                                          alignment: 'center',
                                          fillColor: '#000000',
                                          color:'#ffffff',
                                          bold: true,
                                          fontSize: 15,
                                          border: [true, false, true, true],
                                        }
                                      ]
        
                                    ]
                                  },
                                  border: [false, false, false, false],
                                  layout:{
                                          hLineColor:'#999999',
                                          vLineColor:'#999999',
                                         
        
                                  },
                                  colSpan:3,
                                  
                                  
                              },
                               
                              '',
                              '',
                            
                            ],
                            [
                              { text:'From',
                                alignment: 'left',
                                bold: true,
                                fontSize: 12,
                                colSpan:2,
                                border: [true, true, false, false],
                              },
                              '',
                              { text:'Account No.: N6900038',
                                alignment: 'left',
                                bold: true,
                                fontSize: 8,
                                colSpan:2,
                                border: [false, true, true, false],
                              },
                              ''
        
                            ],
                            [{  text:'FREEGO TRADING AND LOGISTICS',
                                alignment: 'left',
                                bold: true,
                                fontSize: 8,
                                colSpan:4,
                                border: [true, false, true, false],
                             },
                             '',
                             '',
                             ''
        
                            ],
                            [{  text:'No 26,Jalan KU5, Pusat Komersial Universiti,Seremban 3,70300,Seremban,Negeri Sembilan,Malaysia',
                                alignment: 'left',
                                bold: true,
                                fontSize: 8,
                                width:220,
                                colSpan:4,
                                border: [true, false, true, false],
                             },
                             '',
                             '',
                             ''
        
                            ],
                            [{  text:'MR TIEN',
                                alignment: 'left',
                                bold: true,
                                fontSize: 8,
                                colSpan:4,
                                border: [true, false, true, false],
                             },
                             '',
                             '',
                             ''
        
                            ],
                            [{  text:'60126368616',
                                alignment: 'left',
                                fontSize: 8,
                                colSpan:2,
                                border: [true, false, false, true],
                             },
                             '',
                             {  text:'Cust. Ref. No.:',
                                colSpan:2,
                                fontSize: 8,
                                bold: true,
                                border: [false, false, true, true],
                             },
                             ''
        
                            ],
                            [
                              { text:'To',
                                alignment: 'left',
                                bold: true,
                                fontSize: 12,
                                colSpan:2,
                                border: [true, true, false, false],
                              },
                              '',
                              { text:'',
                                colSpan:2,
                                border: [false, true, true, false],
                              },
                              ''
        
                            ],
                            [{  text:todayOrderList[i]['收件地址'],
                                alignment: 'left',
                                width:220,
                                fontSize: 8,
                                colSpan:4,
                                border: [true, false, true, false],
                             },
                             '',
                             '',
                             ''
        
                            ],
                            [{  text:todayOrderList[i]['收件人姓名'],
                                alignment: 'left',
                                fontSize: 8,
                                colSpan:4,
                                border: [true, false, true, false],
                             },
                             '',
                             '',
                             ''
        
                            ],
                            [{  text:todayOrderList[i]['收件連絡電話'],
                                alignment: 'left',
                                fontSize: 8,
                                colSpan:4,
                                border: [true, false, true, true],
                             },
                             '',
                             '',
                             ''
        
                            ],
                            [{  text:'Weight(kg):'+weight+'   |Pcs:'+  todayOrderList[i]['總箱數']  + '   |Type:Package',
                                alignment: 'left',
                                bold: true,
                                fontSize: 6,
                                colSpan:2,
                                border: [true, true, false, true],
                             },
                             '',
                             {  text:'Date : '+this.state.day+'.'+this.state.month+'.'+this.state.year,
                                alignment: 'right',
                                colSpan:2,
                                fontSize: 6,
                                bold: true,
                                border: [false, true, true, true],
                             },
                             '',
                             
        
                            ],
                            [
                              {text:'',
                               colSpan:4,
                               height:3,
                               border: [false, true,false, true]},
                              '',
                              '',
                              ''
                            ],
                            [
                              {text:'Acknowledgement by Recipient',
                               fontSize: 8,
                               colSpan:4,
                               height:3,
                               bold: true,
                               border: [true, true,true, true]},
                              '',
                              '',
                              ''
                            ],
                            [
                              {text:'',
                               colSpan:2,
                               border: [true, true,false, false]},
                              '',
                              {text:'Name :',
                               fontSize: 8,
                               colSpan:2,
                               border: [false, true,true, false]},
                              ''
                            ],
                            [
                              {text:'Signature &  Company Stamp',
                               alignment: 'center',
                               color:'#b3b3b3',
                               fontSize: 8,
                               colSpan:2,
                               border: [true, false,false, false]},
                              '',
                              {text:'Date & Time :',
                               fontSize: 8,
                               colSpan:2,
                               border: [false, false,true, false]},
                              ''
                            ],
                            [
                              {text:'',
                               colSpan:2,
                               border: [true, false,false, true]},
                              '',
                              {text:'IC / Passport :',
                               fontSize: 8,
                               colSpan:2,
                               border: [false, false,true, true]},
                              ''
                            ],
        
                            
                            [{text: '** For Terms of Carriage and Tracing, kindly log-in to www.lineclearexpress.com.',
                            fontSize: 6,
                            margin: [0, 1, 0, 0],
                            colSpan: 4,
                            bold: true,
                            border: [false, false, false, false],
                            },
                            '',
                            '',
                            ''
                            ] 
                          ],
                          
                         },
                         
                         pageBreak: 'after'
                        }  
                                 ]
                      LabelArr.push(label);
                      
                  }
                  ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/MY/'+this.state.customerkey+'/'+todayOrderList[i].deliveryNumber+'/lineClearNumber').set(barCodeInfo[0])
     
              }
  
              pdfmake.fonts = {
                ch: {
                normal: 'kaiu.ttf',
                bold: 'kaiu.ttf',
                italics: 'kaiu.ttf',
                bolditalics: 'kaiu.ttf'
                }};
        
              var docDefinition = {
                    content:LabelArr,
                    defaultStyle: {
                    font: 'ch',
                    fontSize: 11
                    } ,
                    styles: {
                      head: {
                        
                        fontSize: 66,
                        bold: true
                      },
        
                    },
        
                    // a string or { width: number, height: number }
                    // 下面是10*10的標籤設計
                    pageSize: { width: 283, height: 425},
                    
        
                    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                    pageMargins: [ 3, 3,3, 0 ],
                  };
        
              //準備出單印製
              pdfmake.createPdf(docDefinition).print();
              //這裡要開啟loading的指標符號
              this.setState({
                loading:false,
              })

            }else{
                //沒成功，就要把訊息印製出來
                console.log(responseInfo['Message'])
                console.log(responseInfo['Reason'])

                this.setState({
                  message : responseInfo['Reason'],
                  loading:false
                })
            }
            
               
      })
      

      
      

    }

    print80071Label(){
        ref.child('/infos/customers/80071/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/MY').on('value', (snapshot)=> {
            
                if(snapshot.val()){
                    this.setState({
                      loading:true,
                    })

                    let POs = snapshot.val()
                    
                    var todayOrderList=[]
                    
                    POs=POs[Object.keys(POs)[0]]
                    Object.keys(POs).forEach((key)=> {
                          
                      todayOrderList.push(POs[key])
                    })
                    //console.log(todayOrderList)

                    const infoUpload = {
                      "Shipment": []
                    }
                  
                    for (let i=0; i<todayOrderList.length; i++) {
                      let order=todayOrderList[i]
                      var weight=(Number(order['總重量-公斤'])/Number(order['總箱數'])).toFixed(1)
                      let wayBill = [];
                      for (let j=0; j<order['總箱數']; j++) {
                          wayBill.push(
                            {
                              "Weight": weight,
                              "VolumeWidth": "1",                                                         
                              "VolumeHeight": "1",
                              "VolumeLength": "1"
                  
                            }
                          )
                      }
                      let info={
                                  "ShipmentServiceType": "Standard Delivery",
                                  "SenderName": "MR TIEN",
                                  "RecipientName": order['收件人姓名'],
                                  "ShipmentAddressFrom":  {
                                      "CompanyName": "FREEGO TRADING AND LOGISTICS",
                                      "UnitNumber": "26",
                                      "Address": "No 26,Jalan KU5, Pusat Komersial Universiti,Seremban 3,70300,Seremban,Negeri Sembilan,Malaysia",
                                      "Address2": "",
                                      "PostalCode": "70300",
                                      "City": "",
                                      "State": "",
                                      "Email": "",
                                      "PhoneNumber": "60126368616",
                                      "ICNumber": ""
                                  },
                                  "ShipmentAddressTo":   {
                                      "CompanyName": "",
                                      "UnitNumber": "5",
                                      "Address": order['收件地址'],
                                      "Address2": "",
                                      //下面是測試版，正式上線要改成客人的郵遞區號
                                      "PostalCode": order['收件人郵遞區號'],
                                      "City": "",
                                      "State": "",
                                      "Email": "",
                                      //下面是測試版，正式上線要改成客人的電話號碼
                                      "PhoneNumber": order['收件連絡電話'],
                                      "ICNumber": ""
                                  },
                                  //下面是測試版，正式上線要改成客人的電話號碼
                                  "RecipientPhone": order['收件連絡電話'],
                                  "ParcelType": "Package",
                                  "ShipmentRef": "",
                                  "ShipmentDescription": null,
                                  "ShipmentType": "Lodge In",
                                  "CODAmount": "",
                                  "WayBill": wayBill,
                                  "DONumber": ""
                              }
                      
                      infoUpload['Shipment'].push(info)
                      
                    }
                  
                    //console.log(infoUpload['Shipment'])
                  
                    //bookingLineClearTest
                    var bookingLineClear = firebase.functions().httpsCallable('bookingLineClear');
                   
                    
                  
                    bookingLineClear({ data: infoUpload})
                    .then((result) => {
                          
                          var LabelArr =[]
                          var responseInfo=result['data']
                          //目前的設計是會讓整包data傳回來，那要自行判斷有沒有成功，有就可以印標籤
                          if(responseInfo['Message']==='Shipment creation successful.'){
                            pdfmake.vfs = pdffonts.pdfMake.vfs;
                            let logo=LineClearLogoImg();
                            let labelInfo=responseInfo['ResponseData']
                            for (let i=0; i<todayOrderList.length; i++) {
                                let barCodeInfo=labelInfo[i]['WayBill']
                                for (let j=0; j<todayOrderList[i]['總箱數']; j++) {
                                    let label= [ 
                                      { table: {
                                        widths: ['auto', '*', '*', '*'],
                                        //下面是要做一開始的logo、barcode、和國碼標
                                        body: [
                                          [
                                            {
                                            // you'll most often use dataURI images on the browser side
                                            // if no width/height/fit is provided, the original size will be used
                                            image:logo,
                                            width:70, 
                                            height:10,
                                            alignment: 'left',
                                            colSpan: 4,
                                            border: [false, false, false, true],},
                                            '',
                                            '',
                                            '',
                                            
                                
                                          ],
                                          [
                                            
                                            {
                                              
                                              table: {
                                                
                                                body: [
                                                  [{ 
                                            
                                                    qr: barCodeInfo[j], 
                                                    fit: '80', 
                                                    alignment: 'center', 
                                                    margin: [0, 10, 0, 5],
                                                    colSpan:3,
                                                    border: [false, false, false, false],        
                                                  },
                                                  '',
                                                  ''],
                                                  [
                                                    { text:'',
                                                      border: [false, false, false, false]},
                                                    { text:'',
                                                    border: [false, false, false, false]},
                                                    { text:'',
                                                    border: [false, false, false, false]}
                                                  ],
                                                  [
                                                      { text: 'PCS',
                                                      fontSize: 6,
                                                      alignment: 'left',
                                                      bold: true,
                                                      border: [true, true, false, false],
                                                      },
                                                      { text: '',
                                                      
                                                      border: [false, true, false, false],
                                                      },
                                                      { text: 'M',
                                                      fontSize: 6,
                                                      alignment: 'right',
                                                      bold: true,
                                                      border: [false, true, true, false],
                                                      },
                      
                      
                                                  ],
                                                  [{  text: (j+1)+'/'+todayOrderList[i]['總箱數'],
                                                      colSpan:3,
                                                      fontSize: 18,
                                                      alignment: 'center',
                                                      bold: true,
                                                      border: [true, false, true, true],
                                                    },
                                                    '',
                                                    ''
                                                  ],
                                                  
                                                ],
                                               
                                              },
                                              border: [false, false, false, false],
                                              layout:{
                                                hLineColor:'#999999',
                                                vLineColor:'#999999',
                                               
                      
                                              },
                                              
                                              
                                              
                                             }, 
                                             {
                                              
                                                table: {
                                                  
                                                  body: [
                                                    [{ image:textToBase64Barcode(barCodeInfo[j],undefined,undefined,60),
                                                      colSpan: 3,
                                                      height:50,
                                                      width:170,
                                                      alignment: 'center',
                                                      border: [false, false, false, false],
                                                    },
                                                    '',
                                                    ''],
                                                    //空白格
                                                    [{text:'',
                                                      height:1,
                                                      border: [false, false, false, false],
                                                      colSpan: 3,
                                                     },'',''],
                                                    //第一排
                                                    [{text:'Origin Agent',
                                                      alignment: 'left',
                                                      bold: true,
                                                      fontSize: 6,
                                                      border: [true, true, true, false],
                                                    }, 
                                                    {text:'Origin Depot',
                                                    alignment: 'left',
                                                    bold: true,
                                                    fontSize: 6,
                                                    border: [true, true, true, false],
                                                    }, 
                                                    {text:'Origin Hub',
                                                    alignment: 'left',
                                                    bold: true,
                                                      fontSize: 6,
                                                      border: [true, true, true, false],
                                                    }],
                                                    //第二排(資料排)
                                                    [
                                                      { text:'',
                                                      alignment: 'center',
                                                      bold: true,
                                                      fontSize: 15,
                                                      border: [true, false, true, false],
                                                      }, 
                                                      { text:labelInfo[i]['PickupBranchCode'],
                                                        alignment: 'center',
                                                        bold: true,
                                                        fontSize: 15,
                                                        border: [true, false, true, false],
                                                      }, 
                                                      { text:labelInfo[i]['PickupHubCode'],
                                                        alignment: 'center',
                                                        bold: true,
                                                        fontSize: 15,
                                                        border: [true, false, true, false],
                                                      }
                                                    ],
                                                    //第三排
                                                    [
                                                      { text:'Dest. Hub',
                                                        alignment: 'left',
                                                        fillColor: '#000000',
                                                        color:'#ffffff',
                                                        bold: true,
                                                        fontSize: 6,
                                                        border: [true, true, true, false],
                                                      }, 
                                                      { text:'Dest. Depot',
                                                        alignment: 'left',
                                                        fillColor: '#000000',
                                                        color:'#ffffff',
                                                        bold: true,
                                                        fontSize: 6,
                                                        border: [true, true, true, false],
                                                      }, 
                                                      { text:'Dest. Agent',
                                                        alignment: 'left',
                                                        fillColor: '#000000',
                                                        color:'#ffffff',
                                                        bold: true,
                                                        fontSize: 6,
                                                        border: [true, true, true, false],
                                                      }
                                                    ],
                                                    //第四排(資料排)
                                                    [
                                                      { text:labelInfo[i]['DeliveryHubCode'],
                                                        alignment: 'center',
                                                        fillColor: '#000000',
                                                        color:'#ffffff',
                                                        
                                                        bold: true,
                                                        fontSize: 15,
                                                        border: [true, false, true, true],
                                                      }, 
                                                      { text:labelInfo[i]['DeliveryBranchCode'],
                                                        alignment: 'center',
                                                        fillColor: '#000000',
                                                        color:'#ffffff',
                                                        bold: true,
                                                        fontSize: 15,
                                                        border: [true, false, true, true],
                                                      }, 
                                                      { text:'',
                                                        alignment: 'center',
                                                        fillColor: '#000000',
                                                        color:'#ffffff',
                                                        bold: true,
                                                        fontSize: 15,
                                                        border: [true, false, true, true],
                                                      }
                                                    ]
                      
                                                  ]
                                                },
                                                border: [false, false, false, false],
                                                layout:{
                                                        hLineColor:'#999999',
                                                        vLineColor:'#999999',
                                                       
                      
                                                },
                                                colSpan:3,
                                                
                                                
                                            },
                                             
                                            '',
                                            '',
                                          
                                          ],
                                          [
                                            { text:'From',
                                              alignment: 'left',
                                              bold: true,
                                              fontSize: 12,
                                              colSpan:2,
                                              border: [true, true, false, false],
                                            },
                                            '',
                                            { text:'Account No.: N6900038',
                                              alignment: 'left',
                                              bold: true,
                                              fontSize: 8,
                                              colSpan:2,
                                              border: [false, true, true, false],
                                            },
                                            ''
                      
                                          ],
                                          [{  text:'FREEGO TRADING AND LOGISTICS',
                                              alignment: 'left',
                                              bold: true,
                                              fontSize: 8,
                                              colSpan:4,
                                              border: [true, false, true, false],
                                           },
                                           '',
                                           '',
                                           ''
                      
                                          ],
                                          [{  text:'No 26,Jalan KU5, Pusat Komersial Universiti,Seremban 3,70300,Seremban,Negeri Sembilan,Malaysia',
                                              alignment: 'left',
                                              bold: true,
                                              fontSize: 8,
                                              width:220,
                                              colSpan:4,
                                              border: [true, false, true, false],
                                           },
                                           '',
                                           '',
                                           ''
                      
                                          ],
                                          [{  text:'MR TIEN',
                                              alignment: 'left',
                                              bold: true,
                                              fontSize: 8,
                                              colSpan:4,
                                              border: [true, false, true, false],
                                           },
                                           '',
                                           '',
                                           ''
                      
                                          ],
                                          [{  text:'60126368616',
                                              alignment: 'left',
                                              fontSize: 8,
                                              colSpan:2,
                                              border: [true, false, false, true],
                                           },
                                           '',
                                           {  text:'Cust. Ref. No.:',
                                              colSpan:2,
                                              fontSize: 8,
                                              bold: true,
                                              border: [false, false, true, true],
                                           },
                                           ''
                      
                                          ],
                                          [
                                            { text:'To',
                                              alignment: 'left',
                                              bold: true,
                                              fontSize: 12,
                                              colSpan:2,
                                              border: [true, true, false, false],
                                            },
                                            '',
                                            { text:'',
                                              colSpan:2,
                                              border: [false, true, true, false],
                                            },
                                            ''
                      
                                          ],
                                          [{  text:todayOrderList[i]['收件地址'],
                                              alignment: 'left',
                                              width:220,
                                              fontSize: 8,
                                              colSpan:4,
                                              border: [true, false, true, false],
                                           },
                                           '',
                                           '',
                                           ''
                      
                                          ],
                                          [{  text:todayOrderList[i]['收件人姓名'],
                                              alignment: 'left',
                                              fontSize: 8,
                                              colSpan:4,
                                              border: [true, false, true, false],
                                           },
                                           '',
                                           '',
                                           ''
                      
                                          ],
                                          [{  text:todayOrderList[i]['收件連絡電話'],
                                              alignment: 'left',
                                              fontSize: 8,
                                              colSpan:4,
                                              border: [true, false, true, true],
                                           },
                                           '',
                                           '',
                                           ''
                      
                                          ],
                                          [{  text:'Weight(kg):'+weight+'   |Pcs:'+  todayOrderList[i]['總箱數']  + '   |Type:Package',
                                              alignment: 'left',
                                              bold: true,
                                              fontSize: 6,
                                              colSpan:2,
                                              border: [true, true, false, true],
                                           },
                                           '',
                                           {  text:'Date : '+this.state.day+'.'+this.state.month+'.'+this.state.year,
                                              alignment: 'right',
                                              colSpan:2,
                                              fontSize: 6,
                                              bold: true,
                                              border: [false, true, true, true],
                                           },
                                           '',
                                           
                      
                                          ],
                                          [
                                            {text:'',
                                             colSpan:4,
                                             height:3,
                                             border: [false, true,false, true]},
                                            '',
                                            '',
                                            ''
                                          ],
                                          [
                                            {text:'Acknowledgement by Recipient',
                                             fontSize: 8,
                                             colSpan:4,
                                             height:3,
                                             bold: true,
                                             border: [true, true,true, true]},
                                            '',
                                            '',
                                            ''
                                          ],
                                          [
                                            {text:'',
                                             colSpan:2,
                                             border: [true, true,false, false]},
                                            '',
                                            {text:'Name :',
                                             fontSize: 8,
                                             colSpan:2,
                                             border: [false, true,true, false]},
                                            ''
                                          ],
                                          [
                                            {text:'Signature &  Company Stamp',
                                             alignment: 'center',
                                             color:'#b3b3b3',
                                             fontSize: 8,
                                             colSpan:2,
                                             border: [true, false,false, false]},
                                            '',
                                            {text:'Date & Time :',
                                             fontSize: 8,
                                             colSpan:2,
                                             border: [false, false,true, false]},
                                            ''
                                          ],
                                          [
                                            {text:'',
                                             colSpan:2,
                                             border: [true, false,false, true]},
                                            '',
                                            {text:'IC / Passport :',
                                             fontSize: 8,
                                             colSpan:2,
                                             border: [false, false,true, true]},
                                            ''
                                          ],
                      
                                          
                                          [{text: '** For Terms of Carriage and Tracing, kindly log-in to www.lineclearexpress.com.',
                                          fontSize: 6,
                                          margin: [0, 1, 0, 0],
                                          colSpan: 4,
                                          bold: true,
                                          border: [false, false, false, false],
                                          },
                                          '',
                                          '',
                                          ''
                                          ] 
                                        ],
                                        
                                       },
                                       
                                       pageBreak: 'after'
                                      }  
                                               ]
                                    LabelArr.push(label);
                                    
                                }
                                //ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/MY/'+this.state.customerkey+'/'+todayOrderList[i].deliveryNumber+'/lineClearNumber').set(barCodeInfo[0])
                   
                            }
                  
                            pdfmake.fonts = {
                              ch: {
                              normal: 'kaiu.ttf',
                              bold: 'kaiu.ttf',
                              italics: 'kaiu.ttf',
                              bolditalics: 'kaiu.ttf'
                              }};
                      
                            var docDefinition = {
                                  content:LabelArr,
                                  defaultStyle: {
                                  font: 'ch',
                                  fontSize: 11
                                  } ,
                                  styles: {
                                    head: {
                                      
                                      fontSize: 66,
                                      bold: true
                                    },
                      
                                  },
                      
                                  // a string or { width: number, height: number }
                                  // 下面是10*10的標籤設計
                                  pageSize: { width: 283, height: 425},
                                  
                      
                                  // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                                  pageMargins: [ 3, 3,3, 0 ],
                                };
                      
                            //準備出單印製
                            pdfmake.createPdf(docDefinition).print();
                            this.setState({
                              loading:false,
                            })
                  
                          }else{
                              //沒成功，就要把訊息印製出來
                              console.log(responseInfo['Message'])
                              console.log(responseInfo['Reason'])
                  
                              this.setState({
                                'message' : responseInfo['Reason']
                              })
                          }
              
                    })
                }
              
                
        })
      

      
      

    }
    
    
     

    
    render() {
  
       return (
           
           <div style={{ 'padding-top': '0.5em','padding-left': '2em','padding-right': '2em','padding-bottom': '2em',width:'80%',margin: 'auto'}}>
             <Grid  divided='vertically' centered >
                 <Grid.Row columns={1} > 
                    <Grid.Column style={{ 'padding-top':'3em','padding-bottom':'0em', }}>
                       <Form style={{'font-size':'16px'}}>
                             <Form.Group widths='equal' > 
                                 <Form.Field>
                                     
                                     <Form.Group style={{ 'padding-left': '0.3em', width:'70%'}} >
                                            <label style={{'font-size':'18px','font-weight':'bolder','color':'black',width:200}}>請輸入單號</label>
                                            <input placeholder='請輸入'  focus={true} value={this.state.queryKey} size='large' name="queryKey" onChange={this.checkOrder} style={{ padding: '0.3em', }} ref={(input) => { this.queryKey = input; }} />
                                            
                                     </Form.Group>
                                     <Form.Group style={{ 'padding-left': '0.3em', width:'70%'}} >
                                            
                                            <label style={{'font-size':'20px','font-weight':'bolder','color':'black'}}>{this.state.message}</label>
                                     </Form.Group>
                                     
                                 </Form.Field> 
                             </Form.Group>
                       </Form>
                    </Grid.Column>
                 </Grid.Row>

                 <Grid.Row columns={1}>  
                     <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                     
                       <Segment raised>  
                             <Label as='a' color='red' ribbon style={{'font-size':'20px'}}>
                               狀態顯示
                             </Label>

                             <Modal  open={this.state.loading}>
                                 <Loader content='處理中'  size='huge' />
                              </Modal>
                            
                             <Button color='orange'  style={{'font-size':'14px','margin-left': '4em'}}  onClick={this.printTodayAllLabel}>印製今日全部標籤</Button>
                             <Table celled >
                                  <Table.Header>
                                  <Table.Row>
                                      <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>快遞單號</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>收件人</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>收件連絡電話</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>件數</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>印製標籤</Grid></Table.HeaderCell>
                            
                                  </Table.Row>
                                  </Table.Header>

                                  <Table.Body>
                                      {this.state.dataOnScreenMY}
                                      
                                  </Table.Body>
                                  <Table.Body>
                                      
                                      {this.state.dataOnScreenSG}
                                  </Table.Body>

                              </Table>
                             
                             
                                 
                       </Segment>
                    
                     </Grid.Column>
                 </Grid.Row>

                 
             <Grid.Row columns={1}>  
                     <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                     
                       <Segment raised>  
                             <Label as='a' color='red' ribbon style={{'font-size':'20px'}}>
                             薇恩待出單數量
                             </Label>
                             
                             <Table celled >
                                  <Table.Header>
                                  <Table.Row>
                                      <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>快遞單號</Grid></Table.HeaderCell>
                                      
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>件數</Grid></Table.HeaderCell>
                                      
                            
                                  </Table.Row>
                                  </Table.Header>

                                  <Table.Body>
                                      {this.state.waitToShip}

                                      
                                  </Table.Body>
                              </Table>
                                 
                       </Segment>
                    
                     </Grid.Column>
                 </Grid.Row>
                 <Grid.Row>
                      <Button color='orange'  style={{'font-size':'16px'}}  onClick={this.print80071Label}>印製普士特標籤</Button>
                 </Grid.Row>
             </Grid>
           </div>
    
        
    
    
    
    
       );
      
    }
    


}
export default App;