import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import {todayTitle,savePO,tellAdmin,saveDeliveryNumber} from './helper/SaveToDatabase';
import {LogoImg,textToBase64Barcode} from './helper/Helper';
import pdfmake from 'pdfmake/build/pdfmake'
import pdffonts from 'pdfmake/build/vfs_fonts';
import saveAs from 'file-saver';
import {Button, Input, Confirm,Table,Form,Grid,Segment,Label,Radio,Divider,Icon,Header,Dropdown} from 'semantic-ui-react';
import {ref, firebaseAuth,firebaseStorage} from '../firebase/firebaseConfig';
import 'semantic-ui-css/semantic.min.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
var JsBarcode = require('jsbarcode');


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      //這個每個客人都要改
      'customerNumber':'22306',
      'deliveryNation':'ID',
      
      'today':todayTitle()[0],
      'year':todayTitle()[1],
      'month':todayTitle()[2],
      'day':todayTitle()[3],
      'userid':'',
      
      'printStatus':false,
      

      'pickUpDate':new Date(),
      'department': '',
      "Sales":'',
      "extensionNumber" :'',

      
      "pickUpCompanyName":'',
      "pickUpContactName":'',
      "pickUpContactPhone":'',
      "pickUpAddress":'',
      "chinaDeliveryNumber":'',
      
      'deliveryTarget':'',
      "deliveryCompany":'',
      'deliveryContact':'',
      "deliveryPhone":'',
      "deliveryAddress":'',
      "deliveryContactExtension":'',
      
      

      "poNumber":'',
      "containerNumber":'',
      "brand":'',
      "styleNO":'',
      "season":'',
      "quantity":'',
      'fileName':'',
      'upLoadStatus':'',

      'statusInfo':'',
    };
    
   
    this.print=this.print.bind(this);
    
    this.handleInputChange=this.handleInputChange.bind(this);
    this.rechangePOContent=this.rechangePOContent.bind(this);
    
    this.rePrint=this.rePrint.bind(this);

    this.deleteOpen=this.deleteOpen.bind(this);
    this.deleteClose=this.deleteClose.bind(this);
    this.deleteRecord=this.deleteRecord.bind(this);
    
    this.setStartDate=this.setStartDate.bind(this);
    this.setDepartment=this.setDepartment.bind(this);
    this.setDeliveryTarget=this.setDeliveryTarget.bind(this);
    this.handleUpload=this.handleUpload.bind(this);
    this.downloadShipInfo=this.downloadShipInfo.bind(this);
    this.download45ShipInfo=this.download45ShipInfo.bind(this);
    this.download=this.download.bind(this);

  }


componentDidMount (){
    var userid = firebaseAuth.currentUser.uid;

    ref.child('/users/'+userid+'/uid').on('value', (snapshot)=> {
          if(snapshot.val()){
              this.setState({
                'userid':snapshot.val(),
              })

              userid=snapshot.val()
              //下面先處理PrepareToShip的部分
              ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+userid).on('value', (snapshot)=> {
                        if(snapshot.val()){
                            //因為這裡設計權限差異，所以要比較一下uid，然後輸出不同的東西
                            if(firebaseAuth.currentUser.uid===userid){
                              let queryResult=snapshot.val();
                              //console.log(queryResult)
                              var PrepareToShip=[]
                              var PrepareToShipData=[]
                              var keyList=[]
                              Object.keys(queryResult).forEach((key)=> {
                                  keyList.push(key)
                              })
                              keyList=keyList.reverse()
                              keyList.forEach((key)=>{
                              var po=queryResult[key];

                              let d=new Date(po.pickUpDate)
                              let year=d.getFullYear();
                              let month=d.getMonth()+1;
                              let day=d.getDate();
                              let pickUpDate=year+'/'+month+'/'+day

                              PrepareToShipData.push(po)
                              PrepareToShip.push(
                                    <Table.Row style={{'height':44 }} >                            
                                        <Table.Cell width={1}>
                                            {po.deliveryNumber}
                                        </Table.Cell>
                                        <Table.Cell width={1} >
                                            {pickUpDate}
                                        </Table.Cell> 
                                        <Table.Cell width={1} textAlign='center'>
                                            {po.Sales}
                                        </Table.Cell> 
                                        <Table.Cell >
                                            <Grid centered style={{'padding-top':'0.3em'}}>{po.poNumber}</Grid>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Grid centered style={{'padding-top':'0.3em' }}>{po.pickUpCompanyName}</Grid>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Grid centered style={{'padding-top':'0.3em' }}>{po.deliveryCompany}</Grid>
                                        </Table.Cell>
                                        <Table.Cell>                               
                                            <Grid centered style={{'padding-top':'0.3em' }}><Button content='下載'  size='medium' color='green' name={po.fileName} onClick={(e)=>this.download(e)} style={{ 'font-size':'12px','font-weight':'bold'}}/></Grid>
                                        </Table.Cell> 
                                        <Table.Cell>
                                              <Grid centered style={{'padding-top':'0.3em' }}><Button size='medium' color='green'  style={{'font-size':'12px'}} name={po.deliveryNumber} onClick={this.rePrint}>重印標籤</Button></Grid>
                                        </Table.Cell>
                                                             
                                    </Table.Row>
                                )
                              })
              
                              
                              this.setState({
                                PrepareToShip:PrepareToShip,
                                PrepareToShipData:PrepareToShipData
                              })

                            }else{
                              let queryResult=snapshot.val();
                              //console.log(queryResult)
                              var PrepareToShip=[]
                              var PrepareToShipData=[]
                              var keyList=[]
                              Object.keys(queryResult).forEach((key)=> {
                                  keyList.push(key)
                              })
                              keyList=keyList.reverse()
                              keyList.forEach((key)=>{
                              var po=queryResult[key];

                              let d=new Date(po.pickUpDate)
                              let year=d.getFullYear();
                              let month=d.getMonth()+1;
                              let day=d.getDate();
                              let pickUpDate=year+'/'+month+'/'+day

                              PrepareToShipData.push(po)
                              PrepareToShip.push(
                                    <Table.Row style={{'height':44 }} >                            
                                        <Table.Cell width={1} onClick={()=>this.rechangePOContent(po.deliveryNumber)} >
                                            {po.deliveryNumber}
                                        </Table.Cell>
                                        <Table.Cell width={1} >
                                            {pickUpDate}
                                        </Table.Cell> 
                                        <Table.Cell width={1} textAlign='center'>
                                            {po.Sales}
                                        </Table.Cell> 
                                        <Table.Cell >
                                            <Grid centered style={{'padding-top':'0.3em'}}>{po.poNumber}</Grid>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Grid centered style={{'padding-top':'0.3em' }}>{po.pickUpCompanyName}</Grid>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Grid centered style={{'padding-top':'0.3em' }}>{po.deliveryCompany}</Grid>
                                        </Table.Cell>
                                        <Table.Cell>                               
                                             <Grid centered style={{'padding-top':'0.3em' }}><Button content='下載'  size='medium' color='green' name={po.fileName} onClick={(e)=>this.download(e)} style={{ 'font-size':'12px','font-weight':'bold'}}/></Grid>
                                        </Table.Cell> 
                                        <Table.Cell>
                                              <Grid centered style={{'padding-top':'0.3em' }}><Button size='medium' color='green'  style={{'font-size':'12px'}} name={po.deliveryNumber} onClick={this.rePrint}>重印標籤</Button></Grid>
                                        </Table.Cell>
                                        <Table.Cell>                               
                                              <Grid centered style={{'padding-top':'0.3em' }}><Button content='刪除'  size='medium' color='red' name={po.deliveryNumber} onClick={(e)=>this.deleteOpen(e)} style={{ 'font-size':'12px','font-weight':'bold'}}/></Grid>
                                        </Table.Cell>                     
                                    </Table.Row>
                                )
                              })
              
                              
                              this.setState({
                                PrepareToShip:PrepareToShip,
                                PrepareToShipData:PrepareToShipData
                              })

                            }
                              
                              
                        }else{
                              
                              this.setState({
                                PrepareToShip:'',
                                PrepareToShipData:''
                              })
              
                        }
            
              })
              //下面先處理ReadyToShip的部分
              ref.child('/infos/customers/'+this.state.customerNumber+'/ReadyToShip/'+userid).on('value', (snapshot)=> {
                if(snapshot.val()){
                      let queryResult=snapshot.val();
                      //console.log(queryResult)
                      var ReadyToShip=[]
                      var ReadyToShipData=[]
                      var keyList=[]
                      Object.keys(queryResult).forEach((key)=> {
                          keyList.push(key)
                      })
                      keyList=keyList.reverse()
                      keyList.forEach((key)=>{
                      var po=queryResult[key];
                      ReadyToShipData.push(po)
                      ReadyToShip.push(
                            <Table.Row style={{'height':44 }} >                            
                                <Table.Cell width={1}>
                                            {po.deliveryNumber}
                                </Table.Cell>
                                <Table.Cell width={2} textAlign='center'>
                                            {po.Sales}
                                </Table.Cell> 
                                <Table.Cell >
                                    <Grid centered style={{'padding-top':'0.3em'}}>{po.poNumber}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.pickUpCompanyName}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.deliveryCompany}</Grid>
                                </Table.Cell>
                               
                                                     
                            </Table.Row>
                        )
                      })
      
                      
                      this.setState({
                        ReadyToShip:ReadyToShip,
                        ReadyToShipData:ReadyToShipData
                      })

                    }else{
                      
                      this.setState({
                        ReadyToShip:'',
                        ReadyToShipData:''
                      })
      
                    }
    
              })
              

              var FinishToShip=[]
              var FinishToShipData=[]
              for (var i = 0; i < 10; i++) {
                  var d = new Date();
                  d.setDate(d.getDate() - i);
                  let year=d.getFullYear();
                  let month=d.getMonth()+1;
                  if(month.toString().length===1){
                    month="0"+month;
                  };
                  let day=d.getDate();
                  if(day.toString().length===1){
                    day="0"+day;
                  };
                  ref.child('/infos/customers/'+this.state.customerNumber+'/'+year+'/'+month+'/'+day+'/'+this.state.deliveryNation+'/'+this.state.userid).on('value', (snapshot)=> {
                    if(snapshot.val()){
                      let queryResult=snapshot.val();
                      //console.log(queryResult)
                      
                      var keyList=[]
                      Object.keys(queryResult).forEach((key)=> {
                          keyList.push(key)
                      })
                      keyList=keyList.reverse()
                      keyList.forEach((key)=>{
                      var po=queryResult[key];

                      let finishDate=year+'/'+month+'/'+day
                      var po=queryResult[key];
                      po['finishDate']=finishDate

                      FinishToShipData.push(po)
                      FinishToShip.push(
                            <Table.Row style={{'height':44 }} > 
                                <Table.Cell width={1}>
                                      {po.deliveryNumber}
                                </Table.Cell>
                                <Table.Cell width={1} >
                                      {po.finishDate}
                                </Table.Cell>                           
                                <Table.Cell width={1} textAlign='center'>
                                      {po.Sales}
                                </Table.Cell> 
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.poNumber}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.pickUpCompanyName}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.deliveryCompany}</Grid>
                                </Table.Cell>
                               
                                <Table.Cell>                               
                                    <Grid centered style={{'padding-top':'0.3em'}}>{po.weight}</Grid>
                                </Table.Cell>  
                                
                                <Table.Cell>                               
                                    <Grid centered style={{'padding-top':'0.3em'}}>{po.quotePrice}</Grid>
                                </Table.Cell>  
                                                     
                            </Table.Row>
                        )
                      })
                      
                      
                      this.setState({ 
                        FinishToShip: FinishToShip,
                        FinishToShipData:FinishToShipData 
                      })

                    }
                  })

              }

          }
     })
    //console.log(userid)
    
    
    //每一張開始都要先取得單號
    var IDRef = ref.child('/infos/customers/'+this.state.customerNumber+'/IDDeliveryNumberData/'+this.state.year+'/'+this.state.month+'/'+this.state.day) 
    IDRef.transaction((currentData)=> {

      if(currentData===null){
        return {'order':0}
      }else{
        currentData['order']=Number(currentData['order'])+1
        return currentData; // 把單號+1，然後存回去
      }
    },(error, committed, snapshot)=>{
      if (error) {
          this.setState({ 
            printErrorMessage:'請確認網路是否離線!', 
          })
      } else if (committed) {
          //console.log(snapshot.val())
          var currentData=snapshot.val()
          let deliveryNumber=(Number(currentData['order'])+1).toString()              
          var Space=4-deliveryNumber.length 
          for (var i = 0; i < Space; i++) {
            deliveryNumber ='0' + deliveryNumber;
          }
          var year=todayTitle()[1].toString().slice(2)
          deliveryNumber=year+this.state.month+this.state.day+deliveryNumber.toString()
          
          this.setState({
            deliveryNumber:'TNN'+deliveryNumber
          })
      } 
      
      

    })
    

}



//這是所有欄位普用型的function
handleInputChange(e){
    this.setState({ [e.target.name]: e.target.value });  
}


rechangePOContent(deliveryNumber){
  
  ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+this.state.userid+'/'+deliveryNumber).on('value', (snapshot)=> {
     if(snapshot.val()){
          var target=snapshot.val()
          this.setState({
            'deliveryNumber':target.deliveryNumber, 
            'pickUpDate':new Date(target.pickUpDate),    //string要轉回來才可以顯示
            'department': target.department,
            'departmentOption': target.department,
            "Sales": target.Sales,
            "extensionNumber" :target.extensionNumber,

            
            "pickUpCompanyName":target.pickUpCompanyName,
            "pickUpContactName":target.pickUpContactName,
            "pickUpContactPhone":target.pickUpContactPhone,
            "pickUpAddress":target.pickUpAddress,
            "chinaDeliveryNumber":target.chinaDeliveryNumber,
            
            'deliveryTarget':target.deliveryTarget,
            "deliveryCompany":target.deliveryCompany,
            "deliveryAddress":target.deliveryAddress,
            "deliveryPhone":target.deliveryPhone,
            'deliveryContact':target.deliveryContact,
            'deliveryContactExtension':target.deliveryContactExtension,

            "poNumber":target.poNumber,
            "containerNumber":target.containerNumber,
            "brand":target.brand,
            "styleNO":target.styleNO,
            "season":target.season,
            "quantity":target.quantity,
            'fileName':target.fileName,
            'upLoadStatus':'1',
            
          });

     }
    })
}

//啟動印製品項動作，這裡主要做分類動作，實際印製方式寫在前面的printProcess裡面
print(){
  let goodData={
          'deliveryNumber':this.state.deliveryNumber,
          'deliveryNation':this.state.deliveryNation,
          'pickUpDate':this.state.pickUpDate.toString(),  //請注意date沒辦法存到資料庫裏面，所以要轉一下
          'department': this.state.department,
          "Sales":this.state.Sales,
          "extensionNumber" :this.state.extensionNumber,
          
          
          "pickUpCompanyName":this.state.pickUpCompanyName,
          "pickUpContactName":this.state.pickUpContactName,
          "pickUpContactPhone":this.state.pickUpContactPhone,
          "pickUpAddress":this.state.pickUpAddress,
          "chinaDeliveryNumber":this.state.chinaDeliveryNumber,
          
          'deliveryTarget':this.state.deliveryTarget,
          'deliveryCompany':this.state.deliveryCompany,
          'deliveryAddress':this.state.deliveryAddress,
          'deliveryPhone':this.state.deliveryPhone,
          'deliveryContact':this.state.deliveryContact,
          'deliveryContactExtension':this.state.deliveryContactExtension,

          "poNumber":this.state.poNumber,
          "containerNumber":this.state.containerNumber,
          "brand":this.state.brand,
          "styleNO":this.state.styleNO,
          "season":this.state.season,
          "quantity":this.state.quantity,
          'fileName':this.state.fileName,

  }

  const process=()=>{
          var logo=LogoImg();
          var dataURL=textToBase64Barcode(this.state.deliveryNumber);
          pdfmake.vfs = pdffonts.pdfMake.vfs;
          let LabelArr = [];

          let Label=[
            { table: {
              widths: ['auto', '*', '*', '*', '*', '*'],
              //下面是要做一開始的logo、barcode、和國碼標
              body: [
                [
                  {
                  // you'll most often use dataURI images on the browser side
                  // if no width/height/fit is provided, the original size will be used
                  image:logo,
                  width:100, 
                  height:20,
                  alignment: 'center',
                  colSpan: 3},
                  '',
                  '',
                  { rowSpan: 2,
                    colSpan: 3,
                    text: this.state.deliveryNation,
                    style: 'head',  
                    width:100, 
                    alignment: 'center',          
                  },
                  '',
                  ''
  
                ],
                [{ image:dataURL,
                  colSpan: 3,
                  width:140, 
                  height:60,
                  alignment: 'center',
                },
                {},
                {},
                {},
                {},
                {}
                ],
                //SG這一格是設成zipcode
                [{ text: 'A/C NO:'},
                 {text: '',
                  colSpan: 5},
                {},
                {},
                {},
                {}
                ],
                [{ text: 'PO:'},
                {text: this.state.poNumber,colSpan: 5},
                {},
                {},
                {},
                {}
                ],
  
                [{text: 'Shipper:'},
                {text:this.state.pickUpCompanyName,colSpan: 5},
                {},
                {},
                {},
                {}
                ],
  
                [{text: 'Consignee:'},
                {text: this.state.deliveryCompany+'\n Contact:'+this.state.deliveryContact,colSpan: 5},
                {},
                {},
                {},
                {}
                ],
  
                [{text: 'TEL:'},
                {text: this.state.deliveryPhone+'\n Ext:'+this.state.deliveryContactExtension,colSpan: 5},
                {},
                {},
                {},
                {}
                ],
  
                [{text: 'Address:',rowSpan: 2},
                {text: this.state.deliveryAddress,rowSpan: 2,colSpan: 5},
                {},
                {},
                {},
                {}
                ],
                [{},
                {},
                {},
                {},
                {},
                {}
                ],
  
                [{text: 'Declared Value:'},
                {text:'' ,colSpan: 5},
                {},
                {},
                {},
                {}
                ],
  
                [{text: 'Weight:'},
                //KGS前面後一格，看起來比較好看
                {text:'',colSpan: 5},
                {},
                {},
                {},
                {}
                ],
                [{text: 'Description:'},
                {text:'',colSpan: 5},
                {},
                {},
                {},
                {}
                ],
  
              ]
            },
            },     
          ];
          LabelArr.push(Label);

          pdfmake.fonts = {
            ch: {
            normal: 'kaiu.ttf',
            bold: 'kaiu.ttf',
            italics: 'kaiu.ttf',
            bolditalics: 'kaiu.ttf'
            }};
          
          var docDefinition = {
              content:LabelArr,
              defaultStyle: {
              font: 'ch',
              fontSize: 11
              } ,
              styles: {
                head: {
                  
                  fontSize: 66,
                  bold: true
                },
        
              },
        
              // a string or { width: number, height: number }
              // 下面是10*10的標籤設計
              pageSize: { width: 283, height: 425},
              
        
              // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
              pageMargins: [ 3, 3,3, 0 ],
            };

          //等一下第一次存檔成功以後，就要呼叫doTheRest
          const doTheRest=()=>{

                //把品項內容恢復原狀
                this.setState({ 
                  'pickUpDate':new Date(),
                  'department': '',
                  'departmentOption':'',
                  "Sales":'',
                  "extensionNumber" :'',

                  
                  "pickUpCompanyName":'',
                  "pickUpContactName":'',
                  "pickUpContactPhone":'',
                  "pickUpAddress":'',
                  'chinaDeliveryNumber':'',
                  
                  'deliveryTarget':'',
                  "deliveryCompany":'',
                  "deliveryAddress":'',
                  "deliveryPhone":'',
                  'deliveryContact':'',
                  'deliveryContactExtension':'',

                  "poNumber":'',
                  "containerNumber":'',
                  "brand":'',
                  "styleNO":'',
                  "season":'',
                  "quantity":'',
                  'fileName':'',
                  'upLoadStatus':'',

                  'statusInfo':'',
                  
                });
                    
                

                //告訴DEC進單了
                tellAdmin(this.state.customerNumber,this.state.deliveryNation,this.state.year,this.state.month,this.state.day);

                //準備出單印製
                pdfmake.createPdf(docDefinition).print();

                var IDRef = ref.child('/infos/customers/'+this.state.customerNumber+'/IDDeliveryNumberData/'+this.state.year+'/'+this.state.month+'/'+this.state.day) 
                IDRef.transaction((currentData)=> {

                  if(currentData===null){
                    return {'order':0}
                  }else{
                    currentData['order']=Number(currentData['order'])+1
                    return currentData; // 把單號+1，然後存回去
                  }
                },(error, committed, snapshot)=>{
                  if (error) {
                      this.setState({ 
                        printErrorMessage:'請確認網路是否離線!', 
                      })
                  } else if (committed) {
                      //console.log(snapshot.val())
                      var currentData=snapshot.val()
                      let deliveryNumber=(Number(currentData['order'])+1).toString()              
                      var Space=4-deliveryNumber.length 
                      for (var i = 0; i < Space; i++) {
                        deliveryNumber ='0' + deliveryNumber;
                      }
                      var year=todayTitle()[1].toString().slice(2)
                      deliveryNumber=year+this.state.month+this.state.day+deliveryNumber.toString()
                      
                      this.setState({
                        deliveryNumber:'TNN'+deliveryNumber
                      })
                  } 
                  
                  

                })

                
            
    
          }

           //先存檔案一次
          ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+this.state.userid+'/'+this.state.deliveryNumber).set(goodData).then((error)=> {
            if(error){   
                this.setState({
                  printErrorMessage:'訂單存檔沒有成功，再按一次!',
                })                 
            }          
          })      
          
         
          

          //為了避免檔案沒存到就印標籤，所以先查一次，如果有，就印標
          var testRef = ref.child('/infos/customers/'+this.state.customerNumber+'/testNumber')  
          testRef.transaction((currentData)=> {

                  if(currentData===null){
                    return {'order':0}
                  }else{
                    currentData['order']=Number(currentData['order'])+1
                    return currentData; // 把單號+1，然後存回去
                  }
            },(error, committed, snapshot)=>{
                      if (error) {
                          this.setState({ 
                            statusInfo:'請檢查網路狀態，再列印一次!', 
                          })
                      } else if (committed) {
                          doTheRest()
                      } 
            }
          )
          
          
    
  }

  //如果單號存在，我才要讓系統存
  //!this.state.poNumber||
  //||!this.state.weight
  
  if(this.state.deliveryNation==='ID'){
        if(this.state.department=== ''|| this.state.Sales==='' || this.state.extensionNumber===''|| this.state.pickUpCompanyName===''||this.state.pickUpAddress===''||this.state.deliveryTarget===''||this.state.deliveryContact===''){
          this.setState({ 
              statusInfo:'是不是有欄位忘記填了?',
          })
      
        }else if(this.state.upLoadStatus===''){
          this.setState({ 
            statusInfo:'發票是不是忘記上傳了?',
          })
        }else{                
          process()
                        
        }

  }
}

//這是給下面表格每一單需要重印標籤的時候用的
//基本上判斷是哪個國家的單，叫出資料以後，直接呼叫對應的印製方式
rePrint(e){
   let targetDeliveryNumber=e.target.name
   ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+this.state.userid+'/'+targetDeliveryNumber).on('value', (snapshot)=> {
      if(snapshot.val()){
        var target=snapshot.val()

        var logo=LogoImg();
        var dataURL=textToBase64Barcode(target.deliveryNumber);
        pdfmake.vfs = pdffonts.pdfMake.vfs;
        let LabelArr = [];

        let Label=[
          { table: {
            widths: ['auto', '*', '*', '*', '*', '*'],
            //下面是要做一開始的logo、barcode、和國碼標
            body: [
              [
                {
                // you'll most often use dataURI images on the browser side
                // if no width/height/fit is provided, the original size will be used
                image:logo,
                width:100, 
                height:20,
                alignment: 'center',
                colSpan: 3},
                '',
                '',
                { rowSpan: 2,
                  colSpan: 3,
                  text: target.deliveryNation,
                  style: 'head',  
                  width:100, 
                  alignment: 'center',          
                },
                '',
                ''

              ],
              [{ image:dataURL,
                colSpan: 3,
                width:140, 
                height:60,
                alignment: 'center',
              },
              {},
              {},
              {},
              {},
              {}
              ],
              //SG這一格是設成zipcode
              [{ text: 'A/C NO:'},
                {text: '',
                colSpan: 5},
              {},
              {},
              {},
              {}
              ],
              [{ text: 'PO:'},
              {text: target.poNumber,colSpan: 5},
              {},
              {},
              {},
              {}
              ],

              [{text: 'Shipper:'},
              {text:target.pickUpCompanyName,colSpan: 5},
              {},
              {},
              {},
              {}
              ],

              [{text: 'Consignee:'},
              {text: target.deliveryCompany+'\n Contact:'+target.deliveryContact,colSpan: 5},
              {},
              {},
              {},
              {}
              ],

              [{text: 'TEL:'},
              {text: this.state.deliveryPhone+'\n Ext:'+this.state.deliveryContactExtension,colSpan: 5},
              {},
              {},
              {},
              {}
              ],

              [{text: 'Address:',rowSpan: 2},
              {text: target.deliveryAddress,rowSpan: 2,colSpan: 5},
              {},
              {},
              {},
              {}
              ],
              [{},
              {},
              {},
              {},
              {},
              {}
              ],

              [{text: 'Declared Value:'},
              {text:'' ,colSpan: 5},
              {},
              {},
              {},
              {}
              ],

              [{text: 'Weight:'},
              //KGS前面後一格，看起來比較好看
              {text:'',colSpan: 5},
              {},
              {},
              {},
              {}
              ],
              [{text: 'Description:'},
              {text:'',colSpan: 5},
              {},
              {},
              {},
              {}
              ],

            ]
          },
          },     
        ];
        LabelArr.push(Label);
      

        pdfmake.fonts = {
          ch: {
          normal: 'kaiu.ttf',
          bold: 'kaiu.ttf',
          italics: 'kaiu.ttf',
          bolditalics: 'kaiu.ttf'
        }};
  
        var docDefinition = {
                content:LabelArr,
                defaultStyle: {
                font: 'ch',
                fontSize: 11
                } ,
                styles: {
                  head: {
                    
                    fontSize: 66,
                    bold: true
                  },
    
                },
    
                // a string or { width: number, height: number }
                // 下面是10*10的標籤設計
                pageSize: { width: 283, height: 425},
                
    
                // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                pageMargins: [ 3, 3,3, 0 ],
        };
      
      //準備出單印製
      pdfmake.createPdf(docDefinition).print();

      }

   })

}

//打開刪除確認頁
deleteOpen(e){
  var target=e.target.name
  this.setState({
    deleteFinish : true,
    deleteTarget : target,
  })
}

//關閉刪除確認頁
deleteClose=()=>{
  this.setState({
    deleteFinish : false
  })
}
//刪除歷史資料
deleteRecord(){
  this.setState({
    deleteFinish : false
  })

  let target=this.state.deleteTarget

  ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+this.state.userid+'/'+target).set({})
      
    
}


//這是日期選擇器挑選日子後的處理步驟
setStartDate(data){
  this.setState({
    pickUpDate : data
  })
}

setDepartment(event,data){
  if(data.value==='自行輸入'){
    this.setState({
      departmentOption: '自行輸入'
    })
  }else{
    this.setState({
      department : data.value,
      departmentOption: data.value
    })
  }
}

setDeliveryTarget(event,data){
  if(data.value==='TEI'){
      this.setState({
        deliveryTarget : data.value,
        deliveryCompany: 'PT.TAINAN ENTERPRISES INDONESIA',
        deliveryAddress: 'KAWASAN BERIKAT NUSANTARA JL.IRIAN BLOK E NO.28 CAKUNG CILINCING JAKARTA UTARA 14140 INDONESIA',
        deliveryPhone:'021-44832171'
      })
  }else if(data.value==='SOLO1'){
      this.setState({
        deliveryTarget : data.value,
        deliveryCompany: 'PT.ANDALAN MANDIRI BUSANA(SOLO1)',
        deliveryAddress: 'JALAN SOLO-TAWANGMANGU KM9.9 JUMOK KISMOREJO JATEN KARANGANYAR JAWA TENGAH',
        deliveryPhone:'0271-8200430'
      })
  }else if(data.value==='SOLO2'){
    this.setState({
      deliveryTarget : data.value,
      deliveryCompany: 'PT.ANDALAN MANDIRI BUSANA(SOLO2)',
      deliveryAddress: 'JL.SOLO-SEMARANG DESA BATAN RT.013 RW.002 BATAN BANYUDONO BOYOLALI JAWA TENGAH KODE POS 57373 INDONESIA',
      deliveryPhone:'0271-7889254'
    })
  }else{
    this.setState({
      deliveryTarget : data.value,
    })
  }
  
}

handleUpload(event,data){
  //我要先把副檔名讀出來，存檔需要
  let fileType=data.value.split('.')[1]
  let fileName=this.state.deliveryNumber+'.'+fileType
  let year=this.state.deliveryNumber.slice(3, 5)
  let month=this.state.deliveryNumber.slice(5, 7)
  let day=this.state.deliveryNumber.slice(7, 9)
  firebaseStorage.ref('22306/'+year+'/'+month+'/'+day+'/'+fileName).put(event.target.files[0]).then((snapshot) => {
    this.setState({
      statusInfo : '發票上傳成功',
      upLoadStatus:'1',
      fileName:fileName
    })
  });

}

downloadShipInfo(){
  let preparePO=this.state.PrepareToShipData
  let readyPO=this.state.ReadyToShipData
  let finishPO=this.state.FinishToShipData
        
  let POs = [];
  
  let title=[
    '狀態',
    '快遞單號',
    "預定取件日",
    "預約部門",
    "預約人",
    "預約人分機號碼",
    "取件公司名",
    "取件聯絡人",
    "取件連絡電話",
    "取件地址",
    "大陸快遞單號",
    "目的地代號",
    "目的地公司名",
    "目的地聯絡人",
    "目的地連絡電話",
    "目的地分機號碼",
    "目的地地址",
    "訂單號",
    "裝櫃號",
    "Brand",
    "StyleNO",
    "season",
    "主副料名稱(QUANTITY)",
    "重量",
    "批價"


    ];

  POs.push(title);

  
  Object.keys(preparePO).forEach(function(key) {
        var po = preparePO[key]

        let d=new Date(po.pickUpDate)
        let year=d.getFullYear();
        let month=d.getMonth()+1;
        let day=d.getDate();
        let pickUpDate=year+'/'+month+'/'+day

        let poArray = [
          '待處理',
          po.deliveryNumber,
          pickUpDate,
          po.department,
          po.Sales,
          po.extensionNumber,
          po.pickUpCompanyName,
          po.pickUpContactName,
          po.pickUpContactPhone,
          po.pickUpAddress,
          po.chinaDeliveryNumber,
          po.deliveryTarget ,  
          po.deliveryCompany ,
          po.deliveryContact ,
          po.deliveryPhone ,
          po.deliveryContactExtension,
          po.deliveryAddress ,
          po.poNumber ,
          po.containerNumber ,
          po.brand,
          po.styleNO,
          po.season,
          po.quantity ,
        ]
        
        POs.push(poArray);
  });

  Object.keys(readyPO).forEach(function(key)  {
    var po = readyPO[key]

    let d=new Date(po.pickUpDate)
    let year=d.getFullYear();
    let month=d.getMonth()+1;
    let day=d.getDate();
    let pickUpDate=year+'/'+month+'/'+day

    let poArray = [
      '已通知',
      po.deliveryNumber,
      pickUpDate,
      po.department,
      po.Sales,
      po.extensionNumber,
      po.pickUpCompanyName,
      po.pickUpContactName,
      po.pickUpContactPhone,
      po.pickUpAddress,
      po.deliveryTarget ,  
      po.deliveryCompany ,
      po.deliveryContact ,
      po.deliveryPhone ,
      po.deliveryAddress ,
      po.poNumber ,
      po.containerNumber ,
      po.brand,
      po.styleNO,
      po.season,
      po.quantity ,
    ]
    
    POs.push(poArray);
});

Object.keys(finishPO).forEach(function(key)  {
  var po = finishPO[key]

  let d=new Date(po.pickUpDate)
  let year=d.getFullYear();
  let month=d.getMonth()+1;
  let day=d.getDate();
  let pickUpDate=year+'/'+month+'/'+day

  let poArray = [
    '已出貨',
    po.deliveryNumber,
    pickUpDate,
    po.department,
    po.Sales,
    po.extensionNumber,
    po.pickUpCompanyName,
    po.pickUpContactName,
    po.pickUpContactPhone,
    po.pickUpAddress,
    po.deliveryTarget ,  
    po.deliveryCompany ,
    po.deliveryContact ,
    po.deliveryPhone ,
    po.deliveryAddress ,
    po.poNumber ,
    po.containerNumber ,
    po.brand,
    po.styleNO,
    po.season,
    po.quantity ,
    po.weight,
    po.quotePrice,
  ]
  
  POs.push(poArray);
});




  //創造一張新的表
  const wb = XLSX.utils.book_new();
  //下面這句就是把POs放進我們的sheet裡面
  const wsAll = XLSX.utils.aoa_to_sheet(POs);
  //把Sheet放進我們的Excel檔案裡面
  XLSX.utils.book_append_sheet(wb, wsAll, "寄送內容清單");

  //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
  var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
  //利用saveAs就可以把這個檔案存出來
  saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day}-台南企業-跨境通運送清單.xlsx`);
  
  

}

download45ShipInfo(){
  var FinishToShipData=[]
  for (var i = 0; i < 45; i++) {
      var d = new Date();
      d.setDate(d.getDate() - i);
      let year=d.getFullYear();
      let month=d.getMonth()+1;
      if(month.toString().length===1){
        month="0"+month;
      };
      let day=d.getDate();
      if(day.toString().length===1){
        day="0"+day;
      };

      ref.child('/infos/customers/'+this.state.customerNumber+'/'+year+'/'+month+'/'+day+'/'+this.state.deliveryNation+'/'+this.state.userid).on('value', (snapshot)=> {
        if(snapshot.val()){
            let queryResult=snapshot.val();
            //console.log(queryResult)
            
            var keyList=[]
            Object.keys(queryResult).forEach((key)=> {
                keyList.push(key)
            })
            keyList=keyList.reverse()
            keyList.forEach((key)=>{
              var po=queryResult[key];

              let finishDate=year+'/'+month+'/'+day
              var po=queryResult[key];
              po['finishDate']=finishDate

              FinishToShipData.push(po)
          })
        }
      })
  }
  let POs = [];
  
  let title=[
    '狀態',
    '快遞單號',
    "預定取件日",
    "預約部門",
    "預約人",
    "預約人分機號碼",
    "取件公司名",
    "取件聯絡人",
    "取件連絡電話",
    "取件地址",
    "大陸快遞單號",
    "目的地代號",
    "目的地公司名",
    "目的地聯絡人",
    "目的地連絡電話",
    "目的地分機號碼",
    "目的地地址",
    "訂單號",
    "裝櫃號",
    "Brand",
    "StyleNO",
    "season",
    "主副料名稱(QUANTITY)",
    "重量",
    "批價"


    ];

  POs.push(title);

  Object.keys(FinishToShipData).forEach(function(key)  {
    var po = FinishToShipData[key]
  
    let d=new Date(po.pickUpDate)
    let year=d.getFullYear();
    let month=d.getMonth()+1;
    let day=d.getDate();
    let pickUpDate=year+'/'+month+'/'+day
  
    let poArray = [
      '已出貨',
      po.deliveryNumber,
      pickUpDate,
      po.department,
      po.Sales,
      po.extensionNumber,
      po.pickUpCompanyName,
      po.pickUpContactName,
      po.pickUpContactPhone,
      po.pickUpAddress,
      po.deliveryTarget ,  
      po.deliveryCompany ,
      po.deliveryContact ,
      po.deliveryPhone ,
      po.deliveryAddress ,
      po.poNumber ,
      po.containerNumber ,
      po.brand,
      po.styleNO,
      po.season,
      po.quantity ,
      po.weight,
      po.quotePrice,
    ]
    
    POs.push(poArray);
  });
  
  
  
  
    //創造一張新的表
    const wb = XLSX.utils.book_new();
    //下面這句就是把POs放進我們的sheet裡面
    const wsAll = XLSX.utils.aoa_to_sheet(POs);
    //把Sheet放進我們的Excel檔案裡面
    XLSX.utils.book_append_sheet(wb, wsAll, "已寄送清單");
  
    //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
    var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
    //利用saveAs就可以把這個檔案存出來
    saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day}-台南企業-跨境通45天出貨記錄.xlsx`);

}

download(e){
  let fileName=e.target.name
  let target=e.target.name.split('.')[0]
  let year=target.slice(3, 5)
  let month=target.slice(5, 7)
  let day=target.slice(7, 9)
  firebaseStorage.ref('22306/'+year+'/'+month+'/'+day+'/'+fileName).getDownloadURL()
  .then((url) => {
    saveAs(url, fileName);
  })
  .catch((error) => {
    // Handle any errors
  });
}

download45ShipInfo

render() {
  
   
   return (

    <div  style={{ 'padding-left': '1.5em','padding-right': '1.5em' }}>
        <Header as='h2'>您好,台南企業。</Header>
             
       <div style={{ 'padding-top': '0.5em','padding-left': '0.5em','padding-right': '0.5em','padding-bottom': '2em',width:'96%',margin: 'auto'}}>
         <Grid  divided='vertically' centered >
             <Grid.Row columns={1}>  
                 <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                 
                   <Segment raised>  
                         <Label as='a' color='red' ribbon>
                         請輸入寄件資料
                         </Label>
                         
                         <Form style={{'font-size':'16px'}}>
                             <Form.Group widths='equal' id='shipperForm' > 
                                 <Form.Field>
                                     <label >國家別</label>
                                    
                                     <Form.Group style={{ 'padding-right': '0em'}} >
                                          <Form.Field
                                              control={Radio}
                                              label='印尼'
                                              value='ID'
                                              checked={this.state.deliveryNation=== 'ID'}
                                              onChange={this.changeDeliveryNation}
                                              style={{'font-size':'18px','font-weight':'bolder'}}
                                          />
                                     </Form.Group>
                                 </Form.Field> 
                                 <Form.Field>
                                     <label >取件日期</label>
                                     <DatePicker  selected={this.state.pickUpDate} onChange={date => this.setStartDate(date)} />                                                       
                                 </Form.Field>
                             </Form.Group>
                             <Form.Group widths='equal'  > 
                                 <Form.Field>
                                     <label style={{'font-weight':'bolder'}}>部門</label>
                                     <Dropdown placeholder='選擇部門' search selection 
                                               text={this.state.departmentOption}
                                               value={this.state.departmentOption}
                                               options={[{text:'S1-A',value:'S1-A'},
                                                         {text:'S1-B',value:'S1-B'},
                                                         {text:'S1-C',value:'S1-C'},
                                                         {text:'S3',value:'S3'},
                                                         {text:'S4-A',value:'S4-A'},
                                                         {text:'S4-B',value:'S4-B'},
                                                         {text:'S4-C',value:'S4-C'},
                                                         {text:'S6',value:'S6'},
                                                         {text:'S9',value:'S9'},
                                                         {text:'S10',value:'S10'},
                                                         {text:'RD',value:'RD'},
                                                         {text:'FD',value:'FD'},
                                                         {text:'採購',value:'採購'},
                                                         {text:'產銷',value:'產銷'},
                                                         {text:'自行輸入',value:'自行輸入'},]} 
                                               onChange={(event,data)=> this.setDepartment(event,data)}
                                               />
                                 </Form.Field> 
                                 {this.state.departmentOption==='自行輸入' ?
                                  
                                      <Form.Field>
                                          <label style={{'font-weight':'bolder'}}>請填部門名</label>
                                          <Input placeholder='部門' value={this.state.department}  name="department" onChange={this.handleInputChange} />
                                      </Form.Field>                              
                                  
                                  
                                  :
                                  <></>
                                 }
                                
                             </Form.Group>
                             <Form.Group widths='equal'  > 
                                 <Form.Field>
                                     <label style={{'font-weight':'bolder'}}>申請人</label>
                                     <Input placeholder='姓名' value={this.state.Sales}  name="Sales" onChange={this.handleInputChange} />
                                 </Form.Field> 
                                 <Form.Field>
                                     <label style={{'font-weight':'bolder'}}>分機號碼</label>
                                     <Input placeholder='號碼' value={this.state.extensionNumber}  name="extensionNumber" onChange={this.handleInputChange} />
                                 </Form.Field>                           
                             </Form.Group>
                             
                             <Divider horizontal style={{ color: 'red','font-size':'22px'}}>取件資料</Divider>
                             <Form.Group widths='equal'  > 
                                 <Form.Field>
                                     <label style={{'font-weight':'bolder'}}>取件公司名</label>
                                     <Input placeholder='公司名' value={this.state.pickUpCompanyName}  name="pickUpCompanyName" onChange={this.handleInputChange} />
                                 </Form.Field>  
                                 <Form.Field>
                                     <label style={{'font-weight':'bolder'}}>取件聯絡人</label>
                                     <Input placeholder='人名' value={this.state.pickUpContactName}  name="pickUpContactName" onChange={this.handleInputChange}/>
                                 </Form.Field>  
                                 <Form.Field>
                                     <label style={{'font-weight':'bolder'}}>取件聯絡電話</label>
                                     <Input placeholder='電話' value={this.state.pickUpContactPhone}  name="pickUpContactPhone" onChange={this.handleInputChange}/>
                                 </Form.Field>   
                             </Form.Group>

                             <Form.Group >
                                 <Form.Field width={12}>
                                     <label style={{'font-weight':'bolder'}}>取件地址</label>
                                     <Input placeholder='地址' value={this.state.pickUpAddress}  name="pickUpAddress" onChange={this.handleInputChange}/>
                                 </Form.Field> 
                                 <Form.Field width={4}>
                                     <label style={{'font-weight':'bolder'}}>內陸快遞單號</label>
                                     <Input placeholder='' value={this.state.chinaDeliveryNumber}  name="chinaDeliveryNumber" onChange={this.handleInputChange}/>
                                 </Form.Field> 
                             </Form.Group>

                             <Divider horizontal style={{ color: 'red','font-size':'22px'}}>目的地資料</Divider>
                             
                             <Form.Group > 
                                 <Form.Field width={6}>
                                     <label style={{'font-weight':'bolder'}}>收件公司</label>
                                     <Dropdown placeholder='選擇目的地' search selection 
                                               value={this.state.deliveryTarget}
                                               options={[{text:'TEI',value:'TEI'},
                                                         {text:'SOLO1',value:'SOLO1'},
                                                         {text:'SOLO2',value:'SOLO2'},
                                                         {text:'其他',value:'1'},
                                                        ]} 
                                               onChange={(event,data)=> this.setDeliveryTarget(event,data)}
                                               />
                                 </Form.Field> 
                                 <Form.Field width={6}>
                                      <label style={{'font-weight':'bolder'}}>收件人</label>
                                      <Input placeholder='姓名' value={this.state.deliveryContact}  name="deliveryContact" onChange={this.handleInputChange} />
                                 </Form.Field> 
                                 <Form.Field width={4}>
                                      <label style={{'font-weight':'bolder'}}>收件人分機號碼</label>
                                      <Input placeholder='分機號碼' value={this.state.deliveryContactExtension}  name="deliveryContactExtension" onChange={this.handleInputChange} />
                                 </Form.Field> 
                                    
                             </Form.Group>
                             {this.state.deliveryTarget==='1' ?
                                <Form.Group widths='equal'  > 
                                  <Form.Field>
                                      <label style={{'font-weight':'bolder'}}>請填收件公司名</label>
                                      <Input placeholder='' value={this.state.deliveryCompany}  name="deliveryCompany" onChange={this.handleInputChange} />
                                  </Form.Field>  
                                  <Form.Field>
                                      <label style={{'font-weight':'bolder'}}>請填收件地址</label>
                                      <Input placeholder='' value={this.state.deliveryAddress}  name="deliveryAddress" onChange={this.handleInputChange} />
                                  </Form.Field> 
                                  <Form.Field>
                                      <label style={{'font-weight':'bolder'}}>請填收件連絡電話</label>
                                      <Input placeholder='' value={this.state.deliveryPhone}  name="deliveryPhone" onChange={this.handleInputChange} />
                                  </Form.Field> 
                                </Form.Group>                            
                              
                              
                              :
                              <></>
                             }
                          
                             <Divider horizontal style={{ color: 'red','font-size':'22px'}}>訂單相關資料</Divider>
                             
                             <Form.Group widths='equal'  > 
                                 <Form.Field>
                                     <label style={{'font-weight':'bolder'}}>PO NO.</label>
                                     <Input placeholder='PO' value={this.state.poNumber}  name="poNumber" onChange={this.handleInputChange}/>                
                                 </Form.Field>  
                                 <Form.Field>
                                     <label style={{'font-weight':'bolder'}}>裝櫃單號</label>
                                     <Input placeholder='單號' value={this.state.containerNumber}  name="containerNumber" onChange={this.handleInputChange}/>                
                                 </Form.Field>  
                             </Form.Group>
                             <Form.Group widths='equal'  > 
                                 <Form.Field>
                                     <label style={{'font-weight':'bolder'}}>Brand</label>
                                     <Input placeholder='' value={this.state.brand}  name="brand" onChange={this.handleInputChange}/>                
                                 </Form.Field>  
                                 <Form.Field>
                                     <label style={{'font-weight':'bolder'}}>Style NO.</label>
                                     <Input placeholder='' value={this.state.styleNO}  name="styleNO" onChange={this.handleInputChange}/>                
                                 </Form.Field>  
                             </Form.Group>
                             <Form.Group widths='equal'  > 
                                 <Form.Field>
                                     <label style={{'font-weight':'bolder'}}>Season</label>
                                     <Input placeholder='' value={this.state.season}  name="season" onChange={this.handleInputChange}/>                
                                 </Form.Field>  
                                 <Form.Field>
                                     <label style={{'font-weight':'bolder'}}>主副料名稱(QUANTITY)</label>
                                     <Input placeholder='' value={this.state.quantity}  name="quantity" onChange={this.handleInputChange}/>                
                                 </Form.Field>  
                             </Form.Group>
                            
                            

                             <Divider horizontal style={{ color: 'red','font-size':'22px'}}>上傳發票</Divider>
                             <Form.Group widths='equal'  > 
                                 <Form.Field>
                                     <Input placeholder='' type='file' key={this.state.upLoadStatus} accept='.xlsx, .xls,,.doc, .docx' onChange={(event,data)=>this.handleUpload(event,data)} />              
                                 </Form.Field>  
                                 <Form.Field>
                                    {this.state.statusInfo?
                                     <Label size='large' color='red'>{this.state.statusInfo}</Label>  
                                     :
                                     <></>
                                    }            
                                 </Form.Field>  
                                 <Form.Field>
                                     <Button size='large'  color='orange' onClick={this.print}>上傳快遞資料</Button>              
                                 </Form.Field> 

                             </Form.Group>
                                 
                            
                         </Form>
                   
                   </Segment>
                
             </Grid.Column>
             </Grid.Row>  

             <Grid.Row columns={1}> 
                      
                     <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                     <Label color='teal' size='big'><b>快捷鍵<i>"  &nbsp; Ctrl &nbsp;+ &nbsp;f   &nbsp;"</i>輸入申請人名字後，每按一次enter就會看到下一筆相關的快遞訂單喔!!</b></Label>
                       <Segment raised>  
                             <Label as='a' color='red' ribbon>
                             待處理快遞訂單
                             </Label>
                             {firebaseAuth.currentUser.uid!==this.state.userid?
                               <Button size='medium'  color='green' onClick={this.downloadShipInfo}>下載訂單資料</Button>
                               :
                               <></>
                             }
                           
                             <Table celled striped>
                              {this.state.userid===firebaseAuth.currentUser.uid?
                                  <Table.Header>
                                  <Table.Row>
                                      <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>快遞單號</Grid></Table.HeaderCell>
                                      <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>取件日期</Grid></Table.HeaderCell>
                                      <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>申請人</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>PO.No.</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>取件公司名</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>收件公司名</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>發票</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>印製標籤</Grid></Table.HeaderCell>
                            
                                  </Table.Row>
                                  </Table.Header>
                              : 
                                  <Table.Header>
                                  <Table.Row>
                                      <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>快遞單號</Grid></Table.HeaderCell>
                                      <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>取件日期</Grid></Table.HeaderCell>
                                      <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>申請人</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>PO.No.</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>取件公司名</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>收件公司名</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>發票</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>印製標籤</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>刪除</Grid></Table.HeaderCell>
                            
                                  </Table.Row>
                                  </Table.Header>
                              }

                                  <Table.Body>
                                      {this.state.PrepareToShip}

                                      <Confirm
                                      open={this.state.deleteFinish}
                                      content='確定要刪除訂單??'
                                      onCancel={this.deleteClose}
                                      onConfirm={this.deleteRecord}
                                      cancelButton='取消'
                                      confirmButton='確定'
                                    />
                                  </Table.Body>
                              </Table>
                                 
                       </Segment>
                    
                     </Grid.Column>
              </Grid.Row>


              <Grid.Row columns={1}>  
                     <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                     
                       <Segment raised>  
                             <Label as='a' color='red' ribbon>
                            已通知快遞訂單
                             </Label>
                             
                             <Table celled striped >
                                  <Table.Header>
                                  <Table.Row>
                                      <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>快遞單號</Grid></Table.HeaderCell>
                                      <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>申請人</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>PO.No.</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>取件公司名</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>收件公司名</Grid></Table.HeaderCell>
                                      
                                      
                            
                                  </Table.Row>
                                  </Table.Header>

                                  <Table.Body>
                                      {this.state.ReadyToShip}
                                  </Table.Body>
                              </Table>
                                 
                       </Segment>
                    
                     </Grid.Column>
              </Grid.Row>


              <Grid.Row columns={1}>  
                     <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                     
                       <Segment raised>  
                             <Label as='a' color='red' ribbon>
                              已出貨快遞訂單
                             </Label>
                             {firebaseAuth.currentUser.uid!==this.state.userid?
                               <Button size='medium'  color='green' onClick={this.download45ShipInfo}>下載45天內已出貨資料</Button>
                               :
                               <></>
                             }
                             
                             <Table celled striped >
                                  <Table.Header>
                                  <Table.Row>
                                       <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>快遞單號</Grid></Table.HeaderCell>
                                      <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>出貨日期</Grid></Table.HeaderCell>                                      
                                      <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>申請人</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>PO.No.</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>取件公司名</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>收件公司名</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>重量</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>批價</Grid></Table.HeaderCell>
                            
                                  </Table.Row>
                                  </Table.Header>

                                  <Table.Body>
                                      {this.state.FinishToShip}

                                      
                                  </Table.Body>
                              </Table>
                                 
                       </Segment>
                    
                     </Grid.Column>
              </Grid.Row>
             
         </Grid>
       </div>

    


    </div>

   );
  
}


}

export default App;
