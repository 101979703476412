import React, { Component } from 'react';
import { ref, firebaseAuth } from './firebase/firebaseConfig';

import 'semantic-ui-css/semantic.min.css';
import {
  Button,
  Grid,
  Input,
  Container,
  Divider,
  Card,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'




class App extends Component {

  constructor(props) {
    super(props);
    this.state = {

      'create':""
  };

    this.handleCreateMsg=  this.handleCreateMsg.bind(this);

  }





  handleCreateMsg(){
    if(this.state.email&&this.state.password){
          var errorCode ="";
          var errorMessage="";

          firebaseAuth.createUserWithEmailAndPassword(this.state.email, this.state.password).
          then((user)=>{
              let userid=user.user.uid;
              //要靠child才可以變成一個function，讓我可以寫資料庫
              ref.child('/users/'+userid).set({
                email: user.user.email,
                uid: user.user.uid,
                //這是到時候Admin的頁面要顯示客人的公司名，讓Admin可以知道
                companyNmae:'',
                //到時候要手動輸入，讓客人可以自己抓到自己的頁面
                companyCode:'',
                userModule:''
              })

              this.setState({'create':'請點我們的Logo回首頁啟用帳號，謝謝!!'})
          }).catch(function(error) {
              // Handle Errors here.
              errorCode = error.code;
              errorMessage = error.message;
              console.log(errorCode)

           }).then(()=>{
             if (errorCode == 'auth/email-already-in-use') {
              //關鍵寫法
                 this.setState({'create':'這個帳號有人申請了喔!!'})
            }
           });



    }else{
          this.setState({'create':'您的帳號、密碼不能空白喔!!'})
    }

  }




  render() {
    var create= this.state.create;
    var msg = [];
    msg.push(create);

    const { email, password } = this.state;

    return (
      <div>
          <Segment
            inverted
            textAlign='center'
            style={{ 'background-color': 'rgb(244, 173, 66)' }}
            vertical>

                  <div>
                  <Header as='h1'>歡迎光臨跨境通 您的貨有我就通</Header>

                  <Card centered>

                    <Card.Content>
                    <Input
                      type="text"
                      placeholder="輸入您的Email帳號"
                      onChange={e =>
                        this.setState({
                          email: e.target.value
                        })
                      }
                      value={email}
                      size='large'
                    />
                    <br />
                    <h6></h6>
                    <Input
                      type="password"
                      placeholder="設一下密碼"
                      onChange={e => this.setState({ password: e.target.value })}
                      value={password}
                      size='large'
                    />

                    </Card.Content>
                    <Card.Content extra>

                    <Button
                      type="submit"
                      onClick={this.handleCreateMsg}
                      size='large'
                    >
                      創建新帳號去
                    </Button>

                    </Card.Content>
                  </Card>
                  </div>
                  <h1>{msg}</h1>
            </Segment>

       </div>



    );


 }
}




export default App;
