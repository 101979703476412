//所有的Link目錄做在這裡
import React, { Component } from 'react';
import {firebaseAuth } from './firebase/firebaseConfig';
import 'semantic-ui-css/semantic.min.css';

import { Route,Link} from 'react-router-dom';
import {
  Image,
  Responsive,
  Segment,
  Visibility,
} from 'semantic-ui-react'
import Home from './Home.js';
import CreateAccount from './CreateAccount.js';
import DashBoard from './Customers/DashBoard.js';
import IDService from './Customers/IDservice.js';
import ScanToCheck from './Customers/helper/ScanToCheck.js';






class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'user':"",
      'companyCode':'',

  };

  }

  componentDidMount(){
    var user = firebaseAuth.currentUser;
    if(user){
        this.setState({'user':1})
    };
  }








  render() {

    return (
      <div>


              <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <Visibility
                  once={false}
                  onBottomPassed={this.showFixedMenu}
                  onBottomPassedReverse={this.hideFixedMenu}
                >
                        <Segment
                        textAlign='center'
                        style={{ minHeight: '50', padding: '1em 0em' }}
                        vertical>
                             <Link to="/"><Image size='large' rounded centered src='/img/logo.png' /></Link>
                        </Segment>

                </Visibility>


                        <Segment
                        inverted
                        textAlign='center'
                        style={{ minHeight: 1000, 'padding-top':'0.1em','background-color': 'rgb(244, 173, 66)' }}
                        vertical>

                                <Route exact path="/" component={Home}/>
                                <Route path="/CreateAccount" component={CreateAccount}/>
                                <Route path='/DashBoard/:CustomerNumber' component={DashBoard} />
                                <Route path='/IDService' component={IDService} />
                                <Route path='/ScanToCheck' component={ScanToCheck} />

                        </Segment>


              </Responsive>





      </div>




    );


 }
}

export default App;

/*<Route path="/Shunews" component={Shunews}/>
<Route path="/DMA" component={DMA}/>
<Route path="/Gcdp" component={Gcdp}/>
<Route path="/ICS" component={ICS}/>
<Route path="/PC" component={PC}/>
<Route path="/RTF" component={RTF}/>
<Route path="/Speech" component={Speech}/>
<Route path="/Tourism" component={Tourism}/>*/
