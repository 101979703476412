import { ref, firebaseAuth } from '../../firebase/firebaseConfig';

//這是要把今天的日期做出來
export function todayTitle () {
        var d = new Date();
        let year=d.getFullYear();
        let month=d.getMonth()+1;
        if(month.toString().length===1){
          month="0"+month;
        };
        let day=d.getDate();
        if(day.toString().length===1){
          day="0"+day;
        };
        let Title=year.toString()+month.toString()+day;
        
        return [Title,year,month,day];
}



//savePO(客戶編號,日期標,單一包裹資料)==>把單一包裹資料存到資料庫
export function savePO (customerNumber,year,month,day,goodData) {
  var userid = firebaseAuth.currentUser.uid;
  //用set，就是沒有這個node的時候就增加，有這個node的時候就更新。
  ref.child('/infos/customers/'+customerNumber+'/'+year+'/'+month+'/'+day+'/'+goodData.國別+'/'+userid+'/'+goodData.快遞單號).set(goodData);
  ref.child('/infos/dates/'+'/'+year+'/'+month+'/'+day+'/'+goodData.國別+'/'+customerNumber+'/'+userid+'/'+goodData.快遞單號).set(goodData);

}

//tellAdmin(客戶編號,國家別,日期標)==>通知後臺進單了
export function tellAdmin (customerNumber,nation,year,month,day) {
  ref.child('/status/'+year+'/'+month+'/'+day+'/byCustomer/'+customerNumber+'/'+ nation).once('value', (snapshot)=> {
       if(!snapshot.val()){
           ref.child('/status/'+year+'/'+month+'/'+day+'/byCustomer/'+customerNumber+'/'+ nation).set(
            'o.k.'
           );
       };
  });
  ref.child('/status/'+year+'/'+month+'/'+day+'/byNation/'+nation+'/'+customerNumber).once('value', (snapshot)=> {
       if(!snapshot.val()){
           ref.child('/status/'+year+'/'+month+'/'+day+'/byNation/'+nation+'/'+customerNumber).set(
            'o.k.'
           );
       };
  });
}

//saveDeliveryNumber(客戶編號,日期標,最後流水號)==>把最後一碼記下來，準備繼續編碼。
export function saveDeliveryNumber (customerNumber,todayTitle,cumulatedDeliveryNumber) {
  var userid = firebaseAuth.currentUser.uid;
  ref.child('/infos/customers/'+customerNumber+'/'+todayTitle+'/cumulatedDeliveryNumber').set(
    cumulatedDeliveryNumber
  );

}

//印尼服務系統把單一訂單包裹資料存到資料庫
//我沒有辦法用這個function  setState所以先寫到原始存檔的位置
/*
export function IDSavePO (todayTitle,orderDeliveryNumber,goodData) {
  //var userid = firebaseAuth.currentUser.uid;
  if(orderDeliveryNumber!==''){
    var updates = {};
    updates['/IDService/'+todayTitle+'/'+orderDeliveryNumber] = goodData;
    return ref.update(updates);
  }else{
    return this.setState({message:'你要輸入資料喔!'})
  }
  
}
*/

//印尼服務系統把shipper資料存到資料庫
export function IDSaveShipper (shipperPhoneNumber,goodData) {
  if(shipperPhoneNumber!==''){
    var updates = {};
    updates['/IDService/shipper/' + shipperPhoneNumber] = goodData;
    return ref.update(updates);
  }
}

//為了避免Cog和Rec電話一樣互相蓋掉資料，所以我決定把Cog和Rec分開存
export function IDSaveCog (cogPhoneAreaNumber,cogPhoneNumber,goodData) {
  var updates = {};
  /*
  if(shipperPhoneNumber!=='' && cogPhoneNumber!==''){
    updates['/IDService/consignee/'+shipperPhoneNumber+'/'+cogPhoneNumber] = goodData;   
  }
  */

  if(cogPhoneAreaNumber!=='' && cogPhoneNumber!==''){
    updates['/IDService/consignee/'+(cogPhoneAreaNumber+cogPhoneNumber)] = goodData;  
  }
  return ref.update(updates);
}

export function IDSaveRec (recPhoneAreaNumber,recPhoneNumber,goodData) {
  var updates = {};

  if(recPhoneAreaNumber!=='' && recPhoneNumber!==''){
    updates['/IDService/receiver/'+(recPhoneAreaNumber+recPhoneNumber)] = goodData;  
  }
  return ref.update(updates);
}













/*這個有異步處理的問題，要再討論
export function checkDeliveryNumber (customerNumber) {

  function snapshotToArray(snapshot) {
     var returnArr = [];
     snapshot.forEach(function(childSnapshot) {
         var item = childSnapshot.val();
         returnArr.push(item);
     });
     return returnArr;
  };
  var userid = firebaseAuth.currentUser.uid;
  var d = new Date();
  let year=d.getFullYear();
  let month=d.getMonth()+1;
  if(month.toString().length===1){
    month="0"+month;
  };
  let day=d.getDate();
  if(day.toString().length===1){
    day="0"+day;
  };
  let todayTitle=year.toString()+month.toString()+day;

  var cumulatedDeliveryNumber=0;
  ref.child('/infos/'+customerNumber+'/deliveryNumber/'+todayTitle+'/'+userid+'/cumulatedDeliveryNumber').once('value', (snapshot)=> {

   cumulatedDeliveryNumber=snapshot.val();

 }).then(()=>{this.setState({'cumulatedDeliveryNumber':cumulatedDeliveryNumber})});



}*/
