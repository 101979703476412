import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import {todayTitle,savePO,tellAdmin,saveDeliveryNumber} from './helper/SaveToDatabase';
import {LogoImg,textToBase64Barcode} from './helper/Helper';
import pdfmake from 'pdfmake/build/pdfmake'
import pdffonts from 'pdfmake/build/vfs_fonts';
import saveAs from 'file-saver';
import {Button, Input, Confirm,Table,Modal,Grid,Segment,Label,Header} from 'semantic-ui-react';
import {ref, firebaseAuth,firebaseStorage} from '../firebase/firebaseConfig';
import 'semantic-ui-css/semantic.min.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
var JsBarcode = require('jsbarcode');


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      //這個每個客人都要改
      'customerNumber':'22306',
      'deliveryNation':'ID',
      
      'today':todayTitle()[0],
      'year':todayTitle()[1],
      'month':todayTitle()[2],
      'day':todayTitle()[3],
      'userid':'',
      
      'printStatus':false,
      

      'pickUpDate':new Date(),
      'department': '1',
      "Sales":'',
      "extensionNumber" :'',

      
      "pickUpCompanyName":'',
      "pickUpContactName":'',
      "pickUpContactPhone":'',
      "pickUpAddress":'',
      
      'deliveryTarget':'',
      "deliveryCompany":'',
      "deliveryAddress":'',
      "deliveryPhone":'',
      'deliveryContact':'',

      "poNumber":'',
      "containerNumber":'',
      "brand":'',
      "styleNO":'',
      "season":'',
      "quantity":'',

      'upLoadStatus':'',
      'weight':'',
      'quotePrice':'',
      'statusInfo':'',
      'FinishToShip':''
    };
    
   
   
    
    this.handleInputChange=this.handleInputChange.bind(this);
    
    this.prepareOpen=this.prepareOpen.bind(this);
    this.prepareClose=this.prepareClose.bind(this);
    this.toReadyShip=this.toReadyShip.bind(this);
    this.readyOpen=this.readyOpen.bind(this);
    this.readyClose=this.readyClose.bind(this);
    this.toFinishShip=this.toFinishShip.bind(this);
    this.downloadShipInfo=this.downloadShipInfo.bind(this);


  }


componentDidMount (){
    var userid = firebaseAuth.currentUser.uid;

    ref.child('/users/'+userid+'/uid').on('value', (snapshot)=> {
          if(snapshot.val()){
              this.setState({
                'userid':snapshot.val(),
              })

              userid=snapshot.val()
              ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+userid).on('value', (snapshot)=> {
                        if(snapshot.val()){
                            
                              let queryResult=snapshot.val();
                              //console.log(queryResult)
                              var PrepareToShip=[]
                              var PrepareToShipData=[]
                              var keyList=[]
                              Object.keys(queryResult).forEach((key)=> {
                                  keyList.push(key)
                              })
                              keyList=keyList.reverse()
                              keyList.forEach((key)=>{
                              var po=queryResult[key];

                              let d=new Date(po.pickUpDate)
                              let year=d.getFullYear();
                              let month=d.getMonth()+1;
                              let day=d.getDate();
                              let pickUpDate=year+'/'+month+'/'+day

                              PrepareToShipData.push(po)
                              PrepareToShip.push(
                                    <Table.Row style={{'height':44 }} >                            
                                        <Table.Cell width={1} >
                                            {po.deliveryNumber}
                                        </Table.Cell>
                                        <Table.Cell >
                                            <Grid centered style={{'padding-top':'0.3em' }}>{pickUpDate}</Grid>
                                        </Table.Cell>              
                                        <Table.Cell>
                                            <Grid centered style={{'padding-top':'0.3em' }}>{po.pickUpCompanyName}</Grid>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Grid centered style={{'padding-top':'0.3em' }}>{po.pickUpContactName}</Grid>
                                        </Table.Cell>
                                        <Table.Cell  width={2} textAlign='left'>
                                            {po.pickUpContactPhone}
                                        </Table.Cell>
                                        <Table.Cell width={5} textAlign='left'>
                                            {po.pickUpAddress}
                                        </Table.Cell>
                                        <Table.Cell  textAlign='left'>
                                            {po.chinaDeliveryNumber}
                                        </Table.Cell>
                                        <Table.Cell>                               
                                              <Grid centered style={{'padding-top':'0.3em' }}><Button content='完成'  size='medium' color='red' name={po.deliveryNumber} onClick={(e)=>this.prepareOpen(e)} style={{ 'font-size':'12px','font-weight':'bold'}}/></Grid>
                                        </Table.Cell>  
                                                             
                                    </Table.Row>
                                )
                              })
              
                              
                              this.setState({
                                PrepareToShip:PrepareToShip,
                                PrepareToShipData:PrepareToShipData
                              })

                            }else{
                              
                                  this.setState({
                                    PrepareToShip:'',
                                    PrepareToShipData:''
                                  })
              
                            }
            
              })

              ref.child('/infos/customers/'+this.state.customerNumber+'/ReadyToShip/'+userid).on('value', (snapshot)=> {
                if(snapshot.val()){
                    
                      let queryResult=snapshot.val();
                      //console.log(queryResult)
                      var ReadyToShip=[]
                      var ReadyToShipData=[]
                      var keyList=[]
                      Object.keys(queryResult).forEach((key)=> {
                          keyList.push(key)
                      })
                      keyList=keyList.reverse()
                      keyList.forEach((key)=>{
                        var po=queryResult[key];
                        ReadyToShipData.push(po)
                        ReadyToShip.push(
                            <Table.Row style={{'height':44 }} >                            
                                <Table.Cell width={1} >
                                            {po.deliveryNumber}
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.pickUpCompanyName}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.pickUpContactName}</Grid>
                                </Table.Cell>
                                <Table.Cell  width={2} textAlign='left'>
                                    {po.pickUpContactPhone}
                                </Table.Cell>
                                <Table.Cell width={5} textAlign='left'>
                                    {po.pickUpAddress}
                                </Table.Cell>
                                <Table.Cell>                               
                                      <Grid centered style={{'padding-top':'0.3em' }}><Button content='下載'  size='medium' color='green' name={po.fileName} onClick={(e)=>this.download(e)} style={{ 'font-size':'12px','font-weight':'bold'}}/></Grid>
                                </Table.Cell>  
                                
                                <Table.Cell>                               
                                      <Grid centered style={{'padding-top':'0.3em' }}><Button content='完成'  size='medium' color='red' name={po.deliveryNumber} onClick={(e)=>this.readyOpen(e)} style={{ 'font-size':'12px','font-weight':'bold'}}/></Grid>
                                </Table.Cell>  
                                                     
                            </Table.Row>
                        )
                      })
      
                      
                      this.setState({
                        ReadyToShip:ReadyToShip,
                        ReadyToShipData:ReadyToShipData
                      })

                    }else{
                      
                          this.setState({
                            ReadyToShip:'',
                            ReadyToShipData:''
                          })
      
                    }
    
              })

              var FinishToShip=[]
              var FinishToShipData=[]
              for (var i = 0; i < 10; i++) {
                  var d = new Date();
                  d.setDate(d.getDate() - i);
                  let year=d.getFullYear();
                  let month=d.getMonth()+1;
                  if(month.toString().length===1){
                    month="0"+month;
                  };
                  let day=d.getDate();
                  if(day.toString().length===1){
                    day="0"+day;
                  };
                  ref.child('/infos/customers/'+this.state.customerNumber+'/'+year+'/'+month+'/'+day+'/'+this.state.deliveryNation+'/'+this.state.userid).on('value', (snapshot)=> {
                    if(snapshot.val()){
                      let queryResult=snapshot.val();
                      //console.log(queryResult)
                      
                      var keyList=[]
                      Object.keys(queryResult).forEach((key)=> {
                          keyList.push(key)
                      })
                      keyList=keyList.reverse()
                      keyList.forEach((key)=>{
                        let finishDate=year+'/'+month+'/'+day
                        var po=queryResult[key];
                        po['finishDate']=finishDate
                        FinishToShipData.push(po)
                        FinishToShip.push(
                            <Table.Row style={{'height':44 }} > 
                                <Table.Cell width={1} >
                                            {po.deliveryNumber}
                                </Table.Cell>
                                <Table.Cell >
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.finishDate}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.pickUpCompanyName}</Grid>
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid centered style={{'padding-top':'0.3em' }}>{po.deliveryCompany}</Grid>
                                </Table.Cell>
                                
                                <Table.Cell>                               
                                    <Grid centered style={{'padding-top':'0.3em'}}>{po.weight}</Grid>
                                </Table.Cell>  
                                
                                <Table.Cell>                               
                                    <Grid centered style={{'padding-top':'0.3em'}}>{po.quotePrice}</Grid>
                                </Table.Cell>  
                                                     
                            </Table.Row>
                        )
                      })
                      
                      
                      this.setState({ 
                        FinishToShip: FinishToShip,
                        FinishToShipData:FinishToShipData 
                      })

                    }
                  })

              }
          }
    })
  
    
    
  }



//這是所有欄位普用型的function
handleInputChange(e){
    this.setState({ [e.target.name]: e.target.value });  
}



//打開確認頁
prepareOpen(e){
  var target=e.target.name
  this.setState({
    prepareOpen : true,
    Target : target,
  })
}

//關閉確認頁
prepareClose=()=>{
  this.setState({
    prepareOpen : false
  })
}

toReadyShip(){
  this.setState({
    prepareOpen : false
  })

  let target=this.state.Target

  ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+this.state.userid+'/'+target).on('value', (snapshot)=> {
        if(snapshot.val()){
          ref.child('/infos/customers/'+this.state.customerNumber+'/ReadyToShip/'+this.state.userid+'/'+target).set(snapshot.val()).then(() => {
            ref.child('/infos/customers/'+this.state.customerNumber+'/PrepareToShip/'+this.state.userid+'/'+target).set({})
          })
        }
  })
  
}


readyOpen(e){
  var target=e.target.name
  this.setState({
    readyOpen : true,
    target : target,
  })
  console.log(this.state.PrepareToShipData)
  console.log(this.state.ReadyToShipData)
  console.log(this.state.FinishToShipData)
}

//關閉確認頁
readyClose=()=>{
  this.setState({
    readyOpen : false
  })
}


toFinishShip(){
  
  let target=this.state.target
 
  if(this.state.weight!=='' && this.state.quotePrice!==''){
    ref.child('/infos/customers/'+this.state.customerNumber+'/ReadyToShip/'+this.state.userid+'/'+target).on('value', (snapshot)=> {
          if(snapshot.val()){
              let data=snapshot.val()
              data['weight']=this.state.weight
              data['quotePrice']=this.state.quotePrice
              ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+this.state.deliveryNation+'/'+this.state.userid+'/'+target).set(data).then(() => {
                    ref.child('/infos/customers/'+this.state.customerNumber+'/ReadyToShip/'+this.state.userid+'/'+target).set({})
                    this.setState({
                      statusInfo : "",
                      readyOpen : false
                    })             
              })

          }
    })
  }else{
        this.setState({
          statusInfo : "请先输入资料"
        })
  }
  
  
}


download(e){
  let fileName=e.target.name
  let target=e.target.name.split('.')[0]
  let year=target.slice(3, 5)
  let month=target.slice(5, 7)
  let day=target.slice(7, 9)
  firebaseStorage.ref('22306/'+year+'/'+month+'/'+day+'/'+fileName).getDownloadURL()
  .then((url) => {
    saveAs(url, fileName);
  })
  .catch((error) => {
    // Handle any errors
  });
}

downloadShipInfo(){
  let preparePO=this.state.PrepareToShipData
  let readyPO=this.state.ReadyToShipData
        
  let POs = [];
  
  let title=[
    '状态',
    '快遞單號',
    "预定取件日",
    "预约部门", 
    "预约人", 
    "预约人分机号码", 
    "取件公司名",
    "取件联络人",
    "取件连络电话",
    "取件地址",
    '中国快递单号',
    "目的地代号",
    "目的地公司名",
    "目的地联络人",
    "目的地连络电话",
    '目的地分机',
    "目的地地址",
    "订单号",
    "装柜号",
    "内容物",

    ];

  POs.push(title);

  
  Object.keys(preparePO).forEach(function(key) {
        var po = preparePO[key]

        let d=new Date(po.pickUpDate)
        let year=d.getFullYear();
        let month=d.getMonth()+1;
        let day=d.getDate();
        let pickUpDate=year+'/'+month+'/'+day

        let poArray = [
          '待处理',
          po.deliveryNumber,
          pickUpDate,
          po.department,
          po.Sales,
          po.extensionNumber,
          po.pickUpCompanyName,
          po.pickUpContactName,
          po.pickUpContactPhone,
          po.pickUpAddress,
          po.chinaDeliveryNumber,
          po.deliveryTarget ,  
          po.deliveryCompany ,
          po.deliveryContact ,
          po.deliveryPhone ,
          po.deliveryContactExtension,
          po.deliveryAddress ,
          po.poNumber ,
          po.containerNumber ,
          po.quantity ,
        ]
        
        POs.push(poArray);
  });

  Object.keys(readyPO).forEach(function(key)  {
    var po = readyPO[key]

    let d=new Date(po.pickUpDate)
    let year=d.getFullYear();
    let month=d.getMonth()+1;
    let day=d.getDate();
    let pickUpDate=year+'/'+month+'/'+day

    let poArray = [
      '已通知',
      po.deliveryNumber,
      pickUpDate,
      po.department,
      po.Sales,
      po.extensionNumber,
      po.pickUpCompanyName,
      po.pickUpContactName,
      po.pickUpContactPhone,
      po.pickUpAddress,
      po.chinaDeliveryNumber,
      po.deliveryTarget ,  
      po.deliveryCompany ,
      po.deliveryContact ,
      po.deliveryPhone ,
      po.deliveryContactExtension,
      po.deliveryAddress ,
      po.poNumber ,
      po.containerNumber ,
      po.quantity ,
    ]
    
    POs.push(poArray);
});




  //創造一張新的表
  const wb = XLSX.utils.book_new();
  //下面這句就是把POs放進我們的sheet裡面
  const wsAll = XLSX.utils.aoa_to_sheet(POs);
  //把Sheet放進我們的Excel檔案裡面
  XLSX.utils.book_append_sheet(wb, wsAll, "寄送內容清單");

  //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
  var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
  //利用saveAs就可以把這個檔案存出來
  saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.year+this.state.month+this.state.day}-跨境通-印尼快遞清單.xlsx`);
  

}







render() {

   return (

    <div  style={{ 'padding-left': '.5em','padding-right': '.5em' }}>
        <Header as='h2'>您好,代理夥伴。</Header>
             
       <div style={{ 'padding-top': '0.5em','padding-left': '.5em','padding-right': '.5em','padding-bottom': '2em',width:'97%',margin: 'auto'}}>
         <Grid  divided='vertically' centered >

             <Grid.Row columns={1}>  
                     <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                     
                       <Segment raised>  
                             <Label as='a' color='red' ribbon>
                             待處理快遞訂單
                             </Label>
                             <Button size='medium'  color='green' onClick={this.downloadShipInfo}>下载快递资料</Button>
                             <Table celled striped>
                                <Table.Header>
                                  <Table.Row>
                                      <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>快递单号</Grid></Table.HeaderCell>
                                      <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>预约日期</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>取件公司名</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>取件联络人</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>取件联络电话</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>取件地址</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>中国快递单号</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>完成通知</Grid></Table.HeaderCell>
                            
                                  </Table.Row>
                                </Table.Header>
                              

                                <Table.Body>
                                      {this.state.PrepareToShip}

                                      <Confirm
                                      open={this.state.prepareOpen}
                                      content='確定完成通知??'
                                      onCancel={this.prepareClose}
                                      onConfirm={this.toReadyShip}
                                      cancelButton='取消'
                                      confirmButton='確定'
                                    />
                                  </Table.Body>
                              </Table>
                                 
                       </Segment>
                    
                     </Grid.Column>
              </Grid.Row>


              <Grid.Row columns={1}>  
                     <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                     
                       <Segment raised>  
                             <Label as='a' color='red' ribbon>
                            已通知快遞訂單
                             </Label>
                             
                             <Table celled striped>
                                 <Table.Header>
                                  <Table.Row>
                                      <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>快递单号</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>取件公司名</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>取件联络人</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>取件电话</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>取件地址</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>下载发票</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>完成出貨</Grid></Table.HeaderCell>
                            
                                  </Table.Row>
                                 </Table.Header>

                                  <Table.Body>
                                  
                                      {this.state.ReadyToShip}

                                      <Modal
                                        open={this.state.readyOpen}
                                      >
                                        <Modal.Header>請填寫{this.state.target}的重量和批價</Modal.Header>
                                        <Modal.Content centered >
                                             <div style={{'padding-left':'20em','padding-bottom':'1em' }}>
                                             <label style={{'font-weight':'bolder'}}>请填重量</label>
                                             <Input placeholder=''  value={this.state.weight}  name="weight" onChange={this.handleInputChange} />
                                             </div>
                                             <div style={{'padding-left':'20em' }}> 
                                             <label style={{'font-weight':'bolder'}}>请填批价</label>
                                             <Input placeholder=''  value={this.state.quotePrice}  name="quotePrice" onChange={this.handleInputChange} />
                                             </div>
                                             <div style={{'padding-left':'20em','font-size':20, 'color':'red','font-weight':'bolder'}}> 
                                                {this.state.statusInfo}
                                             </div>
                                        </Modal.Content>
                                        <Modal.Actions>
                                          <Button
                                            content="取消"
                                            onClick={this.readyClose}
                                          />
                                          <Button
                                            content="确认"
                                            labelPosition='right'
                                            icon='checkmark'
                                            onClick={this.toFinishShip}
                                            positive
                                          />
                                        </Modal.Actions>
                                      </Modal>
                                  </Table.Body>
                              </Table>
                                 
                       </Segment>
                    
                     </Grid.Column>
              </Grid.Row>


              <Grid.Row columns={1}>  
                     <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                     
                       <Segment raised>  
                             <Label as='a' color='red' ribbon>
                              已出貨快遞訂單
                             </Label>
                             
                             <Table celled striped>
                                  <Table.Header>
                                  <Table.Row>   
                                      <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>快递单号</Grid></Table.HeaderCell>
                                      <Table.HeaderCell ><Grid centered style={{'padding-top':'0.3em' }}>出貨日期</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>取件公司名</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>收件公司名</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>重量</Grid></Table.HeaderCell>
                                      <Table.HeaderCell><Grid centered style={{'padding-top':'0.3em' }}>批價</Grid></Table.HeaderCell>
                            
                                  </Table.Row>
                                  </Table.Header>

                                  <Table.Body>
                                      {this.state.FinishToShip}

                                      
                                  </Table.Body>
                              </Table>
                                 
                       </Segment>
                    
                     </Grid.Column>
              </Grid.Row>
             
         </Grid>
       </div>

    


    </div>

   );
  
}


}

export default App;
