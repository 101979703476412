//這一塊是為了讓DEC可以自己印標籤
import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import { ref, firebaseAuth,firebaseStorage} from '../firebase/firebaseConfig';
import {todayTitle,savePO,tellAdmin,saveDeliveryNumber} from './helper/SaveToDatabase';
import pdfmake from 'pdfmake/build/pdfmake';
import pdffonts from 'pdfmake/build/vfs_fonts';
import {Button,Input,Table} from 'semantic-ui-react';
import saveAs from 'file-saver';
import 'semantic-ui-css/semantic.min.css';
var JsBarcode = require('jsbarcode');

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'customerNumber':this.props.CustomerNumber,
      'todayTitle':'',
      'cumulatedDeliveryNumber':0,
      'KRPO':[],
      'KRUpload':'',
      'MYPO':[],
      'MYUpload':'',
      'SGPO':[],
      'SGUpload':'',
      'IDPO':[],
      'IDUpload':'',

    };
    this.componentDidMount=this.componentDidMount.bind(this);
    this.KRImportExcel=this.KRImportExcel.bind(this);
    this.MYImportExcel=this.MYImportExcel.bind(this);
    this.SGImportExcel=this.SGImportExcel.bind(this);
    this.IDImportExcel=this.IDImportExcel.bind(this);
    this.handleMYLable=this.handleMYLable.bind(this);
    this.handleSGLable=this.handleSGLable.bind(this);
    this.handleIDLable=this.handleIDLable.bind(this);
    
  }
  
  componentDidMount(){
   
        let today=todayTitle();
        this.setState({
          'todayTitle':today,  
        }); 

        
        
        
        var cumulatedDeliveryNumber=0;
        ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.todayTitle+'/cumulatedDeliveryNumber').on('value', (snapshot)=> {
          //console.log(snapshot.val());  

            if(snapshot.val()){
            cumulatedDeliveryNumber=snapshot.val();
            };

            this.setState({
              'cumulatedDeliveryNumber':cumulatedDeliveryNumber,
            })

            //console.log(this.state.cumulatedDeliveryNumber);
            //console.log(this.state.todayTitle);
        })
        
  };

    
       

  //這一個function就是要把發票印出來
  handleInvoice(){
    pdfmake.vfs = pdffonts.pdfMake.vfs;
    //利用下面的function就可以把DataURL導出來


    let goodData=this.state['PO'];
    //goodData是一個object，不是Array


    var arr = [];

    //idx是array的 index
    Object.keys(goodData).forEach(function(key,idx,array) {
    var po=goodData[key];
    let title={
      text: 'COMMERCIAL INVOICE',
      style: 'header',
      alignment: 'center',
    };
    arr.push(title);

    let date={
         columns: [
         {
           // auto-sized columns have their widths based on their content
           width: '*',
           text: 'Date/日期:'+po.日期
         },
         {
           // star-sized columns fill the remaining space
           // if there's more than one star-column, available width is divided equally
           width: '*',
           text: 'CWB NO./提單號碼:'+po.單號
         },


       ],
       // optional space between columns
       margin: [ 0, 10, 0, 5 ] ,
    };
    arr.push(date);

    let Consignee={
      text: 'Consignee/收件人:'+po.收件人,
      margin: [ 0, 5, 0, 5 ] ,
    };
    arr.push(Consignee);

    let Address={
      text: 'Address/地址:'+po.收件地址,
      margin: [ 0, 5, 0, 5 ] ,
    };
    arr.push(Address);

    let Tel={
      text: 'TEL/電話:'+po.連絡電話,
      margin: [ 0, 5, 0, 5 ] ,
    };
    arr.push(Tel);

    let Name={
      text: 'Name/姓名:'+po.連絡人,
      margin: [ 0, 5, 0, 5 ] ,
    };
    arr.push(Name);

    var bodyCotent = [[ ['No of PKG', '包裹件數'], ['Description','物品內容'], ['Quantity','物品數量'], ['Unit Price', '單價'],['Amount', '總價' ]],];
    Object.keys(po.內容物).forEach(function(key) {
     var item=po.內容物[key];
     let itemDetail=[item.件數,item.內容物,item.公斤數,'',item.報關價值];
     bodyCotent.push(itemDetail);
    });

    let Table={
      table: {
        heights: 20,
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: [ '*', '*', '*' ,'*', '*' ],

        body:bodyCotent
      },
      alignment: 'center'

    };
    arr.push(Table);


    if (idx === array.length - 1){

    let Foot={
        columns: [
          { width: 350,
            stack: [
              { text: 'County of origin/物品來源地:Taiwan',
                margin: [ 0, 5, 0, 5 ] ,
              },
              { text: 'Shipper/發貨人:'+po.寄件人,
                margin: [ 0, 5, 0, 5 ] ,
              },
              { text: 'Address/地址:'+po.寄件地址,
                margin: [ 0, 5, 0, 5 ] ,
              },
              { text: 'TEL/電話:',
                margin: [ 0, 5, 0, 5 ] ,
              },
              { text: 'Name/姓名:',
                margin: [ 0, 5, 0, 5 ] ,
              },
              { text: 'Sign/簽章:',
                margin: [ 0, 5, 0, 5 ] ,
              },
            ]
          },
          {
            width: '*',
            stack: [
              { text: '統一發票章:',
                margin: [ 0, 5, 0, 5 ] ,
              },
              {table: {
                heights: 100,
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                headerRows: 1,
                widths: [ 100 ],

                body: [

                  [ '',  ],


                ]
              }},


            ]
          },

        ],
          margin: [ 0, 20, 0, 5 ],

      };
        arr.push(Foot);
    }else{

      let Foot= {
          columns: [
            { width: 350,
              stack: [
                { text: 'County of origin/物品來源地:',
                  margin: [ 0, 5, 0, 5 ] ,
                },
                { text: 'Shipper/發貨人:'+po.寄件人,
                  margin: [ 0, 5, 0, 5 ] ,
                },
                { text: 'Address/地址:'+po.寄件地址,
                  margin: [ 0, 5, 0, 5 ] ,
                },
                { text: 'TEL/電話:',
                  margin: [ 0, 5, 0, 5 ] ,
                },
                { text: 'Name/姓名:',
                  margin: [ 0, 5, 0, 5 ] ,
                },
                { text: 'Sign/簽章:',
                  margin: [ 0, 5, 0, 5 ] ,
                },
              ]
            },
            {
              width: '*',
              stack: [
                { text: '統一發票章:',
                  margin: [ 0, 5, 0, 5 ] ,
                },
                {table: {
                  heights: 100,
                  // headers are automatically repeated if the table spans over multiple pages
                  // you can declare how many rows should be treated as headers
                  headerRows: 1,
                  widths: [ 100 ],

                  body: [

                    [ '',  ],


                  ]
                }},


              ]
            },

          ],
            margin: [ 0, 20, 0, 5 ],
            pageBreak: 'after'

        };
          arr.push(Foot);

    };


    });


    pdfmake.fonts = {
     ch: {
     normal: 'kaiu.ttf',
     bold: 'kaiu.ttf',
     italics: 'kaiu.ttf',
     bolditalics: 'kaiu.ttf'
   }};






    var docDefinition = {
       content:[arr],

        styles: {
           header: {
             fontSize: 36,
             bold: true,

           },

         },

        defaultStyle: {
        font: 'ch'
        } ,

        // a string or { width: number, height: number }
        pageSize: 'A4',



        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 40, 50, 40, 30 ],
    };

    pdfmake.createPdf(docDefinition).download('Commerical Invoice.pdf');

  }

  //馬來西亞六合一需要修改
  handleMYLable(){
    pdfmake.vfs = pdffonts.pdfMake.vfs;
    //利用下面的function就可以把DataURL導出來


    let goodData=this.state['MYPO'];
    //goodData是一個object，不是Array


    var arr = [];
    Object.keys(goodData).forEach(function(key,idx) {
    var po=goodData[key];
    var value=0;
    var weight=0;
    var content='';

    Object.keys(po.內容物).forEach(function(key,idx) {
        var item=po.內容物[key];

        value= value+item.報關價值;
        weight=weight+item.公斤數;
        if(content){
          content=content+'.'+item.內容物;
        }else{
          content=item.內容物
        }


    });



      let Label={

        stack: [
        {
          alignment: 'justify',
          columns: [
             [
                {
                  text: 'MAWB:'+po.MAWB,
                  margin: [ 0, 5,0,0 ]
                },
                {
                  text: 'Shipper:'+po.寄件人,
                  margin: [ 0, 5,0,5 ] ,
                },
                {
                  text: 'Consignee:'+po.收件人,
                  margin: [ 0, 5,0,5 ] ,
                },
                {
                  text: 'TEL:'+po.連絡電話,
                  margin: [ 0, 5, 0, 5 ] ,
                },

            ],
            {
              text: po.region,
              style: 'head',
              margin: [ 30, 5, 0, 0 ] ,
            }
          ]
        },

        { text: 'Address:'+po.收件地址,
          margin: [ 0, 5, 0, 5 ] ,
        },
        { image:po.dataURL,
          width:100,
          height:50

        },
        { text: 'Declared Value:'+value,
          margin: [ 0, 5, 0, 5 ] ,
        },
        { text: 'Weight:'+weight,
          margin: [ 0, 5, 0, 5 ] ,
        },
        { text: 'Description:'+content,
          margin: [ 0, 5, 0, 0 ] ,
        },

      ],

      };
      arr.push(Label);
     });



     var temp=[];
     for (var i = 1; i <=arr.length; i=i+2) {

       if(i!==arr.length){
         var couple=[];
         couple.push(arr[i-1],arr[i]);
         temp.push(couple);
       }else{
         var couple=[];
         couple.push(arr[i-1],{});
         temp.push(couple);
       }

     };
     console.log(temp);

     var table=[];
     var group=[];
     for (var i = 0; i <temp.length; i++) {

       if((i+1)==temp.length){
         group.push(temp[i]);
         var onepage={
           table: {
             heights:275,
             // headers are automatically repeated if the table spans over multiple pages
             // you can declare how many rows should be treated as headers
             widths: [ 290, 290, ],
             body:group,
             alignment: 'center',

           },

         };

         table.push(onepage);
         console.log(table);

         group=[];

       }else if ((i%3)===2) {
         group.push(temp[i]);
         var onepage={
           table: {
             heights:275,
             // headers are automatically repeated if the table spans over multiple pages
             // you can declare how many rows should be treated as headers
             widths: [ 290, 290, ],
             body:group,
             alignment: 'center',


           },
          pageBreak: 'after'
         };

         table.push(onepage);
         console.log(table);
         group=[];


       }else{

        group.push(temp[i]);
        console.log(group);
      };

     };


    pdfmake.fonts = {
     ch: {
     normal: 'kaiu.ttf',
     bold: 'kaiu.ttf',
     italics: 'kaiu.ttf',
     bolditalics: 'kaiu.ttf'
   }};

    var docDefinition = {
        content:table,
        defaultStyle: {
        font: 'ch'
        } ,
        styles: {
      		head: {
      			fontSize: 66,
      			bold: true
      		},

      	},

        // a string or { width: number, height: number }
        pageSize: 'A4',

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 0, 0, 0, 0 ],
      };

      pdfmake.createPdf(docDefinition).download('label.pdf');

  }
  

  //新加坡標籤6合一完成
  handleSGLable(){
    pdfmake.vfs = pdffonts.pdfMake.vfs;
    //利用下面的function就可以把DataURL導出來


    let goodData=this.state['SGPO'];
    //goodData是一個object，不是Array


    var LabelArr = [];
    Object.keys(goodData).forEach((key)=> {
    var po=goodData[key];
    var value=0;
    var weight=0;
    var content='';

    Object.keys(po.內容物).forEach((key)=> {
        var item=po.內容物[key];

        value= value+item.報關價值;
        weight=weight+item.公斤數;
        if(content){
          content=content+'.'+item.內容物;
        }else{
          content=item.內容物
        }


    });



      let Label={

        stack: [
        {
          alignment: 'justify',
          columns: [
             [
                {
                  text: 'MAWB:',
                  margin: [ 0, 5,0,0 ]
                },
                {
                  text: 'PO:'+po.訂單單號,
                  margin: [ 0, 5,0,0 ]
                },
                {
                  text: 'Shipper:'+po.寄件人姓名,
                  margin: [ 0, 5,0,5 ] ,
                },
                {
                  text: 'Consignee:'+po.收件人姓名,
                  margin: [ 0, 5,0,5 ] ,
                },
                {
                  text: 'TEL:'+po.收件連絡電話,
                  margin: [ 0, 5, 0, 5 ] ,
                },

            ],
            {
              text: po.國別,
              style: 'head',
              margin: [ 30, 5, 0, 0 ] ,
            }
          ]
        },

        { text: 'Address:'+po.收件地址,
          margin: [ 0, 5, 0, 5 ] ,
        },
        { image:po.dataURL,
          width:100,
          height:50

        },
        { text: 'Declared Value:'+po['報關總值-新幣計價'],
          margin: [ 0, 5, 0, 5 ] ,
        },
        { text: 'Weight:'+po.總重量+'kgs',
          margin: [ 0, 5, 0, 5 ] ,
        },
        { text: 'Description:'+content,
          margin: [ 0, 5, 0, 0 ] ,
        },

      ],

      };
      LabelArr.push(Label);
     });



     var temp=[];
     for (var i = 1; i <=LabelArr.length; i=i+2) {

       if(i!==LabelArr.length){
         var couple=[];
         couple.push(LabelArr[i-1],LabelArr[i]);
         temp.push(couple);
       }else{
         var couple=[];
         couple.push(LabelArr[i-1],{});
         temp.push(couple);
       }

     };
     console.log(temp);

     var table=[];
     var group=[];
     for (var i = 0; i <temp.length; i++) {

       if((i+1)==temp.length){
         group.push(temp[i]);
         var onepage={
           table: {
             heights:275,
             // headers are automatically repeated if the table spans over multiple pages
             // you can declare how many rows should be treated as headers
             widths: [ 290, 290, ],
             body:group,
             alignment: 'center',

           },

         };

         table.push(onepage);
         console.log(table);

         group=[];

       }else if ((i%3)===2) {
         group.push(temp[i]);
         var onepage={
           table: {
             heights:275,
             // headers are automatically repeated if the table spans over multiple pages
             // you can declare how many rows should be treated as headers
             widths: [ 290, 290, ],
             body:group,
             alignment: 'center',


           },
          pageBreak: 'after'
         };

         table.push(onepage);
         console.log(table);
         group=[];


       }else{

        group.push(temp[i]);
        console.log(group);
      };

     };


    pdfmake.fonts = {
     ch: {
     normal: 'kaiu.ttf',
     bold: 'kaiu.ttf',
     italics: 'kaiu.ttf',
     bolditalics: 'kaiu.ttf'
   }};

    var docDefinition = {
        content:table,
        defaultStyle: {
        font: 'ch'
        } ,
        styles: {
      		head: {
      			fontSize: 66,
      			bold: true
      		},

      	},

        // a string or { width: number, height: number }
        pageSize: 'A4',

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 0, 0, 0, 0 ],
      };

      let labeltitle="跨境通標籤-SG"+this.state.todayTitle+'.pdf';


      pdfmake.createPdf(docDefinition).download(labeltitle);

  }


  //印尼六合一需要修改
  handleIDLable(){
    pdfmake.vfs = pdffonts.pdfMake.vfs;
    //利用下面的function就可以把DataURL導出來


    let goodData=this.state['PO'];
    //goodData是一個object，不是Array


    var arr = [];
    Object.keys(goodData).forEach(function(key,idx) {
    var po=goodData[key];
    var value=0;
    var weight=0;
    var content='';

    Object.keys(po.內容物).forEach(function(key,idx) {
        var item=po.內容物[key];

        value= value+item.報關價值;
        weight=weight+item.公斤數;
        if(content){
          content=content+'.'+item.內容物;
        }else{
          content=item.內容物
        }


    });



      let Label={

        stack: [
        {
          alignment: 'justify',
          columns: [
             [
                {
                  text: 'MAWB:'+po.MAWB,
                  margin: [ 0, 5,0,0 ]
                },
                {
                  text: 'Shipper:'+po.寄件人,
                  margin: [ 0, 5,0,5 ] ,
                },
                {
                  text: 'Consignee:'+po.收件人,
                  margin: [ 0, 5,0,5 ] ,
                },
                {
                  text: 'TEL:'+po.連絡電話,
                  margin: [ 0, 5, 0, 5 ] ,
                },

            ],
            {
              text: po.region,
              style: 'head',
              margin: [ 30, 5, 0, 0 ] ,
            }
          ]
        },

        { text: 'Address:'+po.收件地址,
          margin: [ 0, 5, 0, 5 ] ,
        },
        { image:po.dataURL,
          width:100,
          height:50

        },
        { text: 'Declared Value:'+value,
          margin: [ 0, 5, 0, 5 ] ,
        },
        { text: 'Weight:'+weight,
          margin: [ 0, 5, 0, 5 ] ,
        },
        { text: 'Description:'+content,
          margin: [ 0, 5, 0, 0 ] ,
        },

      ],

      };
      arr.push(Label);
     });



     var temp=[];
     for (var i = 1; i <=arr.length; i=i+2) {

       if(i!==arr.length){
         var couple=[];
         couple.push(arr[i-1],arr[i]);
         temp.push(couple);
       }else{
         var couple=[];
         couple.push(arr[i-1],{});
         temp.push(couple);
       }

     };
     console.log(temp);

     var table=[];
     var group=[];
     for (var i = 0; i <temp.length; i++) {

       if((i+1)==temp.length){
         group.push(temp[i]);
         var onepage={
           table: {
             heights:275,
             // headers are automatically repeated if the table spans over multiple pages
             // you can declare how many rows should be treated as headers
             widths: [ 290, 290, ],
             body:group,
             alignment: 'center',

           },

         };

         table.push(onepage);
         console.log(table);

         group=[];

       }else if ((i%3)===2) {
         group.push(temp[i]);
         var onepage={
           table: {
             heights:275,
             // headers are automatically repeated if the table spans over multiple pages
             // you can declare how many rows should be treated as headers
             widths: [ 290, 290, ],
             body:group,
             alignment: 'center',


           },
          pageBreak: 'after'
         };

         table.push(onepage);
         console.log(table);
         group=[];


       }else{

        group.push(temp[i]);
        console.log(group);
      };

     };


    pdfmake.fonts = {
     ch: {
     normal: 'kaiu.ttf',
     bold: 'kaiu.ttf',
     italics: 'kaiu.ttf',
     bolditalics: 'kaiu.ttf'
   }};

    var docDefinition = {
        content:table,
        defaultStyle: {
        font: 'ch'
        } ,
        styles: {
      		head: {
      			fontSize: 66,
      			bold: true
      		},

      	},

        // a string or { width: number, height: number }
        pageSize: 'A4',

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 0, 0, 0, 0 ],
      };

      pdfmake.createPdf(docDefinition).download('label.pdf');

  }

 

 

  KRImportExcel = file => {
    // 获取上传的文件对象
    const { files } = file.target;
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        // 以binary方式读取得到整份excel表格对象
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = []; // 存储获取到的数据
        
        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));

            break; // 如果只取第一张表，就取消注释这行
          }
        };

        function textToBase64Barcode(text){
              var canvas = document.createElement("canvas");
              JsBarcode(canvas, text, {fontSize: 36});
              return canvas.toDataURL("image/png");
        };
        
        let goodData=[];
        //console.log(this.state.cumulatedDeliveryNumber);
        let cumulatedDeliveryNumber=Number(this.state.cumulatedDeliveryNumber);

        data.forEach((element)=>{
              console.log(element);
              let number=element.訂單單號;

              if (typeof goodData[number] !=='undefined'){

                let temp=[];
                temp['內容物']=element.內容物;
                temp['件數']=element.內容物件數;
                temp['公斤數']='';
                temp['內容物單價']=element['內容物單價-美金計價'];
                temp['報關價值']=element.內容物報關價值;
                goodData[number].內容物.push(temp);


              }else{
                let date = new Date();
                let year=date.getFullYear();
                year=year.toString().slice(-2, );
                let month=date.getMonth()+1;
                if(month.toString().length===1){
                  month="0"+month;
                };
                let day=date.getDate();
                if(day.toString().length===1){
                  day="0"+day;
                };
                //多了一張訂單，加1
                cumulatedDeliveryNumber=cumulatedDeliveryNumber+1;
                
                //要補0之前，要先轉換成string
                let finalDeliveryNumber=cumulatedDeliveryNumber.toString();
                //開始進行補零

                finalDeliveryNumber=finalDeliveryNumber.padStart(5, '0')
                //console.log(finalDeliveryNumber);

                
                var deliveryNumber=this.state.customerNumber+year+month+day+finalDeliveryNumber;
                console.log(deliveryNumber);
                goodData[number]=[];

                goodData[number].快遞單號=deliveryNumber;
                goodData[number].訂單單號=element.訂單單號;
                
                goodData[number].日期=date.toDateString();
                goodData[number].寄件人姓名=element.寄件人姓名;
                goodData[number].寄件地址=element.寄件地址;
                goodData[number].dataURL=textToBase64Barcode(deliveryNumber);


                goodData[number].收件人姓名=element.收件人姓名;
                goodData[number].收件人身分證號=element.收件人身分證號;
                goodData[number].收件人郵遞區號=element.收件人郵遞區號;
                goodData[number].收件地址=element.收件地址;
                goodData[number].收件連絡電話=element.收件連絡電話;

                goodData[number].國別='KR';

                let temp=[];
                temp['內容物']=element.內容物;
                temp['件數']=element.內容物件數;
                temp['公斤數']='';
                temp['內容物單價']=element['內容物單價-美金計價'];
                temp['報關價值']=element.內容物報關價值;
                goodData[number].內容物=[];
                goodData[number].內容物.push(temp);

                goodData[number].總箱數=element.總箱數;
                goodData[number].總重量=element.總重量;
                goodData[number]['報關總值-美金計價']=element['報關總值-美金計價'];
              
              };
              console.log(goodData[number]);
              
              savePO(this.state.customerNumber,this.state.todayTitle,goodData[number]);

      });
        
        this.setState({KRPO:goodData, KRUpload:'success'});
        
        saveDeliveryNumber(this.state.customerNumber,this.state.todayTitle,cumulatedDeliveryNumber);
        tellAdmin(this.state.customerNumber,'KR',this.state.todayTitle);


      } catch (e) {
        // 这里可以抛出文件类型错误不正确的相关提示
        console.log('文件类型不正确');
        return;
      }
    };
    // 以Binary方式打开文件
    fileReader.readAsBinaryString(files[0]);
  }

  MYImportExcel = file => {
    // 获取上传的文件对象
    const { files } = file.target;
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        // 以binary方式读取得到整份excel表格对象
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = []; // 存储获取到的数据
        let goodData=[];
        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));

            break; // 如果只取第一张表，就取消注释这行
          }
        };

        function textToBase64Barcode(text){
              var canvas = document.createElement("canvas");
              JsBarcode(canvas, text, {fontSize: 36});
              return canvas.toDataURL("image/png");
        };

        let cumulatedDeliveryNumber=Number(this.state.cumulatedDeliveryNumber);

        data.forEach((element)=>{
              let number=element.訂單單號;
              console.log(element);
              goodData[number]=[];
              let date = new Date();
              let year=date.getFullYear();
              year=year.toString().slice(-2, );
              let month=date.getMonth()+1;
              if(month.toString().length===1){
                month="0"+month;
              };
              let day=date.getDate();
              if(day.toString().length===1){
                day="0"+day;
              };
              //多了一張訂單，加1
              cumulatedDeliveryNumber=cumulatedDeliveryNumber+1;
              
              //要補0之前，要先轉換成string
              let finalDeliveryNumber=cumulatedDeliveryNumber.toString();
              //開始進行補零

              finalDeliveryNumber=finalDeliveryNumber.padStart(5, '0')
              //console.log(finalDeliveryNumber);

              //this.setState({'finalDeliveryNumber':finalDeliveryNumber});
              //console.log(this.state.finalDeliveryNumber);
              var deliveryNumber=this.state.customerNumber+year+month+day+finalDeliveryNumber;
              //console.log(deliveryNumber);
              goodData[number]=[];

              goodData[number].快遞單號=deliveryNumber;
              goodData[number].訂單單號=element.訂單單號;
              
              goodData[number].日期=date.toDateString();
              goodData[number].dataURL=textToBase64Barcode(deliveryNumber);


              goodData[number].收件人姓名=element.收件人姓名;
              goodData[number].收件人郵遞區號=element.收件人郵遞區號;
              goodData[number].收件地址=element.收件地址;
              goodData[number].收件連絡電話=element.收件連絡電話;

              goodData[number].國別='MY';
              
              goodData[number].總箱數=element.總箱數;
              
              savePO(this.state.customerNumber,this.state.todayTitle,goodData[number]);

      });

        this.setState({MYPO: goodData, MYUpload:'success'});
        saveDeliveryNumber(this.state.customerNumber,this.state.todayTitle,cumulatedDeliveryNumber);
        tellAdmin(this.state.customerNumber,'MY',this.state.todayTitle);


      } catch (e) {
        // 这里可以抛出文件类型错误不正确的相关提示
        console.log('文件类型不正确');
        return;
      }
    };
    // 以Binary方式打开文件
    fileReader.readAsBinaryString(files[0]);
  }

  SGImportExcel = file => {
    // 获取上传的文件对象
    const { files } = file.target;
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        // 以binary方式读取得到整份excel表格对象
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = []; // 存储获取到的数据
       
        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            // 我只想讓系統讀第一頁，因為第二頁我要放範例
            break; // 如果只取第一张表，就取消注释这行
          }
        };

        function textToBase64Barcode(text){
              var canvas = document.createElement("canvas");
              JsBarcode(canvas, text, {fontSize: 36});
              return canvas.toDataURL("image/png");
        };

        let goodData=[];
        let cumulatedDeliveryNumber=Number(this.state.cumulatedDeliveryNumber);

        data.forEach((element)=> {
              console.log(element);
              let number=element.訂單單號;
              if (typeof goodData[number] !=='undefined'){

                let temp=[];
                temp['內容物']=element.內容物;
                temp['件數']=element.內容物件數;
                temp['公斤數']='';
                temp['內容物單價']='';
                temp['報關價值']='';
                goodData[number].內容物.push(temp);


              }else{

                let date = new Date();
                let year=date.getFullYear();
                year=year.toString().slice(-2, );
                let month=date.getMonth()+1;
                if(month.toString().length===1){
                  month="0"+month;
                };
                let day=date.getDate();
                if(day.toString().length===1){
                  day="0"+day;
                };
                //多了一張訂單，加1
                cumulatedDeliveryNumber=cumulatedDeliveryNumber+1;
                
                //要補0之前，要先轉換成string
                let finalDeliveryNumber=cumulatedDeliveryNumber.toString();
                //開始進行補零

                finalDeliveryNumber=finalDeliveryNumber.padStart(5, '0')
                console.log(finalDeliveryNumber);

                
                var deliveryNumber=this.state.customerNumber+year+month+day+finalDeliveryNumber;
                console.log(deliveryNumber);
                goodData[number]=[];

                goodData[number].快遞單號=deliveryNumber;
                goodData[number].訂單單號=element.訂單單號;
                
                goodData[number].日期=date.toDateString();
                goodData[number].寄件人姓名='';
                goodData[number].寄件地址='';
                goodData[number].dataURL=textToBase64Barcode(deliveryNumber);


                goodData[number].收件人姓名=element.收件人姓名;
                goodData[number].收件人郵遞區號=element.收件人郵遞區號;
                goodData[number].收件地址=element.收件地址;
                goodData[number].收件連絡電話=element.收件連絡電話;

                goodData[number].國別='SG';

                let temp=[];
                temp['內容物']=element.內容物;
                temp['件數']=element.內容物件數;
                temp['公斤數']='';
                temp['內容物單價']='';
                temp['報關價值']='';
                goodData[number].內容物=[];
                goodData[number].內容物.push(temp);

                goodData[number].總箱數=element.總箱數;
                goodData[number].總重量=element.總重量;
                goodData[number]['報關總值-新幣計價']=element['報關總值-新幣計價'];
              };
              //console.log(goodData[number]);
              savePO(this.state.customerNumber,this.state.todayTitle,goodData[number]);
              //console.log('o.k.');
      });

              this.setState({SGPO:goodData, SGUpload:'success'});
                
              saveDeliveryNumber(this.state.customerNumber,this.state.todayTitle,cumulatedDeliveryNumber);
              tellAdmin(this.state.customerNumber,'SG',this.state.todayTitle);


      } catch (e) {
        // 这里可以抛出文件类型错误不正确的相关提示
        console.log('文件类型不正确');
        return;
      }
    };
    // 以Binary方式打开文件
    fileReader.readAsBinaryString(files[0]);
  }

  IDImportExcel = file => {
    // 获取上传的文件对象
    const { files } = file.target;
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        // 以binary方式读取得到整份excel表格对象
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = []; // 存储获取到的数据
        
        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));

            break; // 如果只取第一张表，就取消注释这行
          }
        };

        function textToBase64Barcode(text){
              var canvas = document.createElement("canvas");
              JsBarcode(canvas, text, {fontSize: 36});
              return canvas.toDataURL("image/png");
        };
        
        let goodData=[];
        //console.log(this.state.cumulatedDeliveryNumber);
        let cumulatedDeliveryNumber=Number(this.state.cumulatedDeliveryNumber);

        data.forEach((element)=>{
              console.log(element);
              let number=element.訂單單號;

              if (typeof goodData[number] !=='undefined'){

                let temp=[];
                temp['內容物']=element.內容物;
                temp['件數']=element.內容物件數;
                temp['公斤數']='';
                temp['內容物單價']=element['內容物單價-美金計價'];
                temp['報關價值']=element.內容物報關價值;
                goodData[number].內容物.push(temp);


              }else{
                let date = new Date();
                let year=date.getFullYear();
                year=year.toString().slice(-2, );
                let month=date.getMonth()+1;
                if(month.toString().length===1){
                  month="0"+month;
                };
                let day=date.getDate();
                if(day.toString().length===1){
                  day="0"+day;
                };
                //多了一張訂單，加1
                cumulatedDeliveryNumber=cumulatedDeliveryNumber+1;
                
                //要補0之前，要先轉換成string
                let finalDeliveryNumber=cumulatedDeliveryNumber.toString();
                //開始進行補零

                finalDeliveryNumber=finalDeliveryNumber.padStart(5, '0')
                //console.log(finalDeliveryNumber);

                
                var deliveryNumber=this.state.customerNumber+year+month+day+finalDeliveryNumber;
                console.log(deliveryNumber);
                goodData[number]=[];

                goodData[number].快遞單號=deliveryNumber;
                goodData[number].訂單單號=element.訂單單號;
                
                goodData[number].日期=date.toDateString();
                goodData[number].寄件人姓名=element.寄件人姓名;
                goodData[number].寄件地址=element.寄件地址;
                goodData[number].dataURL=textToBase64Barcode(deliveryNumber);


                goodData[number].收件人姓名=element.收件人姓名;
                goodData[number].收件人身分證號=element.收件人身分證號;
                goodData[number].收件人郵遞區號=element.收件人郵遞區號;
                goodData[number].收件地址=element.收件地址;
                goodData[number].收件連絡電話=element.收件連絡電話;

                goodData[number].國別='ID';

                let temp=[];
                temp['內容物']=element.內容物;
                temp['件數']=element.內容物件數;
                temp['公斤數']='';
                temp['內容物單價']=element['內容物單價-美金計價'];
                temp['報關價值']=element.內容物報關價值;
                goodData[number].內容物=[];
                goodData[number].內容物.push(temp);

                goodData[number].總箱數=element.總箱數;
                goodData[number].總重量=element.總重量;
                goodData[number]['報關總值-美金計價']=element['報關總值-美金計價'];
              
              };
              console.log(goodData[number]);
              
              savePO(this.state.customerNumber,this.state.todayTitle,goodData[number]);

      });
        
        this.setState({IDPO:goodData, IDUpload:'success'});
        
        saveDeliveryNumber(this.state.customerNumber,this.state.todayTitle,cumulatedDeliveryNumber);
        tellAdmin(this.state.customerNumber,'ID',this.state.todayTitle);


      } catch (e) {
        // 这里可以抛出文件类型错误不正确的相关提示
        console.log('文件类型不正确');
        return;
      }
    };
    // 以Binary方式打开文件
    fileReader.readAsBinaryString(files[0]);
  }



  KRDownloadForm(){
    var storageRef = firebaseStorage.ref("KROrderForm.xlsx");
    storageRef.getDownloadURL().then(function(url) {
      console.log(url);
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function(event) {
        var blob = xhr.response;
        saveAs(blob,'韓國OrderForm.xlsx');
      };
      xhr.open('GET', url );
      xhr.send();
      
    }).catch(function(error) {
      console.log(error);
    });
  }

  SGDownloadForm(){
    var storageRef = firebaseStorage.ref("SGOrderForm.xlsx");
    storageRef.getDownloadURL().then(function(url) {
      console.log(url);
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function(event) {
        var blob = xhr.response;
        saveAs(blob,'新加坡OrderForm.xlsx');
      };
      xhr.open('GET', url );
      xhr.send();
      
    }).catch(function(error) {
      console.log(error);
    });
  }

  MYDownloadForm(){
    var storageRef = firebaseStorage.ref("MYOrderForm.xlsx");
    storageRef.getDownloadURL().then(function(url) {
      console.log(url);
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function(event) {
        var blob = xhr.response;
        saveAs(blob,'馬來西亞OrderForm.xlsx');
      };
      xhr.open('GET', url );
      xhr.send();
      
    }).catch(function(error) {
      console.log(error);
    });
  }

  IDDownloadForm(){
    var storageRef = firebaseStorage.ref("IDOrderForm.xlsx");
    storageRef.getDownloadURL().then(function(url) {
      console.log(url);
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function(event) {
        var blob = xhr.response;
        saveAs(blob,'印尼OrderForm.xlsx');
      };
      xhr.open('GET', url );
      xhr.send();
      
    }).catch(function(error) {
      console.log(error);
    });
  }

  

  render() {
    return (
        <Table celled padded textAlign='center' style={{ padding: '5em 5em','background-color': 'rgb(244, 173, 66)',fontSize:20 }}>
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell>寄送國別</Table.HeaderCell>
                <Table.HeaderCell>下載訂單表格</Table.HeaderCell>
                <Table.HeaderCell>上傳資料</Table.HeaderCell>
                <Table.HeaderCell>下載標籤</Table.HeaderCell>
                
            </Table.Row>
            </Table.Header>
        
            <Table.Body>
            <Table.Row style={{'background-color': 'rgb(208, 8, 33)' }}>
                <Table.Cell><h1>韓國</h1></Table.Cell>
                <Table.Cell>
                    <Button size='large'  color='orange' onClick={this.KRDownloadForm}>下載韓國訂單表格</Button>
                </Table.Cell>
                <Table.Cell>
                    <h1>請先上傳韓國訂單寄送資料</h1><br/>
                    <Input type='file'  accept='.xlsx, .xls' onChange={this.KRImportExcel} />
                    {this.state.KRUpload?<h1>上傳成功</h1>:''}
                </Table.Cell>
                <Table.Cell></Table.Cell>
            </Table.Row>

            <Table.Row style={{'background-color': 'rgb(174, 198, 45)'  }}>
                <Table.Cell><h1>新加坡</h1></Table.Cell>
                <Table.Cell>
                    <Button size='large'  color='orange' onClick={this.SGDownloadForm}>下載新加坡訂單表格</Button>
                </Table.Cell>
                <Table.Cell>
                    <h1>請先上傳新加坡訂單寄送資料</h1><br/>
                    <Input type='file'  accept='.xlsx, .xls' onChange={this.SGImportExcel} />
                    {this.state.SGUpload?<h1>上傳成功</h1>:''}
                </Table.Cell>
                <Table.Cell>
                    <Button size='large'  color='orange' onClick={this.handleSGLable}>請按我一下!<br/>把標籤印出來!!</Button>
                </Table.Cell>
            </Table.Row>
            <Table.Row style={{'background-color': 'rgb(45, 126, 200)' }}>
                <Table.Cell><h1>馬來西亞</h1></Table.Cell>
                <Table.Cell>
                       <Button size='large'  color='orange' onClick={this.MYDownloadForm}>下載馬來西亞訂單表格</Button>
                </Table.Cell>
                <Table.Cell>
                    <h1>請先上傳馬來西亞訂單寄送資料</h1><br/>
                    <Input type='file'  accept='.xlsx, .xls' onChange={this.MYImportExcel} />
                    {this.state.MYUpload?<h1>上傳成功</h1>:''}
                </Table.Cell>
                <Table.Cell>
                    
                </Table.Cell>
            </Table.Row>
            
            <Table.Row style={{'background-color': 'rgb(214, 40, 139)' }}>
                <Table.Cell><h1>印尼</h1></Table.Cell>
                <Table.Cell>
                     <Button size='large'  color='orange' onClick={this.IDDownloadForm}>下載印尼訂單表格</Button>
                </Table.Cell>
                <Table.Cell>
                    <h1>請先上傳印尼訂單寄送資料</h1><br/>
                    <Input type='file'  accept='.xlsx, .xls' onChange={this.IDImportExcel} />
                    {this.state.IDUpload?<h1>上傳成功</h1>:''}
                </Table.Cell>
                <Table.Cell>
                    
                </Table.Cell>
            </Table.Row>
            </Table.Body>
        </Table>
    );
  }
}

export default App;
