//這是酷天
import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import {todayTitle,savePO,tellAdmin} from './helper/SaveToDatabase';
import { LineClearLogoImg,textToBase64Barcode} from './helper/Helper';
import { ref, firebaseAuth,firebase } from '../firebase/firebaseConfig';
import pdfmake from 'pdfmake/build/pdfmake'
import pdffonts from 'pdfmake/build/vfs_fonts';
import saveAs from 'file-saver';
import { Button, Input, Header, Table, Modal, Loader, Label, Form, Grid, Segment} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      //這個每個客人都要改
      'customerNumber':'80603',
      // 'customerNumber':'test',
      'SGPO':[],
      'finalDeliveryNumber':'',
      'cumulatedDeliveryNumber':'',
      'todayTitle':'',
      'deliveryNation':'MY',

    };
    this.UniversalImportExcel=this.UniversalImportExcel.bind(this);
    this.handleUniversalLablePrint=this.handleUniversalLablePrint.bind(this);
    this.deleteRecord=this.deleteRecord.bind(this);
    this.handleInputChange=this.handleInputChange.bind(this)
  }

  componentDidMount (){
    //var userid = firebaseAuth.currentUser.uid;
    let today=todayTitle()[0];
    let year=todayTitle()[1];
    let month=todayTitle()[2];
    let day=todayTitle()[3];
    this.setState({
      'todayTitle':today,
      'year':year,
      'month':month,
      'day':day
    })
    var userid = firebaseAuth.currentUser.uid;
    //console.log(userid)
    this.setState({
      'userid': userid,
    })

   
  }

  //這是所有欄位普用型的function
  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    //console.log(Object.keys(this.state.historyPONumberList))
  }




  handleUniversalLablePrint(){
    
    let goodData=this.state['MYPO'];
    console.log()
    if(goodData !== ''){
        //console.log(goodData)
        this.setState({
          loading: true,
        })

        const infoUpload = {
          "Shipment": []
        }
        
        //上傳資料的時候，我要把順序記起來，後面要讀回傳資料可以用
        var keyOrder=[]
        Object.keys(goodData).forEach((key) => {
        
              keyOrder.push(key)
              
              let order = goodData[key]
              
              var weight = (Number(order['總重量-公斤']) / Number(order['總箱數'])).toFixed(1)
              let wayBill = [];
              for (let j = 0; j < order['總箱數']; j++) {
                wayBill.push(
                  {
                    "Weight": weight,
                    "VolumeWidth": "1",
                    "VolumeHeight": "1",
                    "VolumeLength": "1"

                  }
                )
              }

              let serviceType=''
              // 下面我們要先判斷郵遞區號是不是西馬的？ 如果是還要分成超過2公斤跟2公斤以下。
              if (Number(this.state.zipCode)<87000){
                if(this.state.weight<2){
                  serviceType="ST00000108"
                }else{
                  serviceType="ST00000087"
                }
              }else{
                // 東馬一律是087
                serviceType="ST00000087"
              }


              let info = {
                "ShipmentServiceType": "Standard Delivery",
                "ConfigServiceCode":serviceType,
                "SenderName": "MR TIEN",
                "RecipientName": order['收件人姓名'],
                "ShipmentAddressFrom": {
                  "CompanyName": "FREEGO TRADING AND LOGISTICS",
                  "UnitNumber": "null",
                  "Address": "No 26,Jalan KU5, Pusat Komersial Universiti,Seremban 3,70300,Seremban,Negeri Sembilan,Malaysia",
                  "Address2": "Seremban 3",
                  "PostalCode": "70300",
                  "City": "",
                  "State": "",
                  "Email": "",
                  "PhoneNumber": "60126368616",
                  "ICNumber": ""
                },
                "ShipmentAddressTo": {
                  "CompanyName": "",
                  "UnitNumber": "null",
                  "Address": order['收件地址'],
                  "Address2": "",
                  "PostalCode": order['收件人郵遞區號'],
                  "City": "",
                  "State": "",
                  "Email": "",
                  "PhoneNumber": order['收件連絡電話'],
                  "ICNumber": ""
                },
                "RecipientPhone": order['收件連絡電話'],
                "ParcelType": "Package",
                "ShipmentRef": "",
                "ShipmentDescription": null,
                "ShipmentType": "Lodge In",
                "PickUpAddressType":"Default",   //這是為了讓計價可以正確，所以最新要加入的
                "CODAmount": "",
                "WayBill": wayBill,
                "DONumber": ""
              }

              infoUpload['Shipment'].push(info)
          

        })

    

        //測試切換
        //var bookingLineClear = firebase.functions().httpsCallable('bookingLineClearTest');
        var bookingLineClear = firebase.functions().httpsCallable('bookingLineClear');



        bookingLineClear({ data: infoUpload })
          .then((result) => {

            var LabelArr = []
            var responseInfo = result['data']
            //目前的設計是會讓整包data傳回來，那要自行判斷有沒有成功，有就可以印標籤
            if (responseInfo['Status']===true) {
              pdfmake.vfs = pdffonts.pdfMake.vfs;
              let logo = LineClearLogoImg();
              let labelInfo = responseInfo['ResponseData']
              //console.log(labelInfo)
              //依照上傳的順序，把每一張訂單資料讀出來
              for (let i = 0; i < keyOrder.length; i++) {
                let key = keyOrder[i]
                let order = goodData[key]
                var weight = (Number(order['總重量-公斤']) / Number(order['總箱數'])).toFixed(1)
                let barCodeInfo = labelInfo[i]['WayBill']
                //依照箱數決定印製標籤張數
                for (let j = 0; j < order['總箱數']; j++) {
                  if (j === order['總箱數']-1 && i===keyOrder.length-1){
                    let label = [
                      {
                        table: {
                          widths: ['auto', '*', '*', '*'],
                          //下面是要做一開始的logo、barcode、和國碼標
                          body: [
                            [
                              {
                                // you'll most often use dataURI images on the browser side
                                // if no width/height/fit is provided, the original size will be used
                                image: logo,
                                width: 70,
                                height: 10,
                                alignment: 'left',
                                colSpan: 4,
                                border: [false, false, false, true],
                              },
                              '',
                              '',
                              '',


                            ],
                            [

                              {

                                table: {

                                  body: [
                                    [{

                                      qr: barCodeInfo[j],
                                      fit: '80',
                                      alignment: 'center',
                                      margin: [0, 10, 0, 5],
                                      colSpan: 3,
                                      border: [false, false, false, false],
                                    },
                                      '',
                                      ''],
                                    [
                                      {
                                        text: '',
                                        border: [false, false, false, false]
                                      },
                                      {
                                        text: '',
                                        border: [false, false, false, false]
                                      },
                                      {
                                        text: '',
                                        border: [false, false, false, false]
                                      }
                                    ],
                                    [
                                      {
                                        text: 'PCS',
                                        fontSize: 6,
                                        alignment: 'left',
                                        bold: true,
                                        border: [true, true, false, false],
                                      },
                                      {
                                        text: '',

                                        border: [false, true, false, false],
                                      },
                                      {
                                        text: 'M',
                                        fontSize: 6,
                                        alignment: 'right',
                                        bold: true,
                                        border: [false, true, true, false],
                                      },


                                    ],
                                    [{
                                      text: (j + 1) + '/' + order['總箱數'],
                                      colSpan: 3,
                                      fontSize: 18,
                                      alignment: 'center',
                                      bold: true,
                                      border: [true, false, true, true],
                                    },
                                      '',
                                      ''
                                    ],

                                  ],

                                },
                                border: [false, false, false, false],
                                layout: {
                                  hLineColor: '#999999',
                                  vLineColor: '#999999',


                                },



                              },
                              {

                                table: {

                                  body: [
                                    [{
                                      image: textToBase64Barcode(barCodeInfo[j]),
                                      colSpan: 3,
                                      height: 50,
                                      width: 170,
                                      alignment: 'center',
                                      border: [false, false, false, false],
                                      margin: [ 0, 0, 0, -10 ]
                                    },
                                      '',
                                      ''],
                                    //空白格
                                    [{
                                      text: barCodeInfo[j],
                                      border: [false, false, false, false],
                                      colSpan: 3,
                                      alignment: 'center',
                                      fontSize: 10,
                                    }, '', ''],
                                    //第一排
                                    [{
                                      text: 'Origin Agent',
                                      alignment: 'left',
                                      bold: true,
                                      fontSize: 6,
                                      border: [true, true, true, false],
                                    },
                                    {
                                      text: 'Origin Depot',
                                      alignment: 'left',
                                      bold: true,
                                      fontSize: 6,
                                      border: [true, true, true, false],
                                    },
                                    {
                                      text: 'Origin Hub',
                                      alignment: 'left',
                                      bold: true,
                                      fontSize: 6,
                                      border: [true, true, true, false],
                                    }],
                                    //第二排(資料排)
                                    [
                                      {
                                        text: '',
                                        alignment: 'center',
                                        bold: true,
                                        fontSize: 15,
                                        border: [true, false, true, false],
                                      },
                                      {
                                        text: labelInfo[i]['PickupBranchCode'],
                                        alignment: 'center',
                                        bold: true,
                                        fontSize: 15,
                                        border: [true, false, true, false],
                                      },
                                      {
                                        text: labelInfo[i]['PickupHubCode'],
                                        alignment: 'center',
                                        bold: true,
                                        fontSize: 15,
                                        border: [true, false, true, false],
                                      }
                                    ],
                                    //第三排
                                    [
                                      {
                                        text: 'Dest. Hub',
                                        alignment: 'left',
                                        fillColor: '#000000',
                                        color: '#ffffff',
                                        bold: true,
                                        fontSize: 6,
                                        border: [true, true, true, false],
                                      },
                                      {
                                        text: 'Dest. Depot',
                                        alignment: 'left',
                                        fillColor: '#000000',
                                        color: '#ffffff',
                                        bold: true,
                                        fontSize: 6,
                                        border: [true, true, true, false],
                                      },
                                      {
                                        text: 'Dest. Agent',
                                        alignment: 'left',
                                        fillColor: '#000000',
                                        color: '#ffffff',
                                        bold: true,
                                        fontSize: 6,
                                        border: [true, true, true, false],
                                      }
                                    ],
                                    //第四排(資料排)
                                    [
                                      {
                                        text: labelInfo[i]['DeliveryHubCode'],
                                        alignment: 'center',
                                        fillColor: '#000000',
                                        color: '#ffffff',

                                        bold: true,
                                        fontSize: 15,
                                        border: [true, false, true, true],
                                      },
                                      {
                                        text: labelInfo[i]['DeliveryBranchCode'],
                                        alignment: 'center',
                                        fillColor: '#000000',
                                        color: '#ffffff',
                                        bold: true,
                                        fontSize: 15,
                                        border: [true, false, true, true],
                                      },
                                      {
                                        text: '',
                                        alignment: 'center',
                                        fillColor: '#000000',
                                        color: '#ffffff',
                                        bold: true,
                                        fontSize: 15,
                                        border: [true, false, true, true],
                                      }
                                    ]

                                  ]
                                },
                                border: [false, false, false, false],
                                layout: {
                                  hLineColor: '#999999',
                                  vLineColor: '#999999',


                                },
                                colSpan: 3,


                              },

                              '',
                              '',

                            ],
                            [
                              {
                                text: 'From',
                                alignment: 'left',
                                bold: true,
                                fontSize: 12,
                                colSpan: 2,
                                border: [true, true, false, false],
                              },
                              '',
                              {
                                text: 'Account No.: N6900038',
                                alignment: 'left',
                                bold: true,
                                fontSize: 8,
                                colSpan: 2,
                                border: [false, true, true, false],
                              },
                              ''

                            ],
                            [{
                              text: 'FREEGO TRADING AND LOGISTICS',
                              alignment: 'left',
                              bold: true,
                              fontSize: 8,
                              colSpan: 4,
                              border: [true, false, true, false],
                            },
                              '',
                              '',
                              ''

                            ],
                            [{
                              text: 'No 26,Jalan KU5, Pusat Komersial Universiti,Seremban 3,70300,Seremban,Negeri Sembilan,Malaysia',
                              alignment: 'left',
                              bold: true,
                              fontSize: 8,
                              width: 220,
                              colSpan: 4,
                              border: [true, false, true, false],
                            },
                              '',
                              '',
                              ''

                            ],
                            [{
                              text: 'MR TIEN',
                              alignment: 'left',
                              bold: true,
                              fontSize: 8,
                              colSpan: 4,
                              border: [true, false, true, false],
                            },
                              '',
                              '',
                              ''

                            ],
                            [{
                              text: '60126368616',
                              alignment: 'left',
                              fontSize: 8,
                              colSpan: 2,
                              border: [true, false, false, true],
                            },
                              '',
                            {
                              text: 'Cust. Ref. No.:',
                              colSpan: 2,
                              fontSize: 8,
                              bold: true,
                              border: [false, false, true, true],
                            },
                              ''

                            ],
                            [
                              {
                                text: 'To',
                                alignment: 'left',
                                bold: true,
                                fontSize: 12,
                                colSpan: 2,
                                border: [true, true, false, false],
                              },
                              '',
                              {
                                text: '',
                                colSpan: 2,
                                border: [false, true, true, false],
                              },
                              ''

                            ],
                            [{
                              text: order['收件地址'],
                              alignment: 'left',
                              width: 220,
                              fontSize: 8,
                              colSpan: 4,
                              border: [true, false, true, false],
                            },
                              '',
                              '',
                              ''

                            ],
                            [{
                              text: order['收件人姓名'] + '  ' + order['訂單單號'],
                              alignment: 'left',
                              fontSize: 8,
                              colSpan: 4,
                              border: [true, false, true, false],
                            },
                              '',
                              '',
                              ''

                            ],
                            [{
                              text: order['收件連絡電話'],
                              alignment: 'left',
                              fontSize: 8,
                              colSpan: 4,
                              border: [true, false, true, true],
                            },
                              '',
                              '',
                              ''

                            ],
                            [{
                              text: 'Weight(kg):' + weight + '   |Pcs:' + order['總箱數'] + '   |Type:Package',
                              alignment: 'left',
                              bold: true,
                              fontSize: 6,
                              colSpan: 2,
                              border: [true, true, false, true],
                            },
                              '',
                            {
                              text: 'Date : ' + this.state.day + '.' + this.state.month + '.' + this.state.year,
                              alignment: 'right',
                              colSpan: 2,
                              fontSize: 6,
                              bold: true,
                              border: [false, true, true, true],
                            },
                              '',


                            ],
                            [
                              {
                                text: '',
                                colSpan: 4,
                                height: 3,
                                border: [false, true, false, true]
                              },
                              '',
                              '',
                              ''
                            ],
                            [
                              {
                                text: 'Acknowledgement by Recipient',
                                fontSize: 8,
                                colSpan: 4,
                                height: 3,
                                bold: true,
                                border: [true, true, true, true]
                              },
                              '',
                              '',
                              ''
                            ],
                            [
                              {
                                text: '',
                                colSpan: 2,
                                border: [true, true, false, false]
                              },
                              '',
                              {
                                text: 'Name :',
                                fontSize: 8,
                                colSpan: 2,
                                border: [false, true, true, false]
                              },
                              ''
                            ],
                            [
                              {
                                text: 'Signature &  Company Stamp',
                                alignment: 'center',
                                color: '#b3b3b3',
                                fontSize: 8,
                                colSpan: 2,
                                border: [true, false, false, false]
                              },
                              '',
                              {
                                text: 'Date & Time :',
                                fontSize: 8,
                                colSpan: 2,
                                border: [false, false, true, false]
                              },
                              ''
                            ],
                            [
                              {
                                text: '',
                                colSpan: 2,
                                border: [true, false, false, true]
                              },
                              '',
                              {
                                text: 'IC / Passport :',
                                fontSize: 8,
                                colSpan: 2,
                                border: [false, false, true, true]
                              },
                              ''
                            ],


                            [{
                              text: '** For Terms of Carriage and Tracing, kindly log-in to www.lineclearexpress.com.',
                              fontSize: 6,
                              margin: [0, 1, 0, 0],
                              colSpan: 4,
                              bold: true,
                              border: [false, false, false, false],
                            },
                              '',
                              '',
                              ''
                            ]
                          ],

                        },

                      }
                    ]
                    LabelArr.push(label);

                  }else{
                    let label = [
                      {
                        table: {
                          widths: ['auto', '*', '*', '*'],
                          //下面是要做一開始的logo、barcode、和國碼標
                          body: [
                            [
                              {
                                // you'll most often use dataURI images on the browser side
                                // if no width/height/fit is provided, the original size will be used
                                image: logo,
                                width: 70,
                                height: 10,
                                alignment: 'left',
                                colSpan: 4,
                                border: [false, false, false, true],
                              },
                              '',
                              '',
                              '',


                            ],
                            [

                              {

                                table: {

                                  body: [
                                    [{

                                      qr: barCodeInfo[j],
                                      fit: '80',
                                      alignment: 'center',
                                      margin: [0, 10, 0, 5],
                                      colSpan: 3,
                                      border: [false, false, false, false],
                                    },
                                      '',
                                      ''],
                                    [
                                      {
                                        text: '',
                                        border: [false, false, false, false]
                                      },
                                      {
                                        text: '',
                                        border: [false, false, false, false]
                                      },
                                      {
                                        text: '',
                                        border: [false, false, false, false]
                                      }
                                    ],
                                    [
                                      {
                                        text: 'PCS',
                                        fontSize: 6,
                                        alignment: 'left',
                                        bold: true,
                                        border: [true, true, false, false],
                                      },
                                      {
                                        text: '',

                                        border: [false, true, false, false],
                                      },
                                      {
                                        text: 'M',
                                        fontSize: 6,
                                        alignment: 'right',
                                        bold: true,
                                        border: [false, true, true, false],
                                      },


                                    ],
                                    [{
                                      text: (j + 1) + '/' + order['總箱數'],
                                      colSpan: 3,
                                      fontSize: 18,
                                      alignment: 'center',
                                      bold: true,
                                      border: [true, false, true, true],
                                    },
                                      '',
                                      ''
                                    ],

                                  ],

                                },
                                border: [false, false, false, false],
                                layout: {
                                  hLineColor: '#999999',
                                  vLineColor: '#999999',


                                },



                              },
                              {

                                table: {

                                  body: [
                                    [{
                                      image: textToBase64Barcode(barCodeInfo[j]),
                                      colSpan: 3,
                                      height: 50,
                                      width: 170,
                                      alignment: 'center',
                                      border: [false, false, false, false],
                                      margin: [ 0, 0, 0, -10 ]
                                    },
                                      '',
                                      ''],
                                    //空白格
                                    [{
                                      text: barCodeInfo[j],
                                      border: [false, false, false, false],
                                      colSpan: 3,
                                      alignment: 'center',
                                      fontSize: 10,
                                    }, '', ''],
                                    //第一排
                                    [{
                                      text: 'Origin Agent',
                                      alignment: 'left',
                                      bold: true,
                                      fontSize: 6,
                                      border: [true, true, true, false],
                                    },
                                    {
                                      text: 'Origin Depot',
                                      alignment: 'left',
                                      bold: true,
                                      fontSize: 6,
                                      border: [true, true, true, false],
                                    },
                                    {
                                      text: 'Origin Hub',
                                      alignment: 'left',
                                      bold: true,
                                      fontSize: 6,
                                      border: [true, true, true, false],
                                    }],
                                    //第二排(資料排)
                                    [
                                      {
                                        text: '',
                                        alignment: 'center',
                                        bold: true,
                                        fontSize: 15,
                                        border: [true, false, true, false],
                                      },
                                      {
                                        text: labelInfo[i]['PickupBranchCode'],
                                        alignment: 'center',
                                        bold: true,
                                        fontSize: 15,
                                        border: [true, false, true, false],
                                      },
                                      {
                                        text: labelInfo[i]['PickupHubCode'],
                                        alignment: 'center',
                                        bold: true,
                                        fontSize: 15,
                                        border: [true, false, true, false],
                                      }
                                    ],
                                    //第三排
                                    [
                                      {
                                        text: 'Dest. Hub',
                                        alignment: 'left',
                                        fillColor: '#000000',
                                        color: '#ffffff',
                                        bold: true,
                                        fontSize: 6,
                                        border: [true, true, true, false],
                                      },
                                      {
                                        text: 'Dest. Depot',
                                        alignment: 'left',
                                        fillColor: '#000000',
                                        color: '#ffffff',
                                        bold: true,
                                        fontSize: 6,
                                        border: [true, true, true, false],
                                      },
                                      {
                                        text: 'Dest. Agent',
                                        alignment: 'left',
                                        fillColor: '#000000',
                                        color: '#ffffff',
                                        bold: true,
                                        fontSize: 6,
                                        border: [true, true, true, false],
                                      }
                                    ],
                                    //第四排(資料排)
                                    [
                                      {
                                        text: labelInfo[i]['DeliveryHubCode'],
                                        alignment: 'center',
                                        fillColor: '#000000',
                                        color: '#ffffff',

                                        bold: true,
                                        fontSize: 15,
                                        border: [true, false, true, true],
                                      },
                                      {
                                        text: labelInfo[i]['DeliveryBranchCode'],
                                        alignment: 'center',
                                        fillColor: '#000000',
                                        color: '#ffffff',
                                        bold: true,
                                        fontSize: 15,
                                        border: [true, false, true, true],
                                      },
                                      {
                                        text: '',
                                        alignment: 'center',
                                        fillColor: '#000000',
                                        color: '#ffffff',
                                        bold: true,
                                        fontSize: 15,
                                        border: [true, false, true, true],
                                      }
                                    ]

                                  ]
                                },
                                border: [false, false, false, false],
                                layout: {
                                  hLineColor: '#999999',
                                  vLineColor: '#999999',


                                },
                                colSpan: 3,


                              },

                              '',
                              '',

                            ],
                            [
                              {
                                text: 'From',
                                alignment: 'left',
                                bold: true,
                                fontSize: 12,
                                colSpan: 2,
                                border: [true, true, false, false],
                              },
                              '',
                              {
                                text: 'Account No.: N6900038',
                                alignment: 'left',
                                bold: true,
                                fontSize: 8,
                                colSpan: 2,
                                border: [false, true, true, false],
                              },
                              ''

                            ],
                            [{
                              text: 'FREEGO TRADING AND LOGISTICS',
                              alignment: 'left',
                              bold: true,
                              fontSize: 8,
                              colSpan: 4,
                              border: [true, false, true, false],
                            },
                              '',
                              '',
                              ''

                            ],
                            [{
                              text: 'No 26,Jalan KU5, Pusat Komersial Universiti,Seremban 3,70300,Seremban,Negeri Sembilan,Malaysia',
                              alignment: 'left',
                              bold: true,
                              fontSize: 8,
                              width: 220,
                              colSpan: 4,
                              border: [true, false, true, false],
                            },
                              '',
                              '',
                              ''

                            ],
                            [{
                              text: 'MR TIEN',
                              alignment: 'left',
                              bold: true,
                              fontSize: 8,
                              colSpan: 4,
                              border: [true, false, true, false],
                            },
                              '',
                              '',
                              ''

                            ],
                            [{
                              text: '60126368616',
                              alignment: 'left',
                              fontSize: 8,
                              colSpan: 2,
                              border: [true, false, false, true],
                            },
                              '',
                            {
                              text: 'Cust. Ref. No.:',
                              colSpan: 2,
                              fontSize: 8,
                              bold: true,
                              border: [false, false, true, true],
                            },
                              ''

                            ],
                            [
                              {
                                text: 'To',
                                alignment: 'left',
                                bold: true,
                                fontSize: 12,
                                colSpan: 2,
                                border: [true, true, false, false],
                              },
                              '',
                              {
                                text: '',
                                colSpan: 2,
                                border: [false, true, true, false],
                              },
                              ''

                            ],
                            [{
                              text: order['收件地址'],
                              alignment: 'left',
                              width: 220,
                              fontSize: 8,
                              colSpan: 4,
                              border: [true, false, true, false],
                            },
                              '',
                              '',
                              ''

                            ],
                            [{
                              text: order['收件人姓名'] + '  ' + order['訂單單號'],
                              alignment: 'left',
                              fontSize: 8,
                              colSpan: 4,
                              border: [true, false, true, false],
                            },
                              '',
                              '',
                              ''

                            ],
                            [{
                              text: order['收件連絡電話'],
                              alignment: 'left',
                              fontSize: 8,
                              colSpan: 4,
                              border: [true, false, true, true],
                            },
                              '',
                              '',
                              ''

                            ],
                            [{
                              text: 'Weight(kg):' + weight + '   |Pcs:' + order['總箱數'] + '   |Type:Package',
                              alignment: 'left',
                              bold: true,
                              fontSize: 6,
                              colSpan: 2,
                              border: [true, true, false, true],
                            },
                              '',
                            {
                              text: 'Date : ' + this.state.day + '.' + this.state.month + '.' + this.state.year,
                              alignment: 'right',
                              colSpan: 2,
                              fontSize: 6,
                              bold: true,
                              border: [false, true, true, true],
                            },
                              '',


                            ],
                            [
                              {
                                text: '',
                                colSpan: 4,
                                height: 3,
                                border: [false, true, false, true]
                              },
                              '',
                              '',
                              ''
                            ],
                            [
                              {
                                text: 'Acknowledgement by Recipient',
                                fontSize: 8,
                                colSpan: 4,
                                height: 3,
                                bold: true,
                                border: [true, true, true, true]
                              },
                              '',
                              '',
                              ''
                            ],
                            [
                              {
                                text: '',
                                colSpan: 2,
                                border: [true, true, false, false]
                              },
                              '',
                              {
                                text: 'Name :',
                                fontSize: 8,
                                colSpan: 2,
                                border: [false, true, true, false]
                              },
                              ''
                            ],
                            [
                              {
                                text: 'Signature &  Company Stamp',
                                alignment: 'center',
                                color: '#b3b3b3',
                                fontSize: 8,
                                colSpan: 2,
                                border: [true, false, false, false]
                              },
                              '',
                              {
                                text: 'Date & Time :',
                                fontSize: 8,
                                colSpan: 2,
                                border: [false, false, true, false]
                              },
                              ''
                            ],
                            [
                              {
                                text: '',
                                colSpan: 2,
                                border: [true, false, false, true]
                              },
                              '',
                              {
                                text: 'IC / Passport :',
                                fontSize: 8,
                                colSpan: 2,
                                border: [false, false, true, true]
                              },
                              ''
                            ],


                            [{
                              text: '** For Terms of Carriage and Tracing, kindly log-in to www.lineclearexpress.com.',
                              fontSize: 6,
                              margin: [0, 1, 0, 0],
                              colSpan: 4,
                              bold: true,
                              border: [false, false, false, false],
                            },
                              '',
                              '',
                              ''
                            ]
                          ],

                        },

                        pageBreak: 'after'
                      }
                    ]
                    LabelArr.push(label);

                  }
                  

                }
                goodData[key]['MYdeliveryData'] = labelInfo[i]
                goodData[key]['快遞單號'] = barCodeInfo[0]
                
                ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+goodData[key]['國別']+'/'+this.state.userid+'/'+goodData[key].訂單單號).set(goodData[key]);
                ref.child('/infos/dates/'+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+goodData[key]['國別']+'/'+this.state.customerNumber+'/'+this.state.userid+'/'+goodData[key].訂單單號).set(goodData[key]);
                
              }

              pdfmake.fonts = {
                ch: {
                  normal: 'kaiu.ttf',
                  bold: 'kaiu.ttf',
                  italics: 'kaiu.ttf',
                  bolditalics: 'kaiu.ttf'
                }
              };

              var docDefinition = {
                content: LabelArr,
                defaultStyle: {
                  font: 'ch',
                  fontSize: 11
                },
                styles: {
                  head: {

                    fontSize: 66,
                    bold: true
                  },

                },

                // a string or { width: number, height: number }
                // 下面是10*10的標籤設計
                pageSize: { width: 283, height: 425 },


                // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                pageMargins: [3, 3, 3, 0],
              };
              
              
                
                //準備出單印製
                pdfmake.createPdf(docDefinition).print();

                let POs = [];
                let title = [
                  "酷天訂單號碼",       //A
                  "跨境通提單號碼",     //B
                  
                ];

                POs.push(title);

                Object.keys(goodData).forEach(function (key) {
                  var po = goodData[key];
                  

                  if (po['快遞單號'] === undefined) {
                    let poArray = [
                      po.訂單單號,
                      " "
                    ];
                    POs.push(poArray);
                  } else {
                    let poArray = [
                      po.訂單單號,
                      po['快遞單號']
                    ];
                    POs.push(poArray);
                  }

                });

                //創造一張新的表
                const wb = XLSX.utils.book_new();
                //下面這句就是把POs放進我們的sheet裡面
                const wsAll = XLSX.utils.aoa_to_sheet(POs);
                //把Sheet放進我們的Excel檔案裡面
                XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

                //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
                var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                //利用saveAs就可以把這個檔案存出來
                saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${this.state.todayTitle}-快遞單號對照表.xlsx`);


              
              
              this.setState({
                loading: false,
                MYPO:'',
                file:Math.random()
              })

            } else {
              //沒成功，就要把訊息印製出來
              console.log(responseInfo['Message'])
              console.log(responseInfo['Reason'])

              this.setState({
                message: responseInfo['Reason'] + '    請截圖通知跨境通',
                loading: false,
                file:Math.random()
              })

            }

          })

    }else{

      this.setState({
        message: '請先上傳資料',
        loading: false
      })
      
    }
    
    
  }
  


  UniversalImportExcel = file => {
    // 获取上传的文件对象
    const { files } = file.target;
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        // 以binary方式读取得到整份excel表格对象
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = []; // 存储获取到的数据

        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            break; // 如果除了取第一张表以外，還要其他的表，就取消注释这行
          }
        };

        //設定國別
        let nation ='MY'
        let goodData=[];
       
        var number = ''
        data.forEach((element)=> {                      
                  //console.log(element)
                  
                  let orderNmber = element['Your Ref No.'];
                  //console.log(orderNmber)
                  //單號不一樣，代表已經跳號了!!所以建立新訂單
                  if (number !== orderNmber){
                    number = orderNmber
                    let date = new Date();

                    goodData[number] = [];

                    goodData[number].訂單單號 = element['Your Ref No.'];
                    goodData[number].收件人郵遞區號 = element['Postal Code']

                    //date一定要做這個處理，要不然進不了realtime database
                    goodData[number].日期 = date.toDateString();
                    goodData[number].寄件人姓名='QOO10 TAIWAN LIMITED';

                    goodData[number].收件人姓名 = element['Customer Name'];
                    goodData[number].收件地址 = element['Recipient Address 1'].replace(/\r?\n|\r/g, " ");
                    goodData[number].收件連絡電話 = element['Mobile'];

                    goodData[number].國別 = 'MY';
                    
                    goodData[number]['items'] = {}
                    var str=element['Item Name']
                    //要把特殊符號刪除，要不然系統不給我進去
                    var itemName=str.replace(/[\ |\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\ |\=|\||\\|\[|\]|\{|\}|\;|\:|\”|\’|\,|\<|\.|\>|\/|\?]/g,'');
                    itemName=itemName.replace(/\s*/g,"")
                    
                    goodData[number]['items'][itemName] = String(element['Item Price (Total)']) + '*' + String(element['Item Qty'])
                    goodData[number]['報關總值'] = element['Item Price (Total)'];

                    goodData[number].總箱數 = 1;
                    goodData[number]['總重量-公斤'] = element['Weight'];
                    goodData[number]['箱長'] = element['BoxLength'];
                    goodData[number]['箱寬'] = element['BoxWidth'];
                    goodData[number]['箱高'] = element['BoxHeight'];
                    

                  }else{
                    var str=element['Item Name']
                    var itemName=str.replace(/[\ |\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\ |\=|\||\\|\[|\]|\{|\}|\;|\:|\”|\’|\,|\<|\.|\>|\/|\?|\+]/g,'');
                    itemName=itemName.replace(/\s*/g,"")
                    
                    goodData[number]['items'][itemName] = String(element['Item Price (Total)']) + '*' + String(element['Item Qty'])
                    goodData[number]['報關總值'] = goodData[number]['報關總值']+element['Item Price (Total)'];
                  }
                  
                  let content =''
                  Object.keys(goodData[number]['items']).forEach((key) => {
                       content =content+key+':'+goodData[number]['items'][key]+' '
                  })
                  
                  goodData[number]['內容物']=content
                  //console.log( goodData[number])

                  //當你是希望可以每一張訂單去修改修正的時候，你就要一張一張訂單存，這樣才不會全部覆蓋掉。
                  
                  //savePO(this.state.customerNumber,this.state.year,this.state.month,this.state.day,goodData[number]);
      });
        
           
           //要把國別記錄下來，這樣後面韓國、越南都可以好分類
           this.setState({[`${nation}PO`]: goodData, [`${nation}Upload`]:'success'});
           //console.log( 'o.k')     
           //把最後單號存起來，留著後面用
           tellAdmin(this.state.customerNumber,nation,this.state.year,this.state.month,this.state.day);
          //  console.log(typeof(goodData))
           //console.log( 'o.k') 
           this.setState({
            message: '上傳文件成功',
          })
      } catch (e) {
        // 这里可以抛出文件类型错误不正确的相关提示
        console.log('文件類型不正確');
        this.setState({
          message: '上傳文件有問題',
        })
        return;
      }
    };
    // 以Binary方式打开文件
    fileReader.readAsBinaryString(files[0]);
  }


  //刪除歷史資料
  deleteRecord() {


    if (this.state.deleteRecord !== '') {

      var target = this.state.deleteRecord
      var record = ''

      ref.child('/infos/customers/' + this.state.customerNumber + '/' + this.state.year + '/' + this.state.month + '/' + this.state.day + '/' + this.state.deliveryNation + '/' + this.state.userid + '/' + target).once('value', (snapshot) => {
        if (snapshot.val() == null) {
          this.setState({
            deleteRecordMessage: '查無此訂單，請確認國別和單號正確!',
          })
        } else {
          record = snapshot.val()

          ref.child('/infos/customers/' + this.state.customerNumber + '/' + this.state.year + '/' + this.state.month + '/' + this.state.day + '/' + this.state.deliveryNation + '/' + this.state.userid + '/' + target).set({}).then((error) => {
            if (error) {
              this.setState({
                deleteRecordMessage: '無法刪除訂單',
              })
            } else {

              this.setState({
                deleteRecordMessage: '已刪除訂單' + target,
                deleteRecord: ''
              })

            }
          })
         
        }
      })

    }
  }

  

 

  

  


  render() {
    return (



      <div style={{ padding: '5em 5em','font-size':'26px','font-weight':'bold','background-color': 'rgb(244, 173, 66)'}}>
        <Header as='h1'>您好，酷天有限公司。</Header>
        <Modal open={this.state.loading}>
          <Loader content='處理中' size='huge' />
        </Modal>
        <Table celled padded textAlign='center' style={{ padding: '5em 5em','background-color': 'rgb(244, 173, 66)',fontSize:20 }}>
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell>寄送國別</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>上傳資料</Table.HeaderCell>
                <Table.HeaderCell>下載標籤</Table.HeaderCell>
                
            </Table.Row>
            </Table.Header>
        
            <Table.Body>
            

            <Table.Row style={{'background-color': 'rgb(174, 198, 45)' }}>
                <Table.Cell><h1>萬用格式</h1></Table.Cell>
                <Table.Cell>
                       
                </Table.Cell>
                <Table.Cell>
                    <h1>請先上傳萬用格式訂單寄送資料</h1><br/>
                    <Input type='file'  accept='.xlsx, .xls' key={this.state.file || '' }  onChange={this.UniversalImportExcel} />
                    {this.state.MIXUpload?<h1>上傳成功</h1>:''}
                    {this.state.message ?
                      <Label style={{ width: 130, 'padding-right': '1em' }}> {this.state.message}</Label>
                      :
                      <div></div>
                    }
                </Table.Cell>
                <Table.Cell>
                <Button size='large'  color='orange' onClick={this.handleUniversalLablePrint}>請按我一下!<br/>把標籤印出來!!</Button>
                </Table.Cell>
            </Table.Row>

           
            
            </Table.Body>
        </Table>

        <Grid divided='vertically' centered >
          <Grid.Row columns={1}>
            <Grid.Column style={{ 'padding-top': '0em', 'padding-bottom': '0em', }}>

              <Segment raised>
                <Label as='a' color='red' ribbon>
                  刪除訂單資料(只能刪除當天上傳資料)
                </Label>
              
              <Form style={{ 'font-size': '18px' }}>

                <Form.Group widths='equal' id='shipperForm' style={{ height: '2.5em', 'padding-top': '0.5em' }}>
                  
                  <Label color='orange' style={{ width: 130, 'padding-right': '1em' }}>請輸入完整出貨單號，刪除資料</Label>
                  <Input
                    action={{
                      content: '輸入',
                      onClick: () => this.deleteRecord()
                    }}
                    value={this.state.deleteRecord}
                    name='deleteRecord'
                    onChange={this.handleInputChange}

                  />

                </Form.Group>
                {this.state.deleteRecordMessage ?
                  <Label style={{ width: 130, 'padding-right': '1em' }}> {this.state.deleteRecordMessage}</Label>
                  :
                  <div></div>
                }
              </Form>
            </Segment>
          </Grid.Column>
            </Grid.Row>


              
            </Grid>

      </div>




    );
  }
}

export default App;
