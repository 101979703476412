//這是為了讓不同人登入有不同的首頁所做的設計module
let Components = {};

   Components['C80071'] = require('../80071').default;
   Components['C80070'] = require('../80070').default;
   Components['C20038'] = require('../20038').default;
   Components['C30036'] = require('../30036').default;
   Components['C80036'] = require('../80036').default;
   Components['C40014'] = require('../40014').default;
   Components['C22306'] = require('../22306').default;
   Components['C80603'] = require('../80603').default;
   Components['C22306CH'] = require('../22306CH').default;
   Components['CDEC'] = require('../DEC').default;
   Components['CStandard'] = require('../Standard').default;
   //給後台用的標準上傳訂單介面
   Components['CAdminStandard'] = require('../AdminStandard').default;
   Components['CAdmin'] = require('../Admin').default;
   //Components['CAdmin'] = require('../IDservice').default;

 export default Components
