import React, { Component } from 'react';
import {Button,Input, Header,Table,Form,Grid,Segment,Label,Radio,Divider,Tab} from 'semantic-ui-react';
import {ref, firebaseAuth} from '../firebase/firebaseConfig';
import 'semantic-ui-css/semantic.min.css';
import Order from './20038/Order.js'
import Query from './20038/Query.js'


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      //這個每個客人都要改 RobinMay
      'customerNumber':'20038',
      //注意 RobinMay的客戶編號要改下面的子件，改這裡沒用
      // 'customerNumber':'test',
      'userid':'',
    };
    
    
    this.handleInputChange=this.handleInputChange.bind(this); 
  }


componentDidMount (){
    var userid = firebaseAuth.currentUser.uid;
    //console.log(userid)
    this.setState({
      'userid':userid,
    })
}



//這是所有欄位普用型的function
handleInputChange(e){
    this.setState({ [e.target.name]: e.target.value });  
    //console.log(this.state)
}



render() {

  const panes = [
    { menuItem: '快遞訂單輸入', 
      render: () => 
      <Tab.Pane style={{ padding: '0.3',}}>
          <div style={{ padding: '0','background-color': 'rgb(244, 173, 66)'}}>
              <Order/>
          </div>
      </Tab.Pane> 
    },

    { menuItem:"歷史資料查詢",
      render: () => 
       <Tab.Pane>
         <div style={{ 'background-color': 'rgb(244, 173, 66)'}}>
             <Query/>
         </div>
       </Tab.Pane> },
    
  ]

  

  return (
    <div  style={{ 'padding-left': '3em','padding-right': '3em' }}>
    <Header as='h4'>您好,ROBINMAY。</Header>
     
     <Tab panes={panes} style={{'background-color': 'rgb(236, 147, 40)' }}/>
      
    </div>
  );
}


}

export default App;
