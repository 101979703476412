//這一塊是進到每一個客戶的後台儀表板
import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import saveAs from 'file-saver';
import pdfmake from 'pdfmake/build/pdfmake';
import pdffonts from 'pdfmake/build/vfs_fonts';
import {Button,Grid,Label,List,Tab,Menu} from 'semantic-ui-react';
import {LogoImg} from './helper/Helper';
import {ref} from '../firebase/firebaseConfig';
import {todayTitle} from './helper/SaveToDatabase';
import Components from './helper/ComponentIndex';
import 'semantic-ui-css/semantic.min.css';


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
    
    
    this.ExportSGFile=this.ExportSGFile.bind(this);
    this.ExportSGSystemFile=this.ExportSGSystemFile.bind(this);
    this.SGLablePrint=this.SGLablePrint.bind(this);

    this.ExportMYFile=this.ExportMYFile.bind(this);
    this.ExportMYSystemFile=this.ExportMYSystemFile.bind(this);
    this.MYLablePrint=this.MYLablePrint.bind(this);
    
    this.ExportMOFile=this.ExportMOFile.bind(this);
    this.ExportMOSystemFile=this.ExportMOSystemFile.bind(this);

    this.ExportHKFile=this.ExportHKFile.bind(this);
    this.ExportHKSystemFile=this.ExportHKSystemFile.bind(this);

    this.ExportJPFile=this.ExportJPFile.bind(this);

  }

  componentDidMount(){
    const {CustomerNumber} = this.props.match.params;
    this.setState({'CustomerNumber':CustomerNumber});

    let today=todayTitle()[0];
    this.setState({'Today':today}); 
    let year=todayTitle()[1];
    let month=todayTitle()[2];
    let day=todayTitle()[3];
    
    
    ref.child('/infos/customers/'+CustomerNumber+'/'+year+'/'+month+'/'+day).on('value', (snapshot)=> {
        let POInfo=snapshot.val();
        //console.log('hello')
        //console.log(POInfo)
        if(POInfo){
        if(POInfo['SG']){
            let SGPO=POInfo['SG'];
            //因為中間有一個UID，要把這個UID取出來，才可以繼續讀下去
            let SGPOKey=Object.keys(SGPO)[0];
            this.setState({'SGPO':SGPO[SGPOKey]});
            //console.log(SGPO[SGPOKey]);
        };

        if(POInfo['MY']){
          let MYPO=POInfo['MY'];
          let MYPOKey=Object.keys(MYPO)[0];
          this.setState({'MYPO':MYPO[MYPOKey]});
          
          
        };

        if(POInfo['KR']){
          let KRPO=POInfo['KR'];
          let KRPOKey=Object.keys(KRPO)[0];
          this.setState({'KRPO':KRPO[KRPOKey]});
          
        };

        if(POInfo['ID']){
          let IDPO=POInfo['ID'];
          let IDPOKey=Object.keys(IDPO)[0];
          this.setState({'IDPO':IDPO[IDPOKey]});
          
        };

        if(POInfo['MO']){
          let MOPO=POInfo['MO'];
          let MOPOKey=Object.keys(MOPO)[0];
          this.setState({'MOPO':MOPO[MOPOKey]});
          
        };

        if(POInfo['HK']){
          let HKPO=POInfo['HK'];
          let HKPOKey=Object.keys(HKPO)[0];
          this.setState({'HKPO':HKPO[HKPOKey]});
          
        };
        
        if(POInfo['JP']){
          let JPPO=POInfo['JP'];
          let JPPOKey=Object.keys(JPPO)[0]; 
          this.setState({'JPPO':JPPO[JPPOKey]});
          
        };

      };
    
    });

    
     
  }

  SGLablePrint(){
    pdfmake.vfs = pdffonts.pdfMake.vfs;
    //利用下面的function就可以把DataURL導出來

    let goodData=this.state.SGPO;
    //goodData是一個object，不是Array

    var LabelArr = [];

    //把最後一張的key抓出來
    const keys = Object.keys(goodData);
    var last=keys.pop();

    Object.keys(goodData).forEach((key)=>{

      var po=goodData[key];
      //依照箱數我需要印不同張數的label 
      var labelnumber=po.總箱數;
      var logo=LogoImg();


      if (typeof po.內容物 =='string'){

        
            if(key===last){
                  for(var i=0;i<labelnumber;i++){
                    let Label=[
                      { table: {
                        widths: ['auto', '*', '*', '*', '*', '*'],
                        //下面是要做一開始的logo、barcode、和國碼標
                        body: [
                          [
                            {
                            // you'll most often use dataURI images on the browser side
                            // if no width/height/fit is provided, the original size will be used
                            image:logo,
                            width:100, 
                            height:20,
                            alignment: 'center',
                            colSpan: 3},
                            '',
                            '',
                            { rowSpan: 2,
                              colSpan: 3,
                              text: po.國別,
                              style: 'head',  
                              width:100, 
                              alignment: 'center',          
                            },
                            '',
                            ''
          
                          ],
                          [{ image:po.dataURL,
                            colSpan: 3,
                            width:140, 
                            height:50,
                            alignment: 'center',
                          },
                          {},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{ text: 'MAWB:'},
                          {text: po.MAWB,
                            colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{ text: 'PO:'},
                          {text:po.訂單單號,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Shipper:'},
                          {text: po.寄件人姓名,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Consignee:'},
                          {text: po.收件人姓名,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'TEL:'},
                          {text: po.收件連絡電話,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Address:',rowSpan: 2},
                          {text: po.收件地址,rowSpan: 2,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{},
                          {},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Declared Value:'},
                          {text: 'SGD$'+po['報關總值-新幣計價'],colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Weight:'},
                          {text: po['總重量-公斤']+'KGS',colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{text: 'Description:'},
                          {text: po.內容物,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                        ]
                      }},     
                    ];

                    LabelArr.push(Label);
                  }

            }else{
                  for(var i=0;i<labelnumber;i++){
                    let Label=[
                      { table: {
                        widths: ['auto', '*', '*', '*', '*', '*'],
                        //下面是要做一開始的logo、barcode、和國碼標
                        body: [
                          [
                            {
                            // you'll most often use dataURI images on the browser side
                            // if no width/height/fit is provided, the original size will be used
                            image:logo,
                            width:100, 
                            height:20,
                            alignment: 'center',
                            colSpan: 3},
                            '',
                            '',
                            { rowSpan: 2,
                              colSpan: 3,
                              text: po.國別,
                              style: 'head',  
                              width:100, 
                              alignment: 'center',          
                            },
                            '',
                            ''
          
                          ],
                          [{ image:po.dataURL,
                            colSpan: 3,
                            width:140, 
                            height:50,
                            alignment: 'center',
                          },
                          {},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{ text: 'MAWB:'},
                          {text: po.MAWB,
                            colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{ text: 'PO:'},
                          {text:po.訂單單號,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Shipper:'},
                          {text: po.寄件人姓名,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Consignee:'},
                          {text: po.收件人姓名,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'TEL:'},
                          {text: po.收件連絡電話,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Address:',rowSpan: 2},
                          {text: po.收件地址,rowSpan: 2,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{},
                          {},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Declared Value:'},
                          {text: 'SGD$'+po['報關總值-新幣計價'],colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Weight:'},
                          {text: po['總重量-公斤']+'KGS',colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{text: 'Description:'},
                          {text: po.內容物,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                        ],
                       
                        },
                        pageBreak: 'after'
                      }, 
                     
                    ];

                    LabelArr.push(Label);
                  };
            };
            

            

      }else{
          
          var content='';
          //一般訂單進來，我會把各分項做成一個array，放進內容物，現在我要把這個array解開
          Object.keys(po.內容物).forEach((key)=> {
              var item=po.內容物[key];
              if(content){
                content=content+'.'+item.內容物+'x'+item.件數;
                
              }else{
                content=item.內容物+'x'+item.件數
              }
          });

          for(var i=0;i<labelnumber;i++){

            if(key===last){
              let Label=[
                { table: {
                  widths: ['auto', '*', '*', '*', '*', '*'],
                  //下面是要做一開始的logo、barcode、和國碼標
                  body: [
                    [
                      {
                      // you'll most often use dataURI images on the browser side
                      // if no width/height/fit is provided, the original size will be used
                      image:logo,
                      width:100, 
                      height:20,
                      alignment: 'center',
                      colSpan: 3},
                      '',
                      '',
                      { rowSpan: 2,
                        colSpan: 3,
                        text: po.國別,
                        style: 'head',  
                        width:100, 
                        alignment: 'center',          
                      },
                      '',
                      ''
    
                    ],
                    [{ image:po.dataURL,
                      colSpan: 3,
                      width:140, 
                      height:50,
                      alignment: 'center',
                    },
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{ text: 'MAWB:'},
                    {text: po.MAWB,
                      colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{ text: 'PO:'},
                    {text:po.訂單單號,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Shipper:'},
                    {text: po.寄件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Consignee:'},
                    {text: po.收件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'TEL:'},
                    {text: po.收件連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Address:',rowSpan: 2},
                    {text: po.收件地址,rowSpan: 2,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{},
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Declared Value:'},
                    {text: 'SGD$'+po['報關總值-新幣計價'],colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Weight:'},
                    {text: po['總重量-公斤']+'KGS',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{text: 'Description:'},
                    {text: content,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                  ]
                }},     
              ];
              LabelArr.push(Label);

            }else{
              let Label=[
                { table: {
                  widths: ['auto', '*', '*', '*', '*', '*'],
                  //下面是要做一開始的logo、barcode、和國碼標
                  body: [
                    [
                      {
                      // you'll most often use dataURI images on the browser side
                      // if no width/height/fit is provided, the original size will be used
                      image:logo,
                      width:100, 
                      height:20,
                      alignment: 'center',
                      colSpan: 3},
                      '',
                      '',
                      { rowSpan: 2,
                        colSpan: 3,
                        text: po.國別,
                        style: 'head',  
                        width:100, 
                        alignment: 'center',          
                      },
                      '',
                      ''
    
                    ],
                    [{ image:po.dataURL,
                      colSpan: 3,
                      width:140, 
                      height:50,
                      alignment: 'center',
                    },
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{ text: 'MAWB:'},
                    {text: po.MAWB,
                      colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{ text: 'PO:'},
                    {text:po.訂單單號,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Shipper:'},
                    {text: po.寄件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Consignee:'},
                    {text: po.收件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'TEL:'},
                    {text: po.收件連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Address:',rowSpan: 2},
                    {text: po.收件地址,rowSpan: 2,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{},
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Declared Value:'},
                    {text: 'SGD$'+po['報關總值-新幣計價'],colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Weight:'},
                    {text: po['總重量-公斤']+'KGS',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{text: 'Description:'},
                    {text: po.內容物,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                  ],
                 
                  },
                  pageBreak: 'after'
                }, 
               
              ];
              LabelArr.push(Label);

            };

          };
      

      }
     
    });

    pdfmake.fonts = {
      ch: {
      normal: 'kaiu.ttf',
      bold: 'kaiu.ttf',
      italics: 'kaiu.ttf',
      bolditalics: 'kaiu.ttf'
      }
    };

    var docDefinition = {
        content:LabelArr,
        defaultStyle: {
        font: 'ch',
        fontSize: 11
        } ,
        styles: {
          head: {
            fontSize: 66,
            bold: true
          },

        },

        // a string or { width: number, height: number }
        pageSize: { width: 283, height: 283},

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 3, 3, 3, 0 ],
    };

    let labeltitle="跨境通標籤-SG"+this.state.CustomerNumber+'-'+this.state.Today;


    pdfmake.createPdf(docDefinition).download(labeltitle);
    //pdfmake.createPdf(docDefinition).open();
  
  }
    
  ExportSGFile(){
    let POs = [];
      let title=[
          "跨境通提單號碼",              //A
          "訂單號碼",          //B
          "件數",                  //C
          "收件人",              //D
          "地址",           //E
          "電話",        //F
          "內容",               //G
          "重量",    //H
          "價值 NT$",            //I
          "郵遞區號",           //J
          "新幣價值",
        ];

       POs.push(title);

       
       var PoInfo=this.state.SGPO;
       Object.keys(PoInfo).forEach(function(key) {
             var po = PoInfo[key];
             //console.log(typeof po.內容物);
             
             if (typeof po.內容物 =='string'){
                  let poArray = [
                    po.快遞單號,                 
                    po.訂單單號,                 
                    po.總箱數, 
                    po.收件人姓名,
                    po.收件地址,
                    po.收件連絡電話,
                    po.內容物,
                    Number(po['總重量-公斤']),                  
                    po['報關總值'],     
                    po.收件人郵遞區號,  
                    Number((po['報關總值']/22).toFixed(1)),                  
                   
                  ];
                  POs.push(poArray);
              }else{
                  
              }
    
     });

      //創造一張新的表
      const wb = XLSX.utils.book_new();
      //下面這句就是把POs放進我們的sheet裡面
      const wsAll = XLSX.utils.aoa_to_sheet(POs);
      //把Sheet放進我們的Excel檔案裡面
      XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

      //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
      //利用saveAs就可以把這個檔案存出來
      saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.Today}-${this.state.CustomerNumber}-SG2訂單.xlsx`);
      
      //下面這句語法是xlsx比較簡單的下載語法，不能選擇儲存路徑
      //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-SG2訂單.xlsx`);

  }

  //下面這個是要把資料導出，丟公司系統
  ExportSGSystemFile(){
     //以下這是一個直接內嵌的寫法，上面的是做一個空的[]，在push，這裡是直接寫進去 
     let POs = [[
         "HAWB",                      //A
         "單號",                      //B
         "寄件人",                    //C
         "寄件地址",                  //D
         "收件人",                    //E
         "收件地址",                  //F
         "PKG",                      //G
         "內容物",                    //H
         "件數",                      //I
         "公斤數",                    //J
         "報關價值",                  //K
         "Remark",                   //L
         "consignee City",           //M
         "Consignee Region",         //N
         "Consignee ZIP Code",       //O
         "Contact Person",           //P
         "連絡電話",                  //Q
         "Personal ID / KTP",        //R
         "Reference Number",         //S
         "DOX/SPX",                  //T
         "CC/PP",                    //U
         "CC Amount",                //V
         "Currency"                  //W
        ]];
      var PoInfo=this.state.SGPO;
      

      Object.keys(PoInfo).forEach(function(key) {
        var po = PoInfo[key];

        if (typeof po.內容物 =='string'){
          let poArray = [
            '',                             //HAWB
            po.快遞單號,                     //單號
            po.寄件人姓名,                   //寄件人
            po.寄件地址,                     //寄件地址
            po.收件人姓名,                   //收件人
            po.收件地址,                     //收件地址
            "",                             //PKG
            po.內容物,                      //內容物
            po.總箱數,                      //件數
            Number(po['總重量-公斤']),               //公斤數
            po['報關總值'],                 //報關價值
            "",                             //Remark
            "",                             //consignee City
            "SIN",                        //Consignee Region
            po.收件人郵遞區號,               //Consignee ZIP Code
            po.收件人姓名,                   //Contact Person
            po.收件連絡電話,                //連絡電話
            "",                            //Personal ID / KTP
            "",                            //Reference Number
            "SPX",                         //DOX/SPX
            "PP",                          //CC/PP
            "",                            //CC Amount
            "",                         //Currency
          ];
          //console.log(poArray);
          POs.push(poArray);

        }else{

              var size = Object.keys(po.內容物).length;
              if(size===1){
                  let poArray = [
                    '',                             //HAWB
                    po.快遞單號,                     //單號
                    po.寄件人姓名,                   //寄件人
                    po.寄件地址,                     //寄件地址
                    po.收件人姓名,                   //收件人
                    po.收件地址,                     //收件地址
                    "",                             //PKG
                    po['內容物'][0].內容物,                      //內容物
                    po['內容物'][0].件數,                             //件數
                    po['總重量-公斤'],                      //公斤數
                    po['報關總值'],                  //報關價值
                    "",                             //Remark
                    "",                             //consignee City
                    "SIN",                        //Consignee Region
                    po.收件人郵遞區號,               //Consignee ZIP Code
                    po.連絡人,                      //Contact Person
                    po.收件連絡電話,                //連絡電話
                    "",                            //Personal ID / KTP
                    "",                            //Reference Number
                    "",                            //DOX/SPX
                    "",                            //CC/PP
                    "",                            //CC Amount
                    "",                         //Currency
                  ];
                  //console.log(poArray);
                  POs.push(poArray);
            }else{
              let poArray = [
                    '',                             //HAWB
                    po.快遞單號,                     //單號
                    po.寄件人姓名,                   //寄件人
                    po.寄件地址,                     //寄件地址
                    po.收件人姓名,                   //收件人
                    po.收件地址,                     //收件地址
                    "",                             //PKG
                    po['內容物'][0].內容物,          //內容物
                    po['內容物'][0].件數,            //件數
                    po['總重量-公斤'],               //公斤數
                    po['報關總值'],                  //報關價值
                    "",                             //Remark
                    "",                             //consignee City
                    "SIN",                        //Consignee Region
                    po.收件人郵遞區號,               //Consignee ZIP Code
                    po.連絡人,                      //Contact Person
                    po.收件連絡電話,                //連絡電話
                    "",                            //Personal ID / KTP
                    "",                            //Reference Number
                    "",                            //DOX/SPX
                    "",                            //CC/PP
                    "",                            //CC Amount
                    "",                         //Currency
              ];

              POs.push(poArray);
              var i;
              for(i=1;i<size;i++){

                let poArray = [
                  "",
                  po.快遞單號,
                  "",
                  "",
                  "",
                  "",
                  "",
                  po['內容物'][i].內容物,
                  po['內容物'][i].件數,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  ""
                ];
                POs.push(poArray);
              }
            }

        }

        
        

      });

     //創造一張新的表
     const wb = XLSX.utils.book_new();
     //下面這句就是把POs放進我們的sheet裡面
     const wsAll = XLSX.utils.aoa_to_sheet(POs);
     //把Sheet放進我們的Excel檔案裡面
     XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

     //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
     var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
     //利用saveAs就可以把這個檔案存出來
     saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.Today}-${this.state.CustomerNumber}-SG上傳公司系統訂單.xlsx`);
     //把表命名做出來
     //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-上傳公司系統訂單.xlsx`);


  }



  MYLablePrint(){
    pdfmake.vfs = pdffonts.pdfMake.vfs;
    //利用下面的function就可以把DataURL導出來

    let goodData=this.state.MYPO;
    //goodData是一個object，不是Array
    /*goodData=goodData.sort(function (a, b) {
      return a['收件人姓名'] > b['收件人姓名']? 1 : -1;
     });*/
   

    var LabelArr = [];

    //把最後一張的key抓出來
    const keys = Object.keys(goodData);
    var last=keys.pop();

    Object.keys(goodData).forEach((key)=>{

      var po=goodData[key];
      //依照箱數我需要印不同張數的label 
      var labelnumber=po.總箱數;
      var logo=LogoImg();


      if (typeof po.內容物 =='string'){

        for(var i=0;i<(labelnumber*2);i++){
            if(key===last&&i==(labelnumber*2-1)){
                  
                    let Label=[
                      { table: {
                        widths: ['auto', '*', '*', '*', '*', '*'],
                        //下面是要做一開始的logo、barcode、和國碼標
                        body: [
                          [
                            {
                            // you'll most often use dataURI images on the browser side
                            // if no width/height/fit is provided, the original size will be used
                            image:logo,
                            width:100, 
                            height:20,
                            alignment: 'center',
                            colSpan: 3},
                            '',
                            '',
                            { rowSpan: 2,
                              colSpan: 3,
                              text: po.國別,
                              style: 'head',  
                              width:100, 
                              alignment: 'center',          
                            },
                            '',
                            ''
          
                          ],
                          [{ image:po.dataURL,
                            colSpan: 3,
                            width:140, 
                            height:50,
                            alignment: 'center',
                          },
                          {},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{ text: 'A/C NO:'},
                          {text: 'N6900038',
                            colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{ text: 'PO:'},
                          {text:po.訂單單號,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Shipper:'},
                          {text: po.寄件人姓名,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Consignee:'},
                          {text: po.收件人姓名,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'TEL:'},
                          {text: po.收件連絡電話,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Address:',rowSpan: 2},
                          {text: po.收件地址,rowSpan: 2,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{},
                          {},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Declared Value:'},
                          {text: 'MYR$'+po['報關總值-馬幣計價'],colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Weight:'},
                          {text: po['總重量-公斤']+'KGS',colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{text: 'Description:'},
                          {text: po.內容物,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                        ]
                      }},     
                    ];

                    LabelArr.push(Label);
                  

            }else{
                 
                    let Label=[
                      { table: {
                        widths: ['auto', '*', '*', '*', '*', '*'],
                        //下面是要做一開始的logo、barcode、和國碼標
                        body: [
                          [
                            {
                            // you'll most often use dataURI images on the browser side
                            // if no width/height/fit is provided, the original size will be used
                            image:logo,
                            width:100, 
                            height:20,
                            alignment: 'center',
                            colSpan: 3},
                            '',
                            '',
                            { rowSpan: 2,
                              colSpan: 3,
                              text: po.國別,
                              style: 'head',  
                              width:100, 
                              alignment: 'center',          
                            },
                            '',
                            ''
          
                          ],
                          [{ image:po.dataURL,
                            colSpan: 3,
                            width:140, 
                            height:50,
                            alignment: 'center',
                          },
                          {},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{ text: 'A/C NO:'},
                          {text:  'N6900038',
                            colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{ text: 'PO:'},
                          {text:po.訂單單號,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Shipper:'},
                          {text: po.寄件人姓名,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Consignee:'},
                          {text: po.收件人姓名,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'TEL:'},
                          {text: po.收件連絡電話,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Address:',rowSpan: 2},
                          {text: po.收件地址,rowSpan: 2,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{},
                          {},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Declared Value:'},
                          {text: 'MYR$'+po['報關總值-馬幣計價'],colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                          [{text: 'Weight:'},
                          {text: po['總重量-公斤']+'KGS',colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
                          [{text: 'Description:'},
                          {text: po.內容物,colSpan: 5},
                          {},
                          {},
                          {},
                          {}
                          ],
          
                        ],
                       
                        },
                        pageBreak: 'after'
                      }, 
                     
                    ];

                    LabelArr.push(Label);
                  
            };
          };
            

            

      }else{
          
          /*
          var content='';
          //一般訂單進來，我會把各分項做成一個array，放進內容物，現在我要把這個array解開
          Object.keys(po.內容物).forEach((key)=> {
              var item=po.內容物[key];
              if(content){
                content=content+'.'+item.內容物+'x'+item.件數;
                
              }else{
                content=item.內容物+'x'+item.件數
              }
          });
          */

          for(var i=0;i<(labelnumber*2);i++){

            if(key===last&&i==(labelnumber*2-1)){
              let Label=[
                { table: {
                  widths: ['auto', '*', '*', '*', '*', '*'],
                  //下面是要做一開始的logo、barcode、和國碼標
                  body: [
                    [
                      {
                      // you'll most often use dataURI images on the browser side
                      // if no width/height/fit is provided, the original size will be used
                      image:logo,
                      width:100, 
                      height:20,
                      alignment: 'center',
                      colSpan: 3},
                      '',
                      '',
                      { rowSpan: 2,
                        colSpan: 3,
                        text: po.國別,
                        style: 'head',  
                        width:100, 
                        alignment: 'center',          
                      },
                      '',
                      ''
    
                    ],
                    [{ image:po.dataURL,
                      colSpan: 3,
                      width:140, 
                      height:50,
                      alignment: 'center',
                    },
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{ text: 'A/C NO:'},
                    {text: 'N6900038',
                      colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{ text: 'PO:'},
                    {text:po.訂單單號,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Shipper:'},
                    {text: po.寄件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Consignee:'},
                    {text: po.收件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'TEL:'},
                    {text: po.收件連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Address:',rowSpan: 2},
                    {text: po.收件地址,rowSpan: 2,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{},
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Declared Value:'},
                    {text: 'MYR$',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Weight:'},
                    {text: ''+'KGS',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{text: 'Description:'},
                    {text: '',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                  ]
                }},     
              ];
              LabelArr.push(Label);

            }else{
              let Label=[
                { table: {
                  widths: ['auto', '*', '*', '*', '*', '*'],
                  //下面是要做一開始的logo、barcode、和國碼標
                  body: [
                    [
                      {
                      // you'll most often use dataURI images on the browser side
                      // if no width/height/fit is provided, the original size will be used
                      image:logo,
                      width:100, 
                      height:20,
                      alignment: 'center',
                      colSpan: 3},
                      '',
                      '',
                      { rowSpan: 2,
                        colSpan: 3,
                        text: po.國別,
                        style: 'head',  
                        width:100, 
                        alignment: 'center',          
                      },
                      '',
                      ''
    
                    ],
                    [{ image:po.dataURL,
                      colSpan: 3,
                      width:140, 
                      height:50,
                      alignment: 'center',
                    },
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{ text: 'A/C NO:'},
                    {text: 'N6900038',
                      colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{ text: 'PO:'},
                    {text:po.訂單單號,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Shipper:'},
                    {text: po.寄件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Consignee:'},
                    {text: po.收件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'TEL:'},
                    {text: po.收件連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Address:',rowSpan: 2},
                    {text: po.收件地址,rowSpan: 2,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{},
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Declared Value:'},
                    {text: 'MYR$',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                    [{text: 'Weight:'},
                    {text: ''+'KGS',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{text: 'Description:'},
                    {text: '',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
    
                  ],
                 
                  },
                  pageBreak: 'after'
                }, 
               
              ];
              LabelArr.push(Label);

            };

          };
      

      }
     
    });

    pdfmake.fonts = {
      ch: {
      normal: 'kaiu.ttf',
      bold: 'kaiu.ttf',
      italics: 'kaiu.ttf',
      bolditalics: 'kaiu.ttf'
      }
    };

    var docDefinition = {
        content:LabelArr,
        defaultStyle: {
        font: 'ch',
        fontSize: 11
        } ,
        styles: {
          head: {
            fontSize: 66,
            bold: true
          },

        },

        // a string or { width: number, height: number }
        pageSize: { width: 283, height: 283},

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 3, 3, 3, 0 ],
    };

    let labeltitle="跨境通標籤-MY"+this.state.CustomerNumber+'-'+this.state.Today;


    pdfmake.createPdf(docDefinition).download(labeltitle);
    //pdfmake.createPdf(docDefinition).open();
  
  }
    
  ExportMYFile(){
    let POs = [];
      let title=[
          "序NO",                                      //A
          "快递单号Tracking No",                        //B
          "唛头Marking",                                //C
          "袋子号码Bag No",                             //D
          "收货人姓名Reciever",                         //E
          "联络电话Tel",                                //F
          "收货地址Address",                            //G
          "邮区编号Postcode",                           //H
          "箱数Carton",                                 //I
          "总重量Total Weight (Kg)E",                   //J
          "计费重量Chargeable Weight (KG)",             //K
          "首一公斤运费1st 1 Kg",                       //L
          "续一公斤运费Following 1 Kg",                 //M
          "计费重量",                                   //N
          "小计Sub Total (RM)",                         //O
         
        ];

       POs.push(title);

       
       var PoInfo=this.state.MYPO;
       Object.keys(PoInfo).forEach(function(key) {
             var po = PoInfo[key];
             //console.log(typeof po.內容物);
             
             if (typeof po.內容物 =='string'){
                  let poArray = [
                    "",                                      //A
                    po.快遞單號,                              //B
                    "",                                      //C
                    "",                                      //D
                    po.收件人姓名,                            //E
                    po.收件連絡電話,                          //F
                    po.收件地址,                              //G
                    po.收件人郵遞區號,                        //H
                    po.總箱數,                                //I
                    Number(po['總重量-公斤']),                                //J
                    "",                                      //K
                    "",                                      //L
                    "",                                      //M
                    "",                                      //N
                    "",                                      //O
                  ];
                  POs.push(poArray);
              }else{
                  /*               
                  let content=po.內容物;
                  let temp=[];
                  let PO='';
                  Object.keys(content).forEach(function(key) { 
                    let ItemName=content[key]['內容物'];
                    let ItemPCs=content[key]['件數'];              
                    temp.push(`${ItemName}x${ItemPCs}`)
                  });
                  Object.keys(temp).forEach(function(key) { 
                    PO=PO+`${temp[key]} `;
                  });
                  */

                  let poArray = [
                    "",                                      //A
                    po.快遞單號,                              //B
                    "",                                      //C
                    "",                                      //D
                    po.收件人姓名,                            //E
                    po.收件連絡電話,                          //F
                    po.收件地址,                              //G
                    po.收件人郵遞區號,                        //H
                    po.總箱數,                                //I
                    po['總重量-公斤'],                                //J
                    "",                                      //K
                    "",                                      //L
                    "",                                      //M
                    "",                                      //N
                    "",                                      //O
                  ];
                  POs.push(poArray);
                  


              }
    
     });

      //創造一張新的表
      const wb = XLSX.utils.book_new();
      //下面這句就是把POs放進我們的sheet裡面
      const wsAll = XLSX.utils.aoa_to_sheet(POs);
      //把Sheet放進我們的Excel檔案裡面
      XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

      //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
      //利用saveAs就可以把這個檔案存出來
      saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.Today}-${this.state.CustomerNumber}-MY2訂單.xlsx`);
      
      //下面這句語法是xlsx比較簡單的下載語法，不能選擇儲存路徑
      //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-SG2訂單.xlsx`);

  }

  //下面這個是要把資料導出，丟公司系統
  ExportMYSystemFile(){
     //以下這是一個直接內嵌的寫法，上面的是做一個空的[]，在push，這裡是直接寫進去 
     let POs = [[
         "HAWB",                      //A
         "單號",                      //B
         "寄件人",                    //C
         "寄件地址",                  //D
         "收件人",                    //E
         "收件地址",                  //F
         "PKG",                      //G
         "內容物",                    //H
         "件數",                      //I
         "公斤數",                    //J
         "報關價值",                  //K
         "Remark",                   //L
         "consignee City",           //M
         "Consignee Region",         //N
         "Consignee ZIP Code",       //O
         "Contact Person",           //P
         "連絡電話",                  //Q
         "Personal ID / KTP",        //R
         "Reference Number",         //S
         "DOX/SPX",                  //T
         "CC/PP",                    //U
         "CC Amount",                //V
         "Currency"                  //W
        ]];
      
      
      var PoInfo=this.state.MYPO;

   
      Object.keys(PoInfo).forEach(function(key) {
        var po = PoInfo[key];

        if (typeof po.內容物 =='string'){
          if(po['箱長']===undefined){
            let poArray = [
              '',                             //HAWB
              po.快遞單號,                     //單號
              po.寄件人姓名,                   //寄件人
              po.寄件地址,                     //寄件地址
              po.收件人姓名,                   //收件人
              po.收件地址,                     //收件地址
              "",                             //PKG
              po.內容物,                      //內容物
              po.總箱數,                      //件數
              Number(po['總重量-公斤']),               //公斤數
              po['報關總值'],         //報關價值
              "",                             //Remark
              "",                             //consignee City
              "KUL",                        //Consignee Region
              po.收件人郵遞區號,               //Consignee ZIP Code
              po.收件人姓名,                      //Contact Person
              po.收件連絡電話,                //連絡電話
              "",                            //Personal ID / KTP
              "",                            //Reference Number
              "SPX",                            //DOX/SPX
              "PP",                            //CC/PP
              "",                            //CC Amount
              "",                         //Currency
            ];
            //console.log(poArray);
            POs.push(poArray);

          }else{
            let poArray = [
              '',                             //HAWB
              po.快遞單號,                     //單號
              po.寄件人姓名,                   //寄件人
              po.寄件地址,                     //寄件地址
              po.收件人姓名,                   //收件人
              po.收件地址,                     //收件地址
              "",                             //PKG
              po.內容物,                      //內容物
              po.總箱數,                      //件數
              Number(po['總重量-公斤']),               //公斤數
              po['報關總值'],                          //報關價值
              po['箱長']+'*'+po['箱寬']+'*'+po['箱高'], //Remark  有要算材積重的，要給跨境通
              "",                             //consignee City
              "KUL",                        //Consignee Region
              po.收件人郵遞區號,               //Consignee ZIP Code
              po.收件人姓名,                      //Contact Person
              po.收件連絡電話,                //連絡電話
              "",                            //Personal ID / KTP
              "",                            //Reference Number
              "SPX",                            //DOX/SPX
              "PP",                            //CC/PP
              "",                            //CC Amount
              "",                         //Currency
            ];
            //console.log(poArray);
            POs.push(poArray);

          }
         

        }else{

              var size = Object.keys(po.內容物).length;
              if(size===1){
                  let poArray = [
                    '',                             //HAWB
                    po.快遞單號,                     //單號
                    po.寄件人姓名,                   //寄件人
                    po.寄件地址,                     //寄件地址
                    po.收件人姓名,                   //收件人
                    po.收件地址,                     //收件地址
                    "",                             //PKG
                    po['內容物'][0].內容物,          //內容物
                    po['內容物'][0].件數,            //件數
                    po['總重量-公斤'],               //公斤數
                    po['報關總值'],         //報關價值
                    "",                             //Remark
                    "",                             //consignee City
                    "KUL",                        //Consignee Region
                    po.收件人郵遞區號,               //Consignee ZIP Code
                    po.連絡人,                      //Contact Person
                    po.收件連絡電話,                //連絡電話
                    "",                            //Personal ID / KTP
                    "",                            //Reference Number
                    "",                            //DOX/SPX
                    "",                            //CC/PP
                    "",                            //CC Amount
                    "",                         //Currency
                  ];
                  //console.log(poArray);
                  POs.push(poArray);
            }else{
              let poArray = [
                    '',                             //HAWB
                    po.快遞單號,                     //單號
                    po.寄件人姓名,                   //寄件人
                    po.寄件地址,                     //寄件地址
                    po.收件人姓名,                   //收件人
                    po.收件地址,                     //收件地址
                    "",                             //PKG
                    po['內容物'][0].內容物,                      //內容物
                    po['內容物'][0].件數,                             //件數
                    po['總重量-公斤'],                      //公斤數
                    po['報關總值'],         //報關價值
                    "",                             //Remark
                    "",                             //consignee City
                    "KUL",                        //Consignee Region
                    po.收件人郵遞區號,               //Consignee ZIP Code
                    po.連絡人,                      //Contact Person
                    po.收件連絡電話,                //連絡電話
                    "",                            //Personal ID / KTP
                    "",                            //Reference Number
                    "",                            //DOX/SPX
                    "",                            //CC/PP
                    "",                            //CC Amount
                    "",                         //Currency
              ];

              POs.push(poArray);
              var i;
              for(i=1;i<size;i++){

                let poArray = [
                  "",
                  po.快遞單號,
                  "",
                  "",
                  "",
                  "",
                  "",
                  po['內容物'][i].內容物,
                  po['內容物'][i].件數,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  ""
                ];
                POs.push(poArray);
              }
            }

        }

        
        

      });

     //創造一張新的表
     const wb = XLSX.utils.book_new();
     //下面這句就是把POs放進我們的sheet裡面
     const wsAll = XLSX.utils.aoa_to_sheet(POs);
     //把Sheet放進我們的Excel檔案裡面
     XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

     //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
     var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
     //利用saveAs就可以把這個檔案存出來
     saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.Today}-${this.state.CustomerNumber}-MY上傳公司系統訂單.xlsx`);
     //把表命名做出來
     //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-上傳公司系統訂單.xlsx`);


  }



  ExportMOFile(){
    let POs = [];
      let title=[
          "跨境通提單號碼",              //A
          "訂單號碼",          //B
          "件數",                  //C
          "收件人",              //D
          "地址",           //E
          "電話",        //F
          "內容",               //G
          "重量",    //H
          "價值 NT$",            //I
                             //J
          
        ];

       POs.push(title);

       
       var PoInfo=this.state.MOPO;
       Object.keys(PoInfo).forEach(function(key) {
             var po = PoInfo[key];
             //console.log(typeof po.內容物);
             
             if (typeof po.內容物 =='string'){
                  let poArray = [
                    po.快遞單號,                 
                    po.訂單單號,                 
                    po.總箱數, 
                    po.收件人姓名,
                    po.收件地址,
                    po.收件連絡電話,
                    po.內容物,
                    Number(po['總重量-公斤']),                  
                    po['報關總值'],     
                                        
                   
                  ];
                  POs.push(poArray);
              }else{
                  
              }
    
     });

      //創造一張新的表
      const wb = XLSX.utils.book_new();
      //下面這句就是把POs放進我們的sheet裡面
      const wsAll = XLSX.utils.aoa_to_sheet(POs);
      //把Sheet放進我們的Excel檔案裡面
      XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

      //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
      //利用saveAs就可以把這個檔案存出來
      saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.Today}-${this.state.CustomerNumber}-MO2訂單.xlsx`);
      
      //下面這句語法是xlsx比較簡單的下載語法，不能選擇儲存路徑
      //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-SG2訂單.xlsx`);

  }
  //下面這個是要把資料導出，丟公司系統
  ExportMOSystemFile(){
     //以下這是一個直接內嵌的寫法，上面的是做一個空的[]，在push，這裡是直接寫進去 
     let POs = [[
         "HAWB",                      //A
         "單號",                      //B
         "寄件人",                    //C
         "寄件地址",                  //D
         "收件人",                    //E
         "收件地址",                  //F
         "PKG",                      //G
         "內容物",                    //H
         "件數",                      //I
         "公斤數",                    //J
         "報關價值",                  //K
         "Remark",                   //L
         "consignee City",           //M
         "Consignee Region",         //N
         "Consignee ZIP Code",       //O
         "Contact Person",           //P
         "連絡電話",                  //Q
         "Personal ID / KTP",        //R
         "Reference Number",         //S
         "DOX/SPX",                  //T
         "CC/PP",                    //U
         "CC Amount",                //V
         "Currency"                  //W
        ]];
      
      
      var PoInfo=this.state.MOPO;

   
      Object.keys(PoInfo).forEach(function(key) {
        var po = PoInfo[key];

        if (typeof po.內容物 =='string'){
          let poArray = [
            '',                             //HAWB
            po.快遞單號,                     //單號
            po.寄件人姓名,                   //寄件人
            po.寄件地址,                     //寄件地址
            po.收件人姓名,                   //收件人
            po.收件地址,                     //收件地址
            "",                             //PKG
            po.內容物,                      //內容物
            po.總箱數,                      //件數
            Number(po['總重量-公斤']),               //公斤數
            po['報關總值'],         //報關價值
            "",                             //Remark
            "",                             //consignee City
            "MAC",                        //Consignee Region
            po.收件人郵遞區號,               //Consignee ZIP Code
            po.收件人姓名,                      //Contact Person
            po.收件連絡電話,                //連絡電話
            "",                            //Personal ID / KTP
            "",                            //Reference Number
            "SPX",                            //DOX/SPX
            "PP",                            //CC/PP
            "",                            //CC Amount
            "",                         //Currency
          ];
          //console.log(poArray);
          POs.push(poArray);

        }else{

              var size = Object.keys(po.內容物).length;
              if(size===1){
                  let poArray = [
                    '',                             //HAWB
                    po.快遞單號,                     //單號
                    po.寄件人姓名,                   //寄件人
                    po.寄件地址,                     //寄件地址
                    po.收件人姓名,                   //收件人
                    po.收件地址,                     //收件地址
                    "",                             //PKG
                    po['內容物'][0].內容物,          //內容物
                    po['內容物'][0].件數,            //件數
                    po['總重量-公斤'],               //公斤數
                    po['報關總值'],         //報關價值
                    "",                             //Remark
                    "",                             //consignee City
                    "MAC",                        //Consignee Region
                    po.收件人郵遞區號,               //Consignee ZIP Code
                    po.連絡人,                      //Contact Person
                    po.收件連絡電話,                //連絡電話
                    "",                            //Personal ID / KTP
                    "",                            //Reference Number
                    "",                            //DOX/SPX
                    "",                            //CC/PP
                    "",                            //CC Amount
                    "",                         //Currency
                  ];
                  //console.log(poArray);
                  POs.push(poArray);
            }else{
              let poArray = [
                    '',                             //HAWB
                    po.快遞單號,                     //單號
                    po.寄件人姓名,                   //寄件人
                    po.寄件地址,                     //寄件地址
                    po.收件人姓名,                   //收件人
                    po.收件地址,                     //收件地址
                    "",                             //PKG
                    po['內容物'][0].內容物,                      //內容物
                    po['內容物'][0].件數,                             //件數
                    po['總重量-公斤'],                      //公斤數
                    po['報關總值'],         //報關價值
                    "",                             //Remark
                    "",                             //consignee City
                    "MAC",                        //Consignee Region
                    po.收件人郵遞區號,               //Consignee ZIP Code
                    po.連絡人,                      //Contact Person
                    po.收件連絡電話,                //連絡電話
                    "",                            //Personal ID / KTP
                    "",                            //Reference Number
                    "",                            //DOX/SPX
                    "",                            //CC/PP
                    "",                            //CC Amount
                    "",                         //Currency
              ];

              POs.push(poArray);
              var i;
              for(i=1;i<size;i++){

                let poArray = [
                  "",
                  po.快遞單號,
                  "",
                  "",
                  "",
                  "",
                  "",
                  po['內容物'][i].內容物,
                  po['內容物'][i].件數,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  ""
                ];
                POs.push(poArray);
              }
            }

        }

        
        

      });

     //創造一張新的表
     const wb = XLSX.utils.book_new();
     //下面這句就是把POs放進我們的sheet裡面
     const wsAll = XLSX.utils.aoa_to_sheet(POs);
     //把Sheet放進我們的Excel檔案裡面
     XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

     //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
     var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
     //利用saveAs就可以把這個檔案存出來
     saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.Today}-${this.state.CustomerNumber}-MO上傳公司系統訂單.xlsx`);
     //把表命名做出來
     //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-上傳公司系統訂單.xlsx`);


  }



  ExportHKFile(){
    let POs = [];
      let title=[
          "跨境通提單號碼",              //A
          "訂單號碼",          //B
          "件數",                  //C
          "收件人",              //D
          "地址",           //E
          "電話",        //F
          "內容",               //G
          "重量",    //H
          "價值 NT$",            //I
          
          
        ];

       POs.push(title);

       
       var PoInfo=this.state.HKPO;
       Object.keys(PoInfo).forEach(function(key) {
             var po = PoInfo[key];
             //console.log(typeof po.內容物);
             
             if (typeof po.內容物 =='string'){
                  let poArray = [
                    po.快遞單號,                 
                    po.訂單單號,                 
                    po.總箱數, 
                    po.收件人姓名,
                    po.收件地址,
                    po.收件連絡電話,
                    po.內容物,
                    Number(po['總重量-公斤']),                  
                    po['報關總值'],     
                                        
                   
                  ];
                  POs.push(poArray);
              }else{
                  
              }
    
     });

      //創造一張新的表
      const wb = XLSX.utils.book_new();
      //下面這句就是把POs放進我們的sheet裡面
      const wsAll = XLSX.utils.aoa_to_sheet(POs);
      //把Sheet放進我們的Excel檔案裡面
      XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

      //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
      //利用saveAs就可以把這個檔案存出來
      saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.Today}-${this.state.CustomerNumber}-HK2訂單.xlsx`);
      
      //下面這句語法是xlsx比較簡單的下載語法，不能選擇儲存路徑
      //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-SG2訂單.xlsx`);

  }
  //下面這個是要把資料導出，丟公司系統
  ExportHKSystemFile(){
    //以下這是一個直接內嵌的寫法，上面的是做一個空的[]，在push，這裡是直接寫進去 
    let POs = [[
        "HAWB",                      //A
        "單號",                      //B
        "寄件人",                    //C
        "寄件地址",                  //D
        "收件人",                    //E
        "收件地址",                  //F
        "PKG",                      //G
        "內容物",                    //H
        "件數",                      //I
        "公斤數",                    //J
        "報關價值",                  //K
        "Remark",                   //L
        "consignee City",           //M
        "Consignee Region",         //N
        "Consignee ZIP Code",       //O
        "Contact Person",           //P
        "連絡電話",                  //Q
        "Personal ID / KTP",        //R
        "Reference Number",         //S
        "DOX/SPX",                  //T
        "CC/PP",                    //U
        "CC Amount",                //V
        "Currency"                  //W
       ]];
     
     
     var PoInfo=this.state.HKPO;

  
     Object.keys(PoInfo).forEach(function(key) {
       var po = PoInfo[key];

       if (typeof po.內容物 =='string'){
         let poArray = [
           '',                             //HAWB
           po.快遞單號,                     //單號
           po.寄件人姓名,                   //寄件人
           po.寄件地址,                     //寄件地址
           po.收件人姓名,                   //收件人
           po.收件地址,                     //收件地址
           "",                             //PKG
           po.內容物,                      //內容物
           po.總箱數,                      //件數
           Number(po['總重量-公斤']),               //公斤數
           po['報關總值'],         //報關價值
           "",                             //Remark
           "",                             //consignee City
           "HKG",                        //Consignee Region
           po.收件人郵遞區號,               //Consignee ZIP Code
           po.收件人姓名,                      //Contact Person
           po.收件連絡電話,                //連絡電話
           "",                            //Personal ID / KTP
           "",                            //Reference Number
           "SPX",                            //DOX/SPX
           "PP",                            //CC/PP
           "",                            //CC Amount
           "",                         //Currency
          ];
         //console.log(poArray);
         POs.push(poArray);

       }else{

             var size = Object.keys(po.內容物).length;
             if(size===1){
                 let poArray = [
                   '',                             //HAWB
                   po.快遞單號,                     //單號
                   po.寄件人姓名,                   //寄件人
                   po.寄件地址,                     //寄件地址
                   po.收件人姓名,                   //收件人
                   po.收件地址,                     //收件地址
                   "",                             //PKG
                   po['內容物'][0].內容物,          //內容物
                   po['內容物'][0].件數,            //件數
                   po['總重量-公斤'],               //公斤數
                   po['報關總值'],         //報關價值
                   "",                             //Remark
                   "",                             //consignee City
                   "HKG",                        //Consignee Region
                   po.收件人郵遞區號,               //Consignee ZIP Code
                   po.連絡人,                      //Contact Person
                   po.收件連絡電話,                //連絡電話
                   "",                            //Personal ID / KTP
                   "",                            //Reference Number
                   "",                            //DOX/SPX
                   "",                            //CC/PP
                   "",                            //CC Amount
                   "",                         //Currency
                 ];
                 //console.log(poArray);
                 POs.push(poArray);
           }else{
             let poArray = [
                   '',                             //HAWB
                   po.快遞單號,                     //單號
                   po.寄件人姓名,                   //寄件人
                   po.寄件地址,                     //寄件地址
                   po.收件人姓名,                   //收件人
                   po.收件地址,                     //收件地址
                   "",                             //PKG
                   po['內容物'][0].內容物,                      //內容物
                   po['內容物'][0].件數,                             //件數
                   po['總重量-公斤'],                      //公斤數
                   po['報關總值'],         //報關價值
                   "",                             //Remark
                   "",                             //consignee City
                   "HKG",                        //Consignee Region
                   po.收件人郵遞區號,               //Consignee ZIP Code
                   po.連絡人,                      //Contact Person
                   po.收件連絡電話,                //連絡電話
                   "",                            //Personal ID / KTP
                   "",                            //Reference Number
                   "",                            //DOX/SPX
                   "",                            //CC/PP
                   "",                            //CC Amount
                   "",                         //Currency
             ];

             POs.push(poArray);
             var i;
             for(i=1;i<size;i++){

               let poArray = [
                 "",
                 po.快遞單號,
                 "",
                 "",
                 "",
                 "",
                 "",
                 po['內容物'][i].內容物,
                 po['內容物'][i].件數,
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 "",
                 ""
               ];
               POs.push(poArray);
             }
           }

       }

       
       

     });

    //創造一張新的表
    const wb = XLSX.utils.book_new();
    //下面這句就是把POs放進我們的sheet裡面
    const wsAll = XLSX.utils.aoa_to_sheet(POs);
    //把Sheet放進我們的Excel檔案裡面
    XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

    //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
    var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
    //利用saveAs就可以把這個檔案存出來
    saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.Today}-${this.state.CustomerNumber}-HK上傳公司系統訂單.xlsx`);
    //把表命名做出來
    //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-上傳公司系統訂單.xlsx`);


  }


  ExportJPFile(){
    let POs = [];
      let title=[
          "跨境通提單號碼",              //A
          "訂單號碼",          //B
          "件數",                  //C
          "收件人",              //D
          "地址",           //E
          "電話",        //F
          "內容",               //G
          "重量",    //H
          "價值 NT$",            //I
          
          
        ];

       POs.push(title);

       
       var PoInfo=this.state.JPPO;
       Object.keys(PoInfo).forEach(function(key) {
             var po = PoInfo[key];
             //console.log(typeof po.內容物);
             
             if (typeof po.內容物 =='string'){
                  let poArray = [
                    po.快遞單號,                 
                    po.訂單單號,                 
                    po.總箱數, 
                    po.收件人姓名,
                    po.收件地址,
                    po.收件連絡電話,
                    po.內容物,
                    Number(po['總重量-公斤']),                  
                    po['報關總值'],     
                                        
                   
                  ];
                  POs.push(poArray);
              }else{
                  
              }
    
     });

      //創造一張新的表
      const wb = XLSX.utils.book_new();
      //下面這句就是把POs放進我們的sheet裡面
      const wsAll = XLSX.utils.aoa_to_sheet(POs);
      //把Sheet放進我們的Excel檔案裡面
      XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

      //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
      //利用saveAs就可以把這個檔案存出來
      saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.Today}-${this.state.CustomerNumber}-JP2訂單.xlsx`);
      
      //下面這句語法是xlsx比較簡單的下載語法，不能選擇儲存路徑
      //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-SG2訂單.xlsx`);

  }




  render() {
    var MyComponent='C'+this.state.CustomerNumber;
    var ComponentToRender='';

    if( Components.hasOwnProperty(MyComponent)){
      ComponentToRender = Components[MyComponent];
    }else{
      ComponentToRender = Components['CAdminStandard'];
    };
    


    const panes = [
      { menuItem: (
        <Menu.Item style={{ 'font-size':'30px'}}>
         <h1>後台控制儀表板</h1>
        </Menu.Item>
        ), 

      render: () => 
        <Tab.Pane >
            <div style={{ padding: '5em 5em','font-size':'26px','font-weight':'bold','background-color': 'rgb(244, 173, 66)'}}>
            <Grid columns={2} textAlign='center'  divided='vertically' >
              <Grid.Row divided>
                <Grid.Column style={{ padding: '0em 0em 3em 3em' }}>
                  <Label color='red' tag style={{ 'font-size':'30px'}}>
                    韓國訂單狀況
                  </Label>
                  
                </Grid.Column>
                <Grid.Column textAlign='left'>
                  {this.state.KRPO?
                    <div>
                            
                    </div>
                    :
                    <div>目前沒有訂單</div>
                  }
                </Grid.Column>      
              </Grid.Row>

              <Grid.Row divided>
                <Grid.Column style={{ padding: '0em 0em 3em 3em' }}>
                  <Label color='olive' tag style={{ 'font-size':'30px'}}>
                  新加坡訂單狀況
                  </Label>
                  
                </Grid.Column>
                <Grid.Column textAlign='left'>
                  {this.state.SGPO?
                    <List divided >
                      {/*
                      <List.Item>
                          <Button size='large' color='yellow' onClick={this.SGLablePrint}>下載新加坡標籤-跨境通版</Button>
                      </List.Item>
                      */}
                      <List.Item>
                          <Button size='large' color='yellow' onClick={this.ExportSGFile}>下載新加坡版本Excel</Button>
                      </List.Item>
                      <List.Item>
                          <Button size='large' color='red' onClick={this.ExportSGSystemFile}>下載上傳公司系統Excel</Button>
                      </List.Item>
                      <List.Item>
                          
                      </List.Item>
                      
                    </List>
                    
                    :
                    <div>目前沒有訂單</div>
                  }
                </Grid.Column>      
              </Grid.Row>

              <Grid.Row divided>
                <Grid.Column style={{ padding: '0em 0em 3em 3em' }}>
                  <Label color='blue' tag style={{ 'font-size':'30px'}}>
                    馬來西亞訂單狀況
                  </Label>
                  
                </Grid.Column>
                <Grid.Column textAlign='left'>
                {this.state.MYPO?
                    <List divided >
                      {/*
                      <List.Item>
                          <Button size='large' color='yellow' onClick={this.MYLablePrint}>下載馬來西亞標籤-跨境通版</Button>
                      </List.Item>
                      */}
                      <List.Item>
                          <Button size='large' color='yellow' onClick={this.ExportMYFile}>下載馬來西亞版本Excel</Button>
                      </List.Item>
                      <List.Item>
                          <Button size='large' color='red' onClick={this.ExportMYSystemFile}>下載上傳公司系統Excel</Button>
                      </List.Item>
                      
                    </List>
                    
                    :
                    <div>目前沒有訂單</div>
                  }
                </Grid.Column>      
              </Grid.Row>

              <Grid.Row divided>
                <Grid.Column style={{ padding: '0em 0em 3em 3em' }}>
                  <Label color='pink' tag style={{ 'font-size':'30px'}}>
                    印尼訂單狀況
                  </Label>
                  
                </Grid.Column>
                <Grid.Column textAlign='left'>
                  {this.state.IDPO?
                    <div>
                            
                    </div>
                    :
                    <div>目前沒有訂單</div>
                  }
                </Grid.Column>      
              </Grid.Row>

              <Grid.Row divided>
                <Grid.Column style={{ padding: '0em 0em 3em 3em' }}>
                  <Label color='yellow' tag style={{ 'font-size':'30px'}}>
                    澳門訂單狀況
                  </Label>
                  
                </Grid.Column>
                <Grid.Column textAlign='left'>
                  {this.state.MOPO?
                    <List divided >
                    {/*
                    <List.Item>
                        <Button size='large' color='yellow' onClick={this.MYLablePrint}>下載馬來西亞標籤-跨境通版</Button>
                    </List.Item>
                    
                    <List.Item>
                        <Button size='large' color='yellow' onClick={this.ExportMOFile}>下載澳門版本Excel</Button>
                    </List.Item>
                    */}
                    <List.Item>
                          <Button size='large' color='yellow' onClick={this.ExportMOFile}>下載澳門版本Excel</Button>
                      </List.Item>
                    <List.Item>
                        <Button size='large' color='red' onClick={this.ExportMOSystemFile}>下載上傳公司系統Excel</Button>
                    </List.Item>
                    
                  </List>
                    :
                    <div>目前沒有訂單</div>
                  }
                </Grid.Column>      
              </Grid.Row>

              <Grid.Row divided>
                <Grid.Column style={{ padding: '0em 0em 3em 3em' }}>
                  <Label color='orange' tag style={{ 'font-size':'30px'}}>
                    香港訂單狀況
                  </Label>
                  
                </Grid.Column>
                <Grid.Column textAlign='left'>
                  {this.state.HKPO?
                    <List divided >
                    {/*
                    <List.Item>
                        <Button size='large' color='yellow' onClick={this.MYLablePrint}>下載馬來西亞標籤-跨境通版</Button>
                    </List.Item>
                    
                    <List.Item>
                        <Button size='large' color='yellow' onClick={this.ExportMOFile}>下載澳門版本Excel</Button>
                    </List.Item>
                    */}
                    <List.Item>
                          <Button size='large' color='yellow' onClick={this.ExportHKFile}>下載香港版本Excel</Button>
                      </List.Item>
                    <List.Item>
                        <Button size='large' color='red' onClick={this.ExportHKSystemFile}>下載上傳公司系統Excel</Button>
                    </List.Item>
                    
                  </List>
                    :
                    <div>目前沒有訂單</div>
                  }
                </Grid.Column>      
              </Grid.Row>

              <Grid.Row divided>
                <Grid.Column style={{ padding: '0em 0em 3em 3em' }}>
                  <Label color='olive' tag style={{ 'font-size':'30px'}}>
                     日本訂單狀況
                  </Label>
                  
                </Grid.Column>
                <Grid.Column textAlign='left'>
                  {this.state.JPPO?
                    <List divided >
                    
                    <List.Item>
                          <Button size='large' color='yellow' onClick={this.ExportJPFile}>下載日本版本Excel</Button>
                    </List.Item>
                    {/*
                    <List.Item>
                        <Button size='large' color='red' onClick={this.ExportHKSystemFile}>下載上傳公司系統Excel</Button>
                    </List.Item>
                    */}
                  </List>
                    :
                    <div>目前沒有訂單</div>
                  }
                </Grid.Column>      
              </Grid.Row>


            
            </Grid>
            </div>
        </Tab.Pane> 
      },
      { menuItem: (
        <Menu.Item style={{ 'font-size':'30px'}}>
         <h1>客戶前台</h1>
        </Menu.Item>
        ),
         render: () => <Tab.Pane><ComponentToRender CustomerNumber={this.state.CustomerNumber}/></Tab.Pane> },
     
    ]





    return (
      <div  style={{ padding: '0em 5em' }}>
       
       <h2>今天是{this.state.Today}</h2>
       <h1>您目前已經進入{this.state.CustomerNumber}的訂單處理儀表板</h1>
       
       <Tab panes={panes} style={{'background-color': 'rgb(236, 147, 40)' }}/>
      
        
        
      </div>
    );
  }
}

export default App;
