import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import {todayTitle,savePO,tellAdmin,saveDeliveryNumber} from './helper/SaveToDatabase';
import {LogoImg,textToBase64Barcode} from './helper/Helper';
import pdfmake from 'pdfmake/build/pdfmake'
import pdffonts from 'pdfmake/build/vfs_fonts';
//import T80071 from './80071T';
import {Button,Input, Header,Table} from 'semantic-ui-react';
import { ref} from '../firebase/firebaseConfig';
import 'semantic-ui-css/semantic.min.css';
var JsBarcode = require('jsbarcode');

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      //這個每個客人都要改
      'customerNumber':'DEC',
      'SGPO':[],
      'finalDeliveryNumber':'',
      'cumulatedDeliveryNumber':'',
      'todayTitle':'',
      'nation':'',
    };
    this.SGImportExcel=this.SGImportExcel.bind(this);
    this.MYImportExcel=this.MYImportExcel.bind(this);
    this.MOImportExcel=this.MOImportExcel.bind(this);
    this.UniversalImportExcel=this.UniversalImportExcel.bind(this);
    this.handleSGLablePrint=this.handleSGLablePrint.bind(this);
    this.handleMYLablePrint=this.handleMYLablePrint.bind(this);
    this.handleMOLablePrint=this.handleMOLablePrint.bind(this);
    this.handleUniversalLablePrint=this.handleUniversalLablePrint.bind(this);
    
  }

  componentDidMount (){
    //var userid = firebaseAuth.currentUser.uid;
    let today=todayTitle()[0];
    let year=todayTitle()[1];
    let month=todayTitle()[2];
    let day=todayTitle()[3];
    this.setState({
      'todayTitle':today,
      'year':year,
      'month':month,
      'day':day
    })

   
  }


  handleSGLablePrint(){
    //先把我們要處理的資料倒進來，所以隨著訂單不同，要改
    let goodData=this.state['SGPO'];
    let Nation='SG';
    let Currency='SGD$';
    
    
    pdfmake.vfs = pdffonts.pdfMake.vfs;
    

    //把印製的Label格式準備好
    var LabelArr = [];

    //把最後一張訂單挑出來，因為這張訂單要特別處理，不要接下一頁
    const keys = Object.keys(goodData);
    var last=keys.pop();

    

    //開始處理每張訂單，導進標籤印製群組
    Object.keys(goodData).forEach((key)=> {
      var po=goodData[key];
      
      //logo是跨境通的logo，在這裡先導進來
      var logo=LogoImg();

      //下面這句是避免原廠導入格式不乾淨，全部轉成Number後面好處理
      var LabelNumber=Number(po.總箱數);
      
      
      
        //新加坡的標籤數需求就是總箱數再加一張，就這麼簡單
          for(var i=0;i<LabelNumber+1;i++){
            //如果是最後一張單的最後一張標，就不要再下一頁了，這是唯一需要特別處理的。
            if(key===last && i==LabelNumber){         
              let Label=[
                { table: {
                  widths: ['auto', '*', '*', '*', '*', '*'],
                  //下面是要做一開始的logo、barcode、和國碼標
                  body: [
                    [
                      {
                      // you'll most often use dataURI images on the browser side
                      // if no width/height/fit is provided, the original size will be used
                      image:logo,
                      width:100, 
                      height:20,
                      alignment: 'center',
                      colSpan: 3},
                      '',
                      '',
                      { rowSpan: 2,
                        colSpan: 3,
                        text: po.國別,
                        style: 'head',  
                        width:100, 
                        alignment: 'center',          
                      },
                      '',
                      ''

                    ],
                    [{ image:po.dataURL,
                      colSpan: 3,
                      width:140, 
                      height:50,
                      alignment: 'center',
                    },
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{ text: 'MAWB:'},
                    {text: po.MAWB,
                      colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{ text: 'PO:'},
                    {text:po.訂單單號,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper:'},
                    {text: po.寄件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee:'},
                    {text: po.收件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'TEL:'},
                    {text: po.收件連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Address:',rowSpan: 2},
                    {text: po.收件地址,rowSpan: 2,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{},
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Declared Value:'},
                    {text: Currency+po['報關總值'],colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Weight:'},
                    //KGS前面後一格，看起來比較好看
                    {text: po['總重量-公斤']+'　KGS',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{text: 'Description:'},
                    {text: po.內容物,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                  ]
                }},     
              ];

              LabelArr.push(Label);

            }else{
             //一般情況下都是跑下面這張標籤        
              let Label=[
                { table: {
                  widths: ['auto', '*', '*', '*', '*', '*'],
                  //下面是要做一開始的logo、barcode、和國碼標
                  body: [
                    [
                      {
                      // you'll most often use dataURI images on the browser side
                      // if no width/height/fit is provided, the original size will be used
                      image:logo,
                      width:100, 
                      height:20,
                      alignment: 'center',
                      colSpan: 3},
                      '',
                      '',
                      { rowSpan: 2,
                        colSpan: 3,
                        text: po.國別,
                        style: 'head',  
                        width:100, 
                        alignment: 'center',          
                      },
                      '',
                      ''

                    ],
                    [{ image:po.dataURL,
                      colSpan: 3,
                      width:140, 
                      height:50,
                      alignment: 'center',
                    },
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{ text: 'MAWB:'},
                    {text: po.MAWB,
                      colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{ text: 'PO:'},
                    {text:po.訂單單號,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper:'},
                    {text: po.寄件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee:'},
                    {text: po.收件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'TEL:'},
                    {text: po.收件連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Address:',rowSpan: 2},
                    {text: po.收件地址,rowSpan: 2,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{},
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Declared Value:'},
                    {text: Currency+po['報關總值'],colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Weight:'},
                    {text: po['總重量-公斤']+' KGS',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{text: 'Description:'},
                    {text: po.內容物,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                  ],
                
                  },
                  pageBreak: 'after'
                }, 
              
              ];

              LabelArr.push(Label);
            };
          };

    });

    
    pdfmake.fonts = {
     ch: {
     normal: 'kaiu.ttf',
     bold: 'kaiu.ttf',
     italics: 'kaiu.ttf',
     bolditalics: 'kaiu.ttf'
     }};

    var docDefinition = {
        content:LabelArr,
        defaultStyle: {
        font: 'ch',
        fontSize: 11
        } ,
        styles: {
          head: {
            
            fontSize: 66,
            bold: true
          },

        },

        // a string or { width: number, height: number }
        // 下面是10*10的標籤設計
        pageSize: { width: 283, height: 283},
        

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 3, 3,3, 0 ],
      };
     
      let labeltitle="跨境通標籤-"+Nation+this.state.todayTitle+'.pdf';
      
      
      ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.todayTitle+'/cumulatedDeliveryNumber').once('value', (snapshot)=> {
        
        if(snapshot.val()==this.state.cumulatedDeliveryNumber){
           pdfmake.createPdf(docDefinition).download(labeltitle);
           //pdfmake.createPdf(docDefinition).open();
        }


      })
      

      
      

  }

  handleMYLablePrint(){
    //先把我們要處理的資料倒進來，所以隨著訂單不同，要改
    //馬來西亞用MYR
    //起單印兩張
    /*
    要加印
    [{ text: 'A/C NO:'},
    {text: 'N6900038',
      colSpan: 5},
    */
   
    let goodData=this.state['MYPO'];
    let Nation='MY';
    let Currency='MYR';
    
    
    pdfmake.vfs = pdffonts.pdfMake.vfs;
    

    //把印製的Label格式準備好
    var LabelArr = [];

    //把最後一張訂單挑出來，因為這張訂單要特別處理，不要接下一頁
    const keys = Object.keys(goodData);
    var last=keys.pop();

    

    //開始處理每張訂單，導進標籤印製群組
    Object.keys(goodData).forEach((key)=> {
      var po=goodData[key];
      
      //logo是跨境通的logo，在這裡先導進來
      var logo=LogoImg();

      //下面這句是避免原廠導入格式不乾淨，全部轉成Number後面好處理
      var LabelNumber=Number(po.總箱數);
      
      
      
        //馬來西亞的標籤數需求就是總箱數再加1張，就這麼簡單
          for(var i=0;i<LabelNumber+1;i++){
            //如果是最後一張單的最後一張標，就不要再下一頁了，這是唯一需要特別處理的。
            if(key===last && i==LabelNumber){         
              let Label=[
                { table: {
                  widths: ['auto', '*', '*', '*', '*', '*'],
                  //下面是要做一開始的logo、barcode、和國碼標
                  body: [
                    [
                      {
                      // you'll most often use dataURI images on the browser side
                      // if no width/height/fit is provided, the original size will be used
                      image:logo,
                      width:100, 
                      height:20,
                      alignment: 'center',
                      colSpan: 3},
                      '',
                      '',
                      { rowSpan: 2,
                        colSpan: 3,
                        text: po.國別,
                        style: 'head',  
                        width:100, 
                        alignment: 'center',          
                      },
                      '',
                      ''

                    ],
                    [{ image:po.dataURL,
                      colSpan: 3,
                      width:140, 
                      height:50,
                      alignment: 'center',
                    },
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],
                    //這是馬來西亞標籤特別要加的
                    [{ text: 'A/C NO:'},
                    {text: 'N6900038',
                      colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{ text: 'PO:'},
                    {text:po.訂單單號,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper:'},
                    {text: po.寄件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee:'},
                    {text: po.收件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'TEL:'},
                    {text: po.收件連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Address:',rowSpan: 2},
                    {text: po.收件地址,rowSpan: 2,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{},
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Declared Value:'},
                    {text: Currency+po['報關總值'],colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Weight:'},
                    //KGS前面後一格，看起來比較好看
                    {text: po['總重量-公斤']+' KGS',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{text: 'Description:'},
                    {text: po.內容物,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                  ]
                }},     
              ];

              LabelArr.push(Label);

            }else{
             //一般情況下都是跑下面這張標籤        
              let Label=[
                { table: {
                  widths: ['auto', '*', '*', '*', '*', '*'],
                  //下面是要做一開始的logo、barcode、和國碼標
                  body: [
                    [
                      {
                      // you'll most often use dataURI images on the browser side
                      // if no width/height/fit is provided, the original size will be used
                      image:logo,
                      width:100, 
                      height:20,
                      alignment: 'center',
                      colSpan: 3},
                      '',
                      '',
                      { rowSpan: 2,
                        colSpan: 3,
                        text: po.國別,
                        style: 'head',  
                        width:100, 
                        alignment: 'center',          
                      },
                      '',
                      ''

                    ],
                    [{ image:po.dataURL,
                      colSpan: 3,
                      width:140, 
                      height:50,
                      alignment: 'center',
                    },
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    //這是馬來西亞標籤特別要加的
                    [{ text: 'A/C NO:'},
                    {text: 'N6900038',
                      colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{ text: 'PO:'},
                    {text:po.訂單單號,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper:'},
                    {text: po.寄件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee:'},
                    {text: po.收件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'TEL:'},
                    {text: po.收件連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Address:',rowSpan: 2},
                    {text: po.收件地址,rowSpan: 2,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{},
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Declared Value:'},
                    {text: Currency+po['報關總值'],colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Weight:'},
                    {text: po['總重量-公斤']+' KGS',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{text: 'Description:'},
                    {text: po.內容物,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                  ],
                
                  },
                  pageBreak: 'after'
                }, 
              
              ];

              LabelArr.push(Label);
            };
          };

    });

    
    pdfmake.fonts = {
     ch: {
     normal: 'kaiu.ttf',
     bold: 'kaiu.ttf',
     italics: 'kaiu.ttf',
     bolditalics: 'kaiu.ttf'
     }};

    var docDefinition = {
        content:LabelArr,
        defaultStyle: {
        font: 'ch',
        fontSize: 11
        } ,
        styles: {
          head: {
            
            fontSize: 66,
            bold: true
          },

        },

        // a string or { width: number, height: number }
        // 下面是10*10的標籤設計
        pageSize: { width: 283, height: 283},
        

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 3, 3,3, 0 ],
      };
     
      let labeltitle="跨境通標籤-"+Nation+this.state.todayTitle+'.pdf';
      
      
      ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.todayTitle+'/cumulatedDeliveryNumber').once('value', (snapshot)=> {
        
        if(snapshot.val()==this.state.cumulatedDeliveryNumber){
           pdfmake.createPdf(docDefinition).download(labeltitle);
           //pdfmake.createPdf(docDefinition).open();
        }


      })
      

      
      

  }

  handleMOLablePrint(){
    //先把我們要處理的資料倒進來，所以隨著訂單不同，要改
    //澳門用台幣計價
    //起單印三張
    let goodData=this.state['MOPO'];
    let Nation='MO';
    let Currency='NTD$';
    
    
    pdfmake.vfs = pdffonts.pdfMake.vfs;
    

    //把印製的Label格式準備好
    var LabelArr = [];

    //把最後一張訂單挑出來，因為這張訂單要特別處理，不要接下一頁
    const keys = Object.keys(goodData);
    var last=keys.pop();

    

    //開始處理每張訂單，導進標籤印製群組
    Object.keys(goodData).forEach((key)=> {
      var po=goodData[key];
      
      //logo是跨境通的logo，在這裡先導進來
      var logo=LogoImg();

      //下面這句是避免原廠導入格式不乾淨，全部轉成Number後面好處理
      var LabelNumber=Number(po.總箱數);
      
      
      
        //澳門的標籤數需求就是總箱數再加2張，就這麼簡單
          for(var i=0;i<LabelNumber+2;i++){
            //如果是最後一張單的最後一張標，就不要再下一頁了，這是唯一需要特別處理的。
            if(key===last && i==LabelNumber+1){         
              let Label=[
                { table: {
                  widths: ['auto', '*', '*', '*', '*', '*'],
                  //下面是要做一開始的logo、barcode、和國碼標
                  body: [
                    [
                      {
                      // you'll most often use dataURI images on the browser side
                      // if no width/height/fit is provided, the original size will be used
                      image:logo,
                      width:100, 
                      height:20,
                      alignment: 'center',
                      colSpan: 3},
                      '',
                      '',
                      { rowSpan: 2,
                        colSpan: 3,
                        text: po.國別,
                        style: 'head',  
                        width:100, 
                        alignment: 'center',          
                      },
                      '',
                      ''

                    ],
                    [{ image:po.dataURL,
                      colSpan: 3,
                      width:140, 
                      height:50,
                      alignment: 'center',
                    },
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{ text: 'MAWB:'},
                    {text: po.MAWB,
                      colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{ text: 'PO:'},
                    {text:po.訂單單號,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper:'},
                    {text: po.寄件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee:'},
                    {text: po.收件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'TEL:'},
                    {text: po.收件連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Address:',rowSpan: 2},
                    {text: po.收件地址,rowSpan: 2,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{},
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Declared Value:'},
                    {text: Currency+po['報關總值'],colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Weight:'},
                    //KGS前面後一格，看起來比較好看
                    {text: po['總重量-公斤']+' KGS',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{text: 'Description:'},
                    {text: po.內容物,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                  ]
                }},     
              ];

              LabelArr.push(Label);

            }else{
             //一般情況下都是跑下面這張標籤        
              let Label=[
                { table: {
                  widths: ['auto', '*', '*', '*', '*', '*'],
                  //下面是要做一開始的logo、barcode、和國碼標
                  body: [
                    [
                      {
                      // you'll most often use dataURI images on the browser side
                      // if no width/height/fit is provided, the original size will be used
                      image:logo,
                      width:100, 
                      height:20,
                      alignment: 'center',
                      colSpan: 3},
                      '',
                      '',
                      { rowSpan: 2,
                        colSpan: 3,
                        text: po.國別,
                        style: 'head',  
                        width:100, 
                        alignment: 'center',          
                      },
                      '',
                      ''

                    ],
                    [{ image:po.dataURL,
                      colSpan: 3,
                      width:140, 
                      height:50,
                      alignment: 'center',
                    },
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{ text: 'MAWB:'},
                    {text: po.MAWB,
                      colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{ text: 'PO:'},
                    {text:po.訂單單號,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper:'},
                    {text: po.寄件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee:'},
                    {text: po.收件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'TEL:'},
                    {text: po.收件連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Address:',rowSpan: 2},
                    {text: po.收件地址,rowSpan: 2,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{},
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Declared Value:'},
                    {text: Currency+po['報關總值'],colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Weight:'},
                    {text: po['總重量-公斤']+' KGS',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{text: 'Description:'},
                    {text: po.內容物,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                  ],
                
                  },
                  pageBreak: 'after'
                }, 
              
              ];

              LabelArr.push(Label);
            };
          };

    });

    
    pdfmake.fonts = {
     ch: {
     normal: 'kaiu.ttf',
     bold: 'kaiu.ttf',
     italics: 'kaiu.ttf',
     bolditalics: 'kaiu.ttf'
     }};

    var docDefinition = {
        content:LabelArr,
        defaultStyle: {
        font: 'ch',
        fontSize: 11
        } ,
        styles: {
          head: {
            
            fontSize: 66,
            bold: true
          },

        },

        // a string or { width: number, height: number }
        // 下面是10*10的標籤設計
        pageSize: { width: 283, height: 283},
        

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 3, 3,3, 0 ],
      };
     
      let labeltitle="跨境通標籤-"+Nation+this.state.todayTitle+'.pdf';
      
      
      ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.todayTitle+'/cumulatedDeliveryNumber').once('value', (snapshot)=> {
        
        if(snapshot.val()==this.state.cumulatedDeliveryNumber){
           pdfmake.createPdf(docDefinition).download(labeltitle);
           //pdfmake.createPdf(docDefinition).open();
        }


      })
      

      
      

  }



 

  SGImportExcel = file => {
    // 获取上传的文件对象
    const { files } = file.target;
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        // 以binary方式读取得到整份excel表格对象
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = []; // 存储获取到的数据

        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            break; // 如果除了取第一张表以外，還要其他的表，就取消注释这行
          }
        };

        //設定國別
        let nation='SG'
        let goodData=[];
        let cumulatedDeliveryNumber=0;
        
        //這邊是寫一個程式做貨幣轉換，目前新加坡匯率是訂22
        function TransactionToLocal(NTD){
          return Number((NTD/22).toFixed(1))
        };
        
        //整個判定過程就是分三類，普通類、1/n的第一箱、n/n的第n箱
        ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.todayTitle+'/cumulatedDeliveryNumber').once('value', (snapshot)=> {
                     
                 cumulatedDeliveryNumber=snapshot.val();
                 data.forEach((element)=> {                      
                      //透過件數特性的分析，開啟不同的處理方式，把有分箱的部分拿出來特別處理
                      if(element.件數==1){
                            //系統紀錄單號一律用客戶的訂單號碼，單號唯一，但是快遞單號可以改變
                           
                            let number=element.訂單號碼;
                            let date = new Date();
                        
                            //多了一張訂單，加1
                            cumulatedDeliveryNumber=cumulatedDeliveryNumber+1;
                            this.setState({'cumulatedDeliveryNumber':cumulatedDeliveryNumber});
                            saveDeliveryNumber(this.state.customerNumber,this.state.todayTitle,cumulatedDeliveryNumber);
                            
                           
                            goodData[number]=[];

                            goodData[number].快遞單號=nation+element.訂單號碼;
                            goodData[number].訂單單號=element.訂單號碼;

                            //date一定要做這個處理，要不然進不了realtime database
                            goodData[number].日期=date.toDateString();

                            //普仕特強調要這個寄件人名稱
                            if(element.寄件人){
                              goodData[number].寄件人姓名=element.寄件人;
                            }else{
                              goodData[number].寄件人姓名='Books.com.co Ltd';
                            }
                            
                            goodData[number].寄件地址="普仕特地址";
                            goodData[number].dataURL=textToBase64Barcode(goodData[number].快遞單號);

                            goodData[number].收件人姓名=element.收件人;
                            goodData[number].收件人郵遞區號='';
                            goodData[number].收件地址=element.地址;
                            goodData[number].收件連絡電話=element.電話;

                            goodData[number].國別=nation;

                            goodData[number].內容物=element.內容;
                            
                            goodData[number].總箱數=element.件數;
                            goodData[number]['總重量-公斤']=element.重量;
                            
                            goodData[number]['報關總值']=TransactionToLocal(element['價值 NT$']);
                            goodData[number].連絡人="";
                            
                  
                            //當你是希望可以每一張訂單去修改修正的時候，你就要一張一張訂單存，這樣才不會全部覆蓋掉。
                            savePO(this.state.customerNumber,this.state.todayTitle,goodData[number]);
                            
                      }else{
                            let piece=element.件數;

                            //因為普仕特的表達是'1/2'，所以用"/"split後，前段可以判斷這是第幾箱
                            let BoxNumber=piece.split('/')[0];
                            
                            ///因為普仕特的表達是'1/2'，所以用"/"split後，後段可以判斷一共幾箱
                            let TotalNumber=piece.split('/')[1];
                            
                            let number=element.訂單號碼;
                            
                            //下面兩個function是為了要做小數運算
                            function FloatMul(arg1, arg2){
                              var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
                              try { m += s1.split(".")[1].length; } catch (e) { }
                              try { m += s2.split(".")[1].length; } catch (e) { }
                              return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
                            }

                            function FloatAdd(arg1, arg2){
                              var r1, r2, m;
                              try { r1 = arg1.toString().split(".")[1].length; } catch (e) { r1 = 0; }
                              try { r2 = arg2.toString().split(".")[1].length; } catch (e) { r2 = 0; }
                              m = Math.pow(10, Math.max(r1, r2));
                              return (FloatMul(arg1, m) + FloatMul(arg2, m)) / m;
                            }

                            //第一箱的部分就先存成一個temp，因為要把後面箱數的資料都加總進來
                            if(BoxNumber==='1'){
                                  
                                  //多了一張訂單，加1
                                  cumulatedDeliveryNumber=cumulatedDeliveryNumber+1;
                                  this.setState({'cumulatedDeliveryNumber':cumulatedDeliveryNumber});
                                  saveDeliveryNumber(this.state.customerNumber,this.state.todayTitle,cumulatedDeliveryNumber);
                                                                
                                  let date = new Date();
                                  //建立一個訂單的格式，慢慢把資料倒進來  
                                  goodData[number]=[];

                                  goodData[number].快遞單號=nation+element.訂單號碼;
                                  goodData[number].訂單單號=element.訂單號碼;


                                  //date一定要做這個處理，要不然進不了realtime database
                                  goodData[number].日期=date.toDateString();

                                  //普仕特強調要這個寄件人名稱
                                  if(element.寄件人){
                                    goodData[number].寄件人姓名=element.寄件人;
                                  }else{
                                    goodData[number].寄件人姓名='Books.com.co Ltd';
                                  };
                                  goodData[number].寄件地址="普仕特地址";
                                  goodData[number].dataURL=textToBase64Barcode(goodData[number].快遞單號);

                                  goodData[number].收件人姓名=element.收件人;
                                  goodData[number].收件人郵遞區號='';
                                  goodData[number].收件地址=element.地址;
                                  goodData[number].收件連絡電話=element.電話;
              
                                  goodData[number].國別=nation;
                                  
                                  //因為後面還有內容物要加進來，所以我們要加空格，把它們隔開
                                  goodData[number].內容物=element.內容+' ';
                                  
                                  //這邊我們直接把split後得到的總箱數放進來
                                  goodData[number].總箱數=TotalNumber;
                                  goodData[number]['總重量-公斤']=element.重量;
                                  goodData[number]['報關總值']=TransactionToLocal(element['價值 NT$']);
                                  goodData[number].連絡人="";
                                  
                                  //因為後面還有資料要加進來，所以先暫存起來
                                  this.setState({temp:goodData[number]})
                            }else if(BoxNumber==TotalNumber){
                                  //現在代表碰到n箱的最後一箱了，所以要把訂單存起來了
                                  goodData[this.state.temp.訂單單號].內容物=goodData[this.state.temp.訂單單號].內容物+element.內容;
                                  goodData[this.state.temp.訂單單號]['總重量-公斤']=FloatAdd(goodData[this.state.temp.訂單單號]['總重量-公斤'],element.重量);
                                  goodData[this.state.temp.訂單單號]['報關總值']=FloatAdd(goodData[this.state.temp.訂單單號]['報關總值'],TransactionToLocal(element['價值 NT$']));
                                  
                                  //當你是希望可以每一張訂單去修改修正的時候，你就要一張一張訂單存，這樣才不會全部覆蓋掉。
                                  savePO(this.state.customerNumber,this.state.todayTitle,goodData[this.state.temp.訂單單號]);

                                  //把暫存紀錄delete掉
                                  this.setState({temp:''})
                            }else{
                                  //這個區段是專門處理第一箱和第n箱以外的其他箱數
                                  goodData[this.state.temp.訂單單號].內容物=goodData[this.state.temp.訂單單號].內容物+element.內容+' ';
                                  goodData[this.state.temp.訂單單號]['總重量-公斤']=FloatAdd(goodData[this.state.temp.訂單單號]['總重量-公斤'],element.重量);
                                  goodData[this.state.temp.訂單單號]['報關總值']=FloatAdd(goodData[this.state.temp.訂單單號]['報關總值'],TransactionToLocal(element['價值 NT$']));
                                  

                            }
                          
                      }
                      


                });
        })
           
           //要把國別記錄下來，這樣後面韓國、越南都可以好分類
           this.setState({[`${nation}PO`]: goodData, [`${nation}Upload`]:'success'});
                      
           //把最後單號存起來，留著後面用
           tellAdmin(this.state.customerNumber,nation,this.state.todayTitle);

      } catch (e) {
        // 这里可以抛出文件类型错误不正确的相关提示
        console.log('文件类型不正确');
        return;
      }
    };
    // 以Binary方式打开文件
    fileReader.readAsBinaryString(files[0]);
  }

  
  MYImportExcel = file => {
    // 获取上传的文件对象
    const { files } = file.target;
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        // 以binary方式读取得到整份excel表格对象
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = []; // 存储获取到的数据

        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            break; // 如果除了取第一张表以外，還要其他的表，就取消注释这行
          }
        };

        //設定國別
        let nation='MY'
        let goodData=[];
        let cumulatedDeliveryNumber=0;
        
        //這邊是寫一個程式做貨幣轉換，目前馬來西亞匯率是訂7.2
        function TransactionToLocal(NTD){
          return Number((NTD/7.2).toFixed(1))
        };
        
        //整個判定過程就是分三類，普通類、1/n的第一箱、n/n的第n箱
        ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.todayTitle+'/cumulatedDeliveryNumber').once('value', (snapshot)=> {
                     
                 cumulatedDeliveryNumber=snapshot.val();
                 data.forEach((element)=> {                      
                      //透過件數特性的分析，開啟不同的處理方式，把有分箱的部分拿出來特別處理
                      if(element.件數==1){
                            //系統紀錄單號一律用客戶的訂單號碼，單號唯一，但是快遞單號可以改變
                           
                            let number=element.訂單號碼;
                            let date = new Date();
                        
                            //多了一張訂單，加1
                            cumulatedDeliveryNumber=cumulatedDeliveryNumber+1;
                            this.setState({'cumulatedDeliveryNumber':cumulatedDeliveryNumber});
                            saveDeliveryNumber(this.state.customerNumber,this.state.todayTitle,cumulatedDeliveryNumber);
                            //Robin May取訂單後碼+May
                            
                           
                            goodData[number]=[];
                            //散客定義就是訂單號MYA直接加訂單號碼
                            goodData[number].快遞單號='MYA'+element.訂單號碼;;
                            goodData[number].訂單單號=element.訂單號碼;

                            //date一定要做這個處理，要不然進不了realtime database
                            goodData[number].日期=date.toDateString();

                            //普仕特強調要這個寄件人名稱
                            if(element.寄件人){
                              goodData[number].寄件人姓名=element.寄件人;
                            }else{
                              goodData[number].寄件人姓名='DEC';
                            }
                            
                            goodData[number].寄件地址="";
                            goodData[number].dataURL=textToBase64Barcode(goodData[number].快遞單號);

                            goodData[number].收件人姓名=element.收件人;
                            goodData[number].收件人郵遞區號='';
                            goodData[number].收件地址=element.地址;
                            goodData[number].收件連絡電話=element.電話;

                            goodData[number].國別=nation;

                            goodData[number].內容物=element.內容;
                            
                            goodData[number].總箱數=element.件數;
                            goodData[number]['總重量-公斤']=element.重量;
                            
                            goodData[number]['報關總值']=TransactionToLocal(element['價值 NT$']);
                            goodData[number].連絡人="";
                            
                  
                            //當你是希望可以每一張訂單去修改修正的時候，你就要一張一張訂單存，這樣才不會全部覆蓋掉。
                            savePO(this.state.customerNumber,this.state.todayTitle,goodData[number]);
                            
                      }else{
                            let piece=element.件數;

                            //因為普仕特的表達是'1/2'，所以用"/"split後，前段可以判斷這是第幾箱
                            let BoxNumber=piece.split('/')[0];
                            
                            ///因為普仕特的表達是'1/2'，所以用"/"split後，後段可以判斷一共幾箱
                            let TotalNumber=piece.split('/')[1];
                            
                            let number=element.訂單號碼;
                            
                            //下面兩個function是為了要做小數運算
                            function FloatMul(arg1, arg2){
                              var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
                              try { m += s1.split(".")[1].length; } catch (e) { }
                              try { m += s2.split(".")[1].length; } catch (e) { }
                              return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
                            }

                            function FloatAdd(arg1, arg2){
                              var r1, r2, m;
                              try { r1 = arg1.toString().split(".")[1].length; } catch (e) { r1 = 0; }
                              try { r2 = arg2.toString().split(".")[1].length; } catch (e) { r2 = 0; }
                              m = Math.pow(10, Math.max(r1, r2));
                              return (FloatMul(arg1, m) + FloatMul(arg2, m)) / m;
                            }

                            //第一箱的部分就先存成一個temp，因為要把後面箱數的資料都加總進來
                            if(BoxNumber==='1'){
                                  
                                  //多了一張訂單，加1
                                  cumulatedDeliveryNumber=cumulatedDeliveryNumber+1;
                                  this.setState({'cumulatedDeliveryNumber':cumulatedDeliveryNumber});
                                  saveDeliveryNumber(this.state.customerNumber,this.state.todayTitle,cumulatedDeliveryNumber);
                                                                
                                  let date = new Date();

                                
                                  //建立一個訂單的格式，慢慢把資料倒進來  
                                  goodData[number]=[];
                                  
                                  //散客定義就是訂單號MYA直接加訂單號碼
                                  goodData[number].快遞單號='MAY'+element.訂單號碼;
                                  goodData[number].訂單單號=element.訂單號碼;


                                  //date一定要做這個處理，要不然進不了realtime database
                                  goodData[number].日期=date.toDateString();

                                  //普仕特強調要這個寄件人名稱
                                  if(element.寄件人){
                                    goodData[number].寄件人姓名=element.寄件人;
                                  }else{
                                    goodData[number].寄件人姓名='DEC';
                                  };
                                  goodData[number].寄件地址="";
                                  goodData[number].dataURL=textToBase64Barcode(goodData[number].快遞單號);

                                  goodData[number].收件人姓名=element.收件人;
                                  goodData[number].收件人郵遞區號='';
                                  goodData[number].收件地址=element.地址;
                                  goodData[number].收件連絡電話=element.電話;
              
                                  goodData[number].國別=nation;
                                  
                                  //因為後面還有內容物要加進來，所以我們要加空格，把它們隔開
                                  goodData[number].內容物=element.內容+' ';
                                  
                                  //這邊我們直接把split後得到的總箱數放進來
                                  goodData[number].總箱數=TotalNumber;
                                  goodData[number]['總重量-公斤']=element.重量;
                                  goodData[number]['報關總值']=TransactionToLocal(element['價值 NT$']);
                                  goodData[number].連絡人="";
                                  
                                  //因為後面還有資料要加進來，所以先暫存起來
                                  this.setState({temp:goodData[number]})
                            }else if(BoxNumber==TotalNumber){
                                  //現在代表碰到n箱的最後一箱了，所以要把訂單存起來了
                                  goodData[this.state.temp.訂單單號].內容物=goodData[this.state.temp.訂單單號].內容物+element.內容;
                                  goodData[this.state.temp.訂單單號]['總重量-公斤']=FloatAdd(goodData[this.state.temp.訂單單號]['總重量-公斤'],element.重量);
                                  goodData[this.state.temp.訂單單號]['報關總值']=FloatAdd(goodData[this.state.temp.訂單單號]['報關總值'],TransactionToLocal(element['價值 NT$']));
                                  
                                  //當你是希望可以每一張訂單去修改修正的時候，你就要一張一張訂單存，這樣才不會全部覆蓋掉。
                                  savePO(this.state.customerNumber,this.state.todayTitle,goodData[this.state.temp.訂單單號]);

                                  //把暫存紀錄delete掉
                                  this.setState({temp:''})
                            }else{
                                  //這個區段是專門處理第一箱和第n箱以外的其他箱數
                                  goodData[this.state.temp.訂單單號].內容物=goodData[this.state.temp.訂單單號].內容物+element.內容+' ';
                                  goodData[this.state.temp.訂單單號]['總重量-公斤']=FloatAdd(goodData[this.state.temp.訂單單號]['總重量-公斤'],element.重量);
                                  goodData[this.state.temp.訂單單號]['報關總值']=FloatAdd(goodData[this.state.temp.訂單單號]['報關總值'],TransactionToLocal(element['價值 NT$']));
                                  

                            }
                          
                      }
                      


                });
        })
           
           //要把國別記錄下來，這樣後面韓國、越南都可以好分類
           this.setState({[`${nation}PO`]: goodData, [`${nation}Upload`]:'success'});
                      
           //把最後單號存起來，留著後面用
           tellAdmin(this.state.customerNumber,nation,this.state.todayTitle);

      } catch (e) {
        // 这里可以抛出文件类型错误不正确的相关提示
        console.log('文件类型不正确');
        return;
      }
    };
    // 以Binary方式打开文件
    fileReader.readAsBinaryString(files[0]);
  }

  MOImportExcel = file => {
    // 获取上传的文件对象
    const { files } = file.target;
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        // 以binary方式读取得到整份excel表格对象
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = []; // 存储获取到的数据

        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            break; // 如果除了取第一张表以外，還要其他的表，就取消注释这行
          }
        };

        //設定國別
        let nation='MO'
        let goodData=[];
        let cumulatedDeliveryNumber=0;
        
        //這邊是寫一個程式做貨幣轉換，目前澳門直接用新台幣表示，所以匯率是訂1
        function TransactionToLocal(NTD){
          return Number((NTD/1).toFixed(1))
        };
        
        //整個判定過程就是分三類，普通類、1/n的第一箱、n/n的第n箱
        ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.todayTitle+'/cumulatedDeliveryNumber').once('value', (snapshot)=> {
                     
                 cumulatedDeliveryNumber=snapshot.val();
                 data.forEach((element)=> {                      
                      //透過件數特性的分析，開啟不同的處理方式，把有分箱的部分拿出來特別處理
                      if(element.件數==1){
                            //系統紀錄單號一律用客戶的訂單號碼，單號唯一，但是快遞單號可以改變
                           
                            let number=element.訂單號碼;
                            let date = new Date();
                        
                            //多了一張訂單，加1
                            cumulatedDeliveryNumber=cumulatedDeliveryNumber+1;
                            this.setState({'cumulatedDeliveryNumber':cumulatedDeliveryNumber});
                            saveDeliveryNumber(this.state.customerNumber,this.state.todayTitle,cumulatedDeliveryNumber);
                            
                           
                            goodData[number]=[];

                            goodData[number].快遞單號=nation+element.訂單號碼;
                            goodData[number].訂單單號=element.訂單號碼;

                            //date一定要做這個處理，要不然進不了realtime database
                            goodData[number].日期=date.toDateString();

                            //普仕特強調要這個寄件人名稱
                            if(element.寄件人){
                              goodData[number].寄件人姓名=element.寄件人;
                            }else{
                              goodData[number].寄件人姓名='Books.com.co Ltd';
                            }
                            
                            goodData[number].寄件地址="普仕特地址";
                            goodData[number].dataURL=textToBase64Barcode(goodData[number].快遞單號);

                            goodData[number].收件人姓名=element.收件人;
                            goodData[number].收件人郵遞區號='';
                            goodData[number].收件地址=element.地址;
                            goodData[number].收件連絡電話=element.電話;

                            goodData[number].國別=nation;

                            goodData[number].內容物=element.內容;
                            
                            goodData[number].總箱數=element.件數;
                            goodData[number]['總重量-公斤']=element.重量;
                            
                            goodData[number]['報關總值']=TransactionToLocal(element['價值 NT$']);
                            goodData[number].連絡人="";
                            
                  
                            //當你是希望可以每一張訂單去修改修正的時候，你就要一張一張訂單存，這樣才不會全部覆蓋掉。
                            savePO(this.state.customerNumber,this.state.todayTitle,goodData[number]);
                            
                      }else{
                            let piece=element.件數;

                            //因為普仕特的表達是'1/2'，所以用"/"split後，前段可以判斷這是第幾箱
                            let BoxNumber=piece.split('/')[0];
                            
                            ///因為普仕特的表達是'1/2'，所以用"/"split後，後段可以判斷一共幾箱
                            let TotalNumber=piece.split('/')[1];
                            
                            let number=element.訂單號碼;
                            
                            //下面兩個function是為了要做小數運算
                            function FloatMul(arg1, arg2){
                              var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
                              try { m += s1.split(".")[1].length; } catch (e) { }
                              try { m += s2.split(".")[1].length; } catch (e) { }
                              return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
                            }

                            function FloatAdd(arg1, arg2){
                              var r1, r2, m;
                              try { r1 = arg1.toString().split(".")[1].length; } catch (e) { r1 = 0; }
                              try { r2 = arg2.toString().split(".")[1].length; } catch (e) { r2 = 0; }
                              m = Math.pow(10, Math.max(r1, r2));
                              return (FloatMul(arg1, m) + FloatMul(arg2, m)) / m;
                            }

                            //第一箱的部分就先存成一個temp，因為要把後面箱數的資料都加總進來
                            if(BoxNumber==='1'){
                                  
                                  //多了一張訂單，加1
                                  cumulatedDeliveryNumber=cumulatedDeliveryNumber+1;
                                  this.setState({'cumulatedDeliveryNumber':cumulatedDeliveryNumber});
                                  saveDeliveryNumber(this.state.customerNumber,this.state.todayTitle,cumulatedDeliveryNumber);
                                                                
                                  let date = new Date();
                                  //建立一個訂單的格式，慢慢把資料倒進來  
                                  goodData[number]=[];

                                  goodData[number].快遞單號=nation+element.訂單號碼;
                                  goodData[number].訂單單號=element.訂單號碼;


                                  //date一定要做這個處理，要不然進不了realtime database
                                  goodData[number].日期=date.toDateString();

                                  //普仕特強調要這個寄件人名稱
                                  if(element.寄件人){
                                    goodData[number].寄件人姓名=element.寄件人;
                                  }else{
                                    goodData[number].寄件人姓名='Books.com.co Ltd';
                                  };
                                  goodData[number].寄件地址="普仕特地址";
                                  goodData[number].dataURL=textToBase64Barcode(goodData[number].快遞單號);

                                  goodData[number].收件人姓名=element.收件人;
                                  goodData[number].收件人郵遞區號='';
                                  goodData[number].收件地址=element.地址;
                                  goodData[number].收件連絡電話=element.電話;
              
                                  goodData[number].國別=nation;
                                  
                                  //因為後面還有內容物要加進來，所以我們要加空格，把它們隔開
                                  goodData[number].內容物=element.內容+' ';
                                  
                                  //這邊我們直接把split後得到的總箱數放進來
                                  goodData[number].總箱數=TotalNumber;
                                  goodData[number]['總重量-公斤']=element.重量;
                                  goodData[number]['報關總值']=TransactionToLocal(element['價值 NT$']);
                                  goodData[number].連絡人="";
                                  
                                  //因為後面還有資料要加進來，所以先暫存起來
                                  this.setState({temp:goodData[number]})
                            }else if(BoxNumber==TotalNumber){
                                  //現在代表碰到n箱的最後一箱了，所以要把訂單存起���了
                                  goodData[this.state.temp.訂單單號].內容物=goodData[this.state.temp.訂單單號].內容物+element.內容;
                                  goodData[this.state.temp.訂單單號]['總重量-公斤']=FloatAdd(goodData[this.state.temp.訂單單號]['總重量-公斤'],element.重量);
                                  goodData[this.state.temp.訂單單號]['報關總值']=FloatAdd(goodData[this.state.temp.訂單單號]['報關總值'],TransactionToLocal(element['價值 NT$']));
                                  
                                  //當你是希望可以每一張訂單去修改修正的時候，你就要一張一張訂單存，這樣才不會全部覆蓋掉。
                                  savePO(this.state.customerNumber,this.state.todayTitle,goodData[this.state.temp.訂單單號]);

                                  //把暫存紀錄delete掉
                                  this.setState({temp:''})
                            }else{
                                  //這個區段是專門處理第一箱和第n箱以外的其他箱數
                                  goodData[this.state.temp.訂單單號].內容物=goodData[this.state.temp.訂單單號].內容物+element.內容+' ';
                                  goodData[this.state.temp.訂單單號]['總重量-公斤']=FloatAdd(goodData[this.state.temp.訂單單號]['總重量-公斤'],element.重量);
                                  goodData[this.state.temp.訂單單號]['報關總值']=FloatAdd(goodData[this.state.temp.訂單單號]['報關總值'],TransactionToLocal(element['價值 NT$']));
                                  

                            }
                          
                      }
                      


                });
        })
           
           //要把國別記錄下來，這樣後面韓國、越南都可以好分類
           this.setState({[`${nation}PO`]: goodData, [`${nation}Upload`]:'success'});
                      
           //把最後單號存起來，留著後面用
           tellAdmin(this.state.customerNumber,nation,this.state.todayTitle);

      } catch (e) {
        // 这里可以抛出文件类型错误不正确的相关提示
        console.log('文件类型不正确');
        return;
      }
    };
    // 以Binary方式打开文件
    fileReader.readAsBinaryString(files[0]);
  }

  
  //下面是萬用格式
  UniversalImportExcel = file => {
    // 获取上传的文件对象
    const { files } = file.target;
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        // 以binary方式读取得到整份excel表格对象
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = []; // 存储获取到的数据

        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            break; // 如果除了取第一张表以外，還要其他的表，就取消注释这行
          }
        };

        //設定國別
        let nation='MIX'
        let goodData=[];
        let cumulatedDeliveryNumber=0;
        
        
        
        //整個判定過程就是分三類，普通類、1/n的第一箱、n/n的第n箱
        ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.todayTitle+'/cumulatedDeliveryNumber').once('value', (snapshot)=> {
                     
                 cumulatedDeliveryNumber=snapshot.val();
                 data.forEach((element)=> {                      
                      
                      
                            //系統紀錄單號一律用客戶的訂單號碼，單號唯一，但是快遞單號可以改變
                           
                            let number=element.跨境通提單號碼;
                            let date = new Date();
                        
                            //多了一張訂單，加1
                            cumulatedDeliveryNumber=cumulatedDeliveryNumber+1;
                            this.setState({'cumulatedDeliveryNumber':cumulatedDeliveryNumber});
                            saveDeliveryNumber(this.state.customerNumber,this.state.todayTitle,cumulatedDeliveryNumber);
                            
                            
                           
                            goodData[number]=[];
                            goodData[number].印製張數=element.印製張數;
                            goodData[number].快遞單號=element.跨境通提單號碼;;
                            
                            if(element['訂單單號']){
                              goodData[number].訂單單號=element['訂單單號'];
                            }else{
                              goodData[number].訂單單號='';
                            }

                            if(element['AC-NO']){
                              goodData[number].acno=element['AC-NO'];
                            }else{
                              goodData[number].acno='';
                            }

                            if(element['AC-NO'] && element.國別=='SG'){
                              goodData[number].收件人郵遞區號=element['AC-NO']
                            }else{
                              goodData[number].收件人郵遞區號=''
                            }
                            

                            

                            //date一定要做這個處理，要不然進不了realtime database
                            goodData[number].日期=date.toDateString();                            
                            goodData[number].寄件人姓名=element.寄件人姓名;

                            if(element['寄件人連絡電話']){
                              goodData[number].寄件人連絡電話=element['寄件人連絡電話'];
                            }else{
                              goodData[number].寄件人連絡電話='';
                            }

                            if(element['寄件人地址']){
                              goodData[number].寄件地址=element['寄件人地址'];
                            }else{
                              goodData[number].寄件地址='';
                            }


                                                       
                            //goodData[number].寄件地址="";
                            goodData[number].dataURL=textToBase64Barcode(goodData[number].快遞單號);

                            goodData[number].收件人姓名=element.收件人姓名;
                            
                            goodData[number].收件地址=element.收件人地址;
                            goodData[number].收件連絡電話=element.收件人連絡電話;

                            goodData[number].國別=element.國別;

                            goodData[number].內容物=element.內容物;
                            
                            goodData[number].總箱數=element.件數;

                            goodData[number]['總重量-公斤']=element.重量;
                            
                            goodData[number]['報關總值']=element.總值;
                            
                            
                  
                            //當你是希望可以每一張訂單去修改修正的時候，你就要一張一張訂單存，這樣才不會全部覆蓋掉。
                            
                            savePO(this.state.customerNumber,this.state.year,this.state.month,this.state.day,goodData[number]);
                            
                      
                            


                });
        })
           
           //要把國別記錄下來，這樣後面韓國、越南都可以好分類
           this.setState({[`${nation}PO`]: goodData, [`${nation}Upload`]:'success'});
           
                      
           //把最後單號存起來，留著後面用
           tellAdmin(this.state.customerNumber,nation,this.state.year,this.state.month,this.state.day);

      } catch (e) {
        // 这里可以抛出文件类型错误不正确的相关提示
        console.log('文件类型不正确');
        return;
      }
    };
    // 以Binary方式打开文件
    fileReader.readAsBinaryString(files[0]);
  }

  handleUniversalLablePrint(){
    //先把我們要處理的資料倒進來，所以隨著訂單不同，要改 
    //這是通用universal的版本，所以印製張數看表格
    /*
    要加印
    [{ text: 'A/C NO:'},
    {text: 'N6900038',
      colSpan: 5},
    */
   
    let goodData=this.state['MIXPO'];
    
    //let Nation='MY';
    //let Currency='MYR';
    
    
    pdfmake.vfs = pdffonts.pdfMake.vfs;
    

    //把印製的Label格式準備好
    var LabelArr = [];

    //把最後一張訂單挑出來，因為這張訂單要特別處理，不要接下一頁
    const keys = Object.keys(goodData);
    var last=keys.pop();

    

    //開始處理每張訂單，導進標籤印製群組
    Object.keys(goodData).forEach((key)=> {
      var po=goodData[key];
      
      //logo是跨境通的logo，在這裡先導進來
      var logo=LogoImg();

      //下面這句是避免原廠導入格式不乾淨，全部轉成Number後面好處理
      var LabelNumber=Number(po.印製張數);
      
      
      
        //馬來西亞的標籤數需求就是總箱數再加1張，就這麼簡單
          for(var i=0;i<LabelNumber;i++){
            //如果是最後一張單的最後一張標，就不要再下一頁了，這是唯一需要特別處理的。
            if(key===last && i==LabelNumber-1){         
              let Label=[
                { table: {
                  widths: ['auto', '*', '*', '*', '*', '*'],
                  //下面是要做一開始的logo、barcode、和國碼標
                  body: [
                    [
                      {
                      // you'll most often use dataURI images on the browser side
                      // if no width/height/fit is provided, the original size will be used
                      image:logo,
                      width:100, 
                      height:20,
                      alignment: 'center',
                      colSpan: 3},
                      '',
                      '',
                      { rowSpan: 2,
                        colSpan: 3,
                        text: po.國別,
                        style: 'head',  
                        width:100, 
                        alignment: 'center',          
                      },
                      '',
                      ''

                    ],
                    [{ image:po.dataURL,
                      colSpan: 3,
                      width:140, 
                      height:50,
                      alignment: 'center',
                    },
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],
                    //這是馬來西亞標籤特別要加的
                    [{ text: 'A/C NO:'},
                    {text: po.acno,
                      colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{ text: 'PO:'},
                    {text:po.訂單單號,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper:'},
                    {text: po.寄件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper TEL:'},
                    {text: po.寄件人連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper Address:'},
                    {text: po.寄件地址,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee:'},
                    {text: po.收件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee TEL:'},
                    {text: po.收件連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee Address:',rowSpan: 2},
                    {text: po.收件地址,rowSpan: 2,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{},
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Package:'},
                    {text: po['總箱數'],colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Declared Value:'},
                    {text: po['報關總值'],colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Weight:'},
                    //KGS前面後一格，看起來比較好看
                    {text: po['總重量-公斤']+' KGS',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{text: 'Description:'},
                    {text: po.內容物,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                  ]
                }},     
              ];

              LabelArr.push(Label);

            }else{
             //一般情況下都是跑下面這張標籤        
              let Label=[
                { table: {
                  widths: ['auto', '*', '*', '*', '*', '*'],
                  //下面是要做一開始的logo、barcode、和國碼標
                  body: [
                    [
                      {
                      // you'll most often use dataURI images on the browser side
                      // if no width/height/fit is provided, the original size will be used
                      image:logo,
                      width:100, 
                      height:20,
                      alignment: 'center',
                      colSpan: 3},
                      '',
                      '',
                      { rowSpan: 2,
                        colSpan: 3,
                        text: po.國別,
                        style: 'head',  
                        width:100, 
                        alignment: 'center',          
                      },
                      '',
                      ''

                    ],
                    [{ image:po.dataURL,
                      colSpan: 3,
                      width:140, 
                      height:50,
                      alignment: 'center',
                    },
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],
                    //這是馬來西亞標籤特別要加的
                    [{ text: 'A/C NO:'},
                    {text: po.acno,
                      colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{ text: 'PO:'},
                    {text:po.訂單單號,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper:'},
                    {text: po.寄件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper TEL:'},
                    {text: po.寄件人連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Shipper Address:'},
                    {text: po.寄件地址,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee:'},
                    {text: po.收件人姓名,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee TEL:'},
                    {text: po.收件連絡電話,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Consignee Address:',rowSpan: 2},
                    {text: po.收件地址,rowSpan: 2,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{},
                    {},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Package:'},
                    {text: po['總箱數'],colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Declared Value:'},
                    {text: po['報關總值'],colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                    [{text: 'Weight:'},
                    //KGS前面後一格，看起來比較好看
                    {text: po['總重量-公斤']+' KGS',colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],
                    [{text: 'Description:'},
                    {text: po.內容物,colSpan: 5},
                    {},
                    {},
                    {},
                    {}
                    ],

                  ],
                
                  },
                  pageBreak: 'after'
                }, 
              
              ];

              LabelArr.push(Label);
            };
          };

    });

    
    pdfmake.fonts = {
     ch: {
     normal: 'kaiu.ttf',
     bold: 'kaiu.ttf',
     italics: 'kaiu.ttf',
     bolditalics: 'kaiu.ttf'
     }};

    var docDefinition = {
        content:LabelArr,
        defaultStyle: {
        font: 'ch',
        fontSize: 8
        } ,
        styles: {
          head: {
            
            fontSize: 66,
            bold: true
          },

        },

        // a string or { width: number, height: number }
        // 下面是10*10的標籤設計
        pageSize: { width: 283, height: 283},
        

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 3, 3,3, 0 ],
      };
     
      let labeltitle="跨境通標籤-"+this.state.nation+this.state.todayTitle+'.pdf';
      
      

      pdfmake.createPdf(docDefinition).download(labeltitle);
           //pdfmake.createPdf(docDefinition).open();
      

      
      

  }
  

  

  


  render() {
    return (



      <div style={{ padding: '5em 5em','font-size':'26px','font-weight':'bold','background-color': 'rgb(244, 173, 66)'}}>
        <Header as='h1'>您好DEC，你可以自己印標籤了。</Header>
        <Table celled padded textAlign='center' style={{ padding: '5em 5em','background-color': 'rgb(244, 173, 66)',fontSize:20 }}>
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell>寄送國別</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>上傳資料</Table.HeaderCell>
                <Table.HeaderCell>下載標籤</Table.HeaderCell>
                
            </Table.Row>
            </Table.Header>
        
            <Table.Body>
            

            {/*
            <Table.Row style={{'background-color': 'rgb(174, 198, 45)'  }}>
                <Table.Cell><h1>新加坡</h1></Table.Cell>
                <Table.Cell>
                    
                </Table.Cell>
                <Table.Cell>
                    <h1>請先上傳新加坡訂單寄送資料</h1><br/>
                    <Input type='file'  accept='.xlsx, .xls' onChange={this.SGImportExcel} />
                    {this.state.SGUpload?<h1>上傳成功</h1>:''}
                </Table.Cell>
                <Table.Cell>
                    <Button size='large'  color='orange' onClick={this.handleSGLablePrint}>請按我一下!<br/>把標籤印出來!!</Button>
                </Table.Cell>
            </Table.Row>
            */}
            <Table.Row style={{'background-color': 'rgb(45, 126, 200)' }}>
                <Table.Cell><h1>馬來西亞</h1></Table.Cell>
                <Table.Cell>
                       
                </Table.Cell>
                <Table.Cell>
                    <h1>請先上傳馬來西亞訂單寄送資料</h1><br/>
                    <Input type='file'  accept='.xlsx, .xls' onChange={this.MYImportExcel} />
                    {this.state.MYUpload?<h1>上傳成功</h1>:''}
                </Table.Cell>
                <Table.Cell>
                <Button size='large'  color='orange' onClick={this.handleMYLablePrint}>請按我一下!<br/>把標籤印出來!!</Button>
                </Table.Cell>
            </Table.Row>

            <Table.Row style={{'background-color': 'rgb(174, 198, 45)' }}>
                <Table.Cell><h1>萬用格式</h1></Table.Cell>
                <Table.Cell>
                       
                </Table.Cell>
                <Table.Cell>
                    <h1>請先上傳萬用格式訂單寄送資料</h1><br/>
                    <Input type='file'  accept='.xlsx, .xls' onChange={this.UniversalImportExcel} />
                    {this.state.MIXUpload?<h1>上傳成功</h1>:''}
                </Table.Cell>
                <Table.Cell>
                <Button size='large'  color='orange' onClick={this.handleUniversalLablePrint}>請按我一下!<br/>把標籤印出來!!</Button>
                </Table.Cell>
            </Table.Row>
            
            
            {/*
            <Table.Row style={{'background-color': 'rgb(244, 200, 66)' }}>
                <Table.Cell><h1>澳門</h1></Table.Cell>
                <Table.Cell>
                       
                </Table.Cell>
                <Table.Cell>
                    <h1>請先上傳澳門訂單寄送資料</h1><br/>
                    <Input type='file'  accept='.xlsx, .xls' onChange={this.MOImportExcel} />
                    {this.state.MOUpload?<h1>上傳成功</h1>:''}
                </Table.Cell>
                <Table.Cell>
                <Button size='large'  color='orange' onClick={this.handleMOLablePrint}>請按我一下!<br/>把標籤印出來!!</Button>
                </Table.Cell>
            </Table.Row>
            */}
            
            </Table.Body>
        </Table>
        
        {/*<T80071/>*/}

      </div>




    );
  }
}

export default App;
