import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import {todayTitle,savePO,tellAdmin,saveDeliveryNumber} from './helper/SaveToDatabase';
import {LogoImg,textToBase64Barcode} from './helper/Helper';
import pdfmake from 'pdfmake/build/pdfmake'
import pdffonts from 'pdfmake/build/vfs_fonts';
import saveAs from 'file-saver';
import {Button,Input, Header,Table,Form,Grid,Segment,Label,Radio,Divider} from 'semantic-ui-react';
import {ref, firebaseAuth} from '../firebase/firebaseConfig';
import 'semantic-ui-css/semantic.min.css';


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      //這個每個客人都要改
      'customerNumber':'80070',
      // 'customerNumber':'test',
      'deliveryNation':'HK',
      'itemNumber':1,            //一共幾個品項  因為有這個數字，我才知道要開幾個欄位給客人填，如果是2，就開兩個品項給客人填
      'itemName1':'書',
      'boxNumber':1,
      //這就是要把訂單編碼的前幾碼抓出來，讓客人好輸入
      'poPredefinedNumber':'',
      'today':todayTitle()[0],
      'year':todayTitle()[1],
      'month':todayTitle()[2],
      'day':todayTitle()[3],
      'userid':'',
      'receiver':'',
      'amount':0,
      'zipCode':'',
      'doorNumber':'',
      'contactENG':'',
      'printStatus':false,
      'historyPONumberList':'',
      'todayPONumberList':''
    };
    
    this.print=this.print.bind(this);
    this.changeDeliveryNation=this.changeDeliveryNation.bind(this);
    this.handleInputChange=this.handleInputChange.bind(this);
    this.addItem=this.addItem.bind(this);
    this.cutItem=this.cutItem.bind(this);
    this.print=this.print.bind(this);
    this.poNumberChange=this.poNumberChange.bind(this);
    this.handlePhoneNumberChange=this.handlePhoneNumberChange.bind(this);
    this.deleteRecord=this.deleteRecord.bind(this);
    this.handleItemAmountChange=this.handleItemAmountChange.bind(this);
    this.ExportListFile=this.ExportListFile.bind(this);
  }


componentDidMount (){
    var userid = firebaseAuth.currentUser.uid;
    //console.log(userid)
    this.setState({
      'userid':userid,
    })
    
    //因為國家一開始預設HK，所以一開機就把香港資料帶進來
    //後面userid的寫法和changeDeliveryNation不同是因為這裡讀不到this.state.userid
    ref.child('/infos/customers/'+this.state.customerNumber+'/receiverList/HK/'+userid).on('value', (snapshot)=> {
      if(snapshot.val()==null){
          
      }else{
        let receiver=snapshot.val();
        this.setState({
          'receiver':receiver
        })
        //console.log(this.state.receiver)
      }
    })

    
   
    
    //我們會把過去六個月的資料叫進來比對一下，確認一下單號的狀態
    let result={}
    for(var i=0;i<6;i++){
      var d = new Date();
      d.setMonth(d.getMonth() - i);
      //月份要調整一下
      let month=d.getMonth()+1;
      if(month.toString().length===1){
        month="0"+month;
      };
      //把我們要的目標資料夾拼湊出來
      let target=(String(d.getFullYear())+month)
      
      ref.child('/infos/customers/'+this.state.customerNumber+'/DeliveryNumberRecord/'+target).once('value', (snapshot)=> {
         //console.log(Object.keys(snapshot.val())) 
         Object.assign(result,snapshot.val())
          
         this.setState({
          'historyPONumberList':result
         })
         
         //console.log( Object.keys(result).includes("A20201003MW4"))
         
      })
      //console.log( Object.keys(result))
    }
    //每天key的單也叫進來比對一下
    ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/todayPONumber').on('value', (snapshot)=> {
      if(snapshot.val()==null){
        
      }else{
        this.setState({
          'todayPONumberList':snapshot.val()
        })
       
      }
    })
    

    
    

    
  }


//決定現在是要寄香港還是馬來西亞
changeDeliveryNation(e,{ value }){
      //因為要提供快速地址輸入，所以我要先把各個國家的寄送資訊叫進來
      ref.child('/infos/customers/'+this.state.customerNumber+'/receiverList/'+value+'/'+this.state.userid).on('value', (snapshot)=> {
        if(snapshot.val()==null){
            
        }else{
        let receiver=snapshot.val();
        this.setState({
          'receiver':receiver
        })
        //console.log(this.state.receiver)
        }
      })
     
      this.setState({
        'deliveryNation':value,
      }) 
    
}

handlePhoneNumberChange(e){
  var phone=e.target.value;
  var obj=this.state.receiver
  //console.log(obj)
  if(obj.hasOwnProperty(phone)){
    //記住，如果你現在要呼叫的是一個變數名稱，不是string的時候，你要放在[]裡面
    var target=obj[phone]
    this.setState({ 
        'contact':target.name, 
        'address':target.address, 
    });
  }
  
  this.setState({ 
    [e.target.name]: e.target.value,
   });
}

//這是所有欄位普用型的function
handleInputChange(e){
    this.setState({ [e.target.name]: e.target.value });  
    //console.log(Object.keys(this.state.historyPONumberList))
}

//加一個品項 
addItem(){
    var number=this.state.itemNumber
    number=number+1
    this.setState({
      'itemNumber':number,
      ['itemName'+number]:'',
      ['itemNumber'+number]:'',
    })
    

}

//少一個品項
cutItem(){
  var number=this.state.itemNumber
  if(number>1){
    delete this.state['itemName'+number]
    delete this.state['itemNumber'+number]
    number=number-1
    this.setState({
      'itemNumber':number,
    })

  }
  
}

//印製品項
print(){
  
  //我們把所有的主要印製處理步驟寫在這個程式裡面，讓大家用。
  const printProcess=(currency,labelNumber,acNumber,deliveryNumber,amount)=>{
          //快遞內容品項
        var content='';
        //下面這個是要獨立存起來，幫DEC算總數
        var contentCalculateBase=[];
        for(var i=0;i<this.state.itemNumber;i++){
          var number=i+1
          var item=this.state['itemName'+number]+'*'+this.state['itemNumber'+number]+'  '
          content=content+item
          contentCalculateBase[this.state['itemName'+number]]={'Number':this.state['itemNumber'+number],'Amount':this.state['itemAmount'+number]}
        
        }
        
        var logo=LogoImg();
        var dataURL=textToBase64Barcode(deliveryNumber);
        
        pdfmake.vfs = pdffonts.pdfMake.vfs;
         
        var address=''
        if(this.state.deliveryNation==='JP'){
          address= this.state.doorNumber+' '+this.state.address
        }else{
          address= this.state.address
        }
        //你只要印很多張，你就需要這個LabelArr，把他們排列一下
        var LabelArr = [];
        for(var i=0;i<labelNumber;i++){
          if(i==labelNumber-1){ 
            let Label=[
              { table: {
                widths: ['auto', '*', '*', '*', '*', '*'],
                //下面是要做一開始的logo、barcode、和國碼標
                body: [
                  [
                    {
                    // you'll most often use dataURI images on the browser side
                    // if no width/height/fit is provided, the original size will be used
                    image:logo,
                    width:100, 
                    height:20,
                    alignment: 'center',
                    colSpan: 3},
                    '',
                    '',
                    { rowSpan: 3,
                      colSpan: 3,
                      text: this.state.deliveryNation,
                      style: 'head',  
                      width:100, 
                      alignment: 'center',          
                    },
                    '',
                    ''
        
                  ],
                  [{ image:dataURL,
                    colSpan: 3,
                    width:140, 
                    height:50,
                    alignment: 'center',
                  },
                  {},
                  {},
                  {},
                  {},
                  {}
                  ],
                  [{text:deliveryNumber,
                    colSpan: 3, 
                    style: 'head2', 
                    width:140, 
                    height:10,
                    alignment: 'center', },
                  {},
                  {},
                  {},
                  {},
                  {}
                  ],
                  //這是馬來西亞標籤特別要加的
                  [{ text: 'A/C NO:'},
                  {text: acNumber,
                    colSpan: 5},
                  {},
                  {},
                  {},
                  {}
                  ],
                  [{ text: 'PO:'},
                  {text:this.state.poNumber,colSpan: 5},
                  {},
                  {},
                  {},
                  {}
                  ],
        
                  [{text: 'Shipper:'},
                  {text:'P-EXPRESS CO., LTD.',colSpan: 5},
                  {},
                  {},
                  {},
                  {}
                  ],
        
                  [{text: 'Consignee:'},
                  {text: this.state.contact,colSpan: 5},
                  {},
                  {},
                  {},
                  {}
                  ],
        
                  [{text: 'TEL:'},
                  {text: this.state.receiverPhoneNumber,colSpan: 5},
                  {},
                  {},
                  {},
                  {}
                  ],
        
                  [{text: 'Address:',rowSpan: 2},
                  {text: address,rowSpan: 2,colSpan: 5},
                  {},
                  {},
                  {},
                  {}
                  ],
                  [{},
                  {},
                  {},
                  {},
                  {},
                  {}
                  ],
        
                  [{text: 'Declared Value:'},
                  {text: currency+amount,colSpan: 5},
                  {},
                  {},
                  {},
                  {}
                  ],
        
                  [{text: 'Weight:'},
                  //KGS前面後一格，看起來比較好看
                  {text:this.state.weight+' KGS',colSpan: 5},
                  {},
                  {},
                  {},
                  {}
                  ],
                  [{text: 'Description:'},
                  {text: content,colSpan: 5},
                  {},
                  {},
                  {},
                  {}
                  ],
        
                ]
              },
              },     
            ];
            LabelArr.push(Label);
          }else{
            let Label=[
              { table: {
                widths: ['auto', '*', '*', '*', '*', '*'],
                //下面是要做一開始的logo、barcode、和國碼標
                body: [
                  [
                    {
                    // you'll most often use dataURI images on the browser side
                    // if no width/height/fit is provided, the original size will be used
                    image:logo,
                    width:100, 
                    height:20,
                    alignment: 'center',
                    colSpan: 3},
                    '',
                    '',
                    { rowSpan: 3,
                      colSpan: 3,
                      text: this.state.deliveryNation,
                      style: 'head',  
                      width:100, 
                      alignment: 'center',          
                    },
                    '',
                    ''
        
                  ],
                  [{ image:dataURL,
                    colSpan: 3,
                    width:140, 
                    height:50,
                    alignment: 'center',
                  },
                  {},
                  {},
                  {},
                  {},
                  {}
                  ],
                  [{text:deliveryNumber,
                    colSpan: 3, 
                    style: 'head2', 
                    width:140, 
                    height:10,
                    alignment: 'center', },
                  {},
                  {},
                  {},
                  {},
                  {}
                  ],
                  //這是馬來西亞標籤特別要加的
                  [{ text: 'A/C NO:'},
                  {text: acNumber,
                    colSpan: 5},
                  {},
                  {},
                  {},
                  {}
                  ],
                  [{ text: 'PO:'},
                  {text:this.state.poNumber,colSpan: 5},
                  {},
                  {},
                  {},
                  {}
                  ],
        
                  [{text: 'Shipper:'},
                  {text:'P-EXPRESS CO., LTD.',colSpan: 5},
                  {},
                  {},
                  {},
                  {}
                  ],
        
                  [{text: 'Consignee:'},
                  {text: this.state.contact,colSpan: 5},
                  {},
                  {},
                  {},
                  {}
                  ],
        
                  [{text: 'TEL:'},
                  {text: this.state.receiverPhoneNumber,colSpan: 5},
                  {},
                  {},
                  {},
                  {}
                  ],
        
                  [{text: 'Address:',rowSpan: 2},
                  {text: address,rowSpan: 2,colSpan: 5},
                  {},
                  {},
                  {},
                  {}
                  ],
                  [{},
                  {},
                  {},
                  {},
                  {},
                  {}
                  ],
        
                  [{text: 'Declared Value:'},
                  {text: currency+amount,colSpan: 5},
                  {},
                  {},
                  {},
                  {}
                  ],
        
                  [{text: 'Weight:'},
                  //KGS前面後一格，看起來比較好看
                  {text:this.state.weight+' KGS',colSpan: 5},
                  {},
                  {},
                  {},
                  {}
                  ],
                  [{text: 'Description:'},
                  {text: content,colSpan: 5},
                  {},
                  {},
                  {},
                  {}
                  ],
        
                ]
              },
              pageBreak: 'after'
             },     
            ];
            LabelArr.push(Label);
          }
        }

      

        
        pdfmake.fonts = {
        ch: {
        normal: 'kaiu.ttf',
        bold: 'kaiu.ttf',
        italics: 'kaiu.ttf',
        bolditalics: 'kaiu.ttf'
        }};

        var docDefinition = {
            content:LabelArr,
            defaultStyle: {
            font: 'ch',
            fontSize: 11
            } ,
            styles: {
              head: {
                fontSize: 66,
                bold: true
              },
              head2: {                
                fontSize: 22,
                bold: true
              },

            },

            // a string or { width: number, height: number }
            // 下面是10*10的標籤設計
            pageSize: { width: 283, height: 283},
            

            // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
            pageMargins: [ 3, 3,3, 0 ],
          };
        
        var goodData=[];
        goodData.快遞單號=deliveryNumber;
        goodData.訂單單號=this.state.poNumber
        goodData.寄件人姓名='P-EXPRESS CO., LTD.';
        goodData.寄件地址="普仕特地址";
        goodData.收件人姓名=this.state.contact;
        goodData.收件地址=this.state.address;
        goodData.收件連絡電話=this.state.receiverPhoneNumber;
        goodData.內容物=content;
        goodData.總箱數=this.state.boxNumber;
        goodData.國別=this.state.deliveryNation;
        goodData['總重量-公斤']=this.state.weight;
        goodData['報關總值']=this.state.amount;
        goodData.收件人郵遞區號=this.state.zipCode;
        goodData.itemNumber=this.state.itemNumber;
        goodData.amount=this.state.amount;
        goodData.poNumber=this.state.poNumber;
        goodData.time=Date();
        goodData.timeTW=new Date().toLocaleString('zh-TW', {timeZone: 'Asia/Taipei'});
        

        //日本的部分要多存兩個欄位
        if(this.state.deliveryNation==='JP'){
             goodData.doorNumber=this.state.doorNumber
             goodData.contactENG=this.state.contactENG
        }

        for(let i=0;i<this.state.itemNumber;i++){
          let number=i+1
          goodData['itemName'+number]=this.state['itemName'+number]
          goodData['itemNumber'+number]=this.state['itemNumber'+number]
          goodData['itemAmount'+number]=this.state['itemAmount'+number]
        }

        var receiver=[];
        //receiver.phone=this.state.receiverPhoneNumber;
        receiver.name=this.state.contact;
        receiver.address=this.state.address;

        //確定存檔後，要繼續做的事情
        const doTheRest=()=>{
           //把寄件人資料存起來，後續要查找
           //要存檔之前要先確認一下路徑上面的參數是不是空白，如果是空白，會讓整個node被刪除
           //不要用undefined的確認方式，因為這個file都會define，但是空值還是有問題
           if(this.state.deliveryNation!== ' ' & this.state.receiverPhoneNumber!== ' '){
              ref.child('/infos/customers/'+this.state.customerNumber+'/receiverList/'+this.state.deliveryNation+'/'+this.state.userid+'/'+this.state.receiverPhoneNumber).set(receiver)
           }
           //同時要存到date才可以拉資料
           ref.child('/infos/dates/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+this.state.deliveryNation+'/'+this.state.customerNumber+'/'+this.state.userid+'/'+this.state.poNumber).set(goodData);
           //把內容物存起來，留著幫DEC算
           ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/contentCalculateBase/'+this.state.deliveryNation+'/'+this.state.userid+'/'+this.state.poNumber).set(contentCalculateBase)
           
           //把單號另外存起來，要確認單號沒有重複
           //要存兩個地方，一個是每天的orderdata，讓存取量比較小
           //一個是歷史資料夾，方便抓過去六個月
           ref.child('/infos/customers/'+this.state.customerNumber+'/DeliveryNumberRecord/'+this.state.year+this.state.month+'/'+this.state.poNumber).set({1:1})
           ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/todayPONumber/'+this.state.poNumber).set({1:1})
           //告訴DEC進單了
           tellAdmin(this.state.customerNumber,this.state.deliveryNation,this.state.year,this.state.month,this.state.day);

           //準備出單印製
           pdfmake.createPdf(docDefinition).print();
           //因為客人要準備下一張訂單，這裡我們要還原，把品項縮回1
           for(var i=1; i<this.state.itemNumber;i++){
             var number=i+1
             delete this.state['itemName'+number]
             delete this.state['itemNumber'+number]
             delete this.state['itemAmount'+number]

           }
           //把品項內容恢復原狀
           this.setState({ 
             today:todayTitle()[0],
             year:todayTitle()[1],
             month:todayTitle()[2],
             day:todayTitle()[3],
             receiverPhoneNumber:'',
             contact: '',
             contactENG:'',
             address:'',
             doorNumber:'',
             zipCode:'',
             poNumber:this.state.poPredefinedNumber,
             boxNumber:1,
             weight:'',
             amount:0,
             itemNumber:1,
             itemName1:'書',
             itemNumber1:'',
             itemAmount1:'',
             printErrorMessage:'',
             deleteRecordMessage:'',
             
           }); 
           
        }
        //先存檔案一次
        ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+this.state.deliveryNation+'/'+this.state.userid+'/'+this.state.poNumber).set(goodData).then((error)=> {
                  if(error){   
                      this.setState({
                        printErrorMessage:'訂單存檔沒有成功，再按一次!',
                      })                 
                  }          
        })      
        //為了避免檔案沒存到就印標籤，所以先查一次，如果有，就印標
        ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+this.state.deliveryNation+'/'+this.state.userid+'/'+this.state.poNumber).once('value', (snapshot)=> {
                  //因為這邊是用on，只要檔案變化，就會印，所以要分三種狀況。
                  //如果是因為delete造成檔案變化，這裡我們就不要動作
                  if(snapshot.val()==null && this.state.deleteRecord===''){
                      this.setState({
                        printErrorMessage:'檔案儲存沒有成功，請再按一次!',
                      })
                  }else if(snapshot.val()==null && this.state.deleteRecord!==''){
                      
                  }else{    
                          doTheRest()
                  }

        })


  }
 
        
  
  var currency='';
  var labelNumber='';
  var acNumber='';
  var deliveryNumber='';
  var amount='';
  

  //如果單號存在，我才要讓系統存
  if(!this.state.poNumber||this.state.amount===0||!this.state.weight){
    this.setState({ 
      printErrorMessage:'你的單號、重量或是金額是不是忘記填了?',
    })
  }else if (Object.keys(this.state.todayPONumberList).includes(this.state.poNumber) || Object.keys(this.state.historyPONumberList).includes(this.state.poNumber)) {
    this.setState({ 
      printErrorMessage:'你的單號好像重複了!!請查單@@',
    })
    
  }else{

  if(this.state.deliveryNation=='HK'){
     currency='HKD'
     labelNumber=Number(this.state.boxNumber)+1
     
     //deliveryNumber='HK'+this.state.poNumber
     //原本普仕特要加國別，現在不加
     deliveryNumber=this.state.poNumber
     amount=Number((this.state.amount/4).toFixed(1))
     printProcess(currency,labelNumber,acNumber,deliveryNumber,amount);
  }else if(this.state.deliveryNation=='MY'){
     currency='MYR'
     labelNumber=Number(this.state.boxNumber)+1
     acNumber='N6900038'
     //deliveryNumber='MY'+this.state.poNumber
     //原本普仕特要加國別，現在不加
     deliveryNumber=this.state.poNumber
     amount=Number((this.state.amount/7.2).toFixed(1))
     printProcess(currency,labelNumber,acNumber,deliveryNumber,amount);
  }else if(this.state.deliveryNation=='SG'){
     currency='SGD$'
     labelNumber=Number(this.state.boxNumber)+1
     //deliveryNumber='SG'+this.state.poNumber
     //原本普仕特要加國別，現在不加
     deliveryNumber=this.state.poNumber
     acNumber=this.state.zipCode
     amount=Number((this.state.amount/22).toFixed(1))
     printProcess(currency,labelNumber,acNumber,deliveryNumber,amount);
  }else if(this.state.deliveryNation=='MO'){
    currency='NTD$'
    labelNumber=Number(this.state.boxNumber)+2
    //deliveryNumber='MO'+this.state.poNumber
    //原本普仕特要加國別，現在不加
    deliveryNumber=this.state.poNumber
    amount=Number((this.state.amount/1).toFixed(1))
    printProcess(currency,labelNumber,acNumber,deliveryNumber,amount);
  }else{
    //這一個部分，我們要處理日本的部分
    currency='NTD$'
    labelNumber=Number(this.state.boxNumber)+1
    //deliveryNumber='MO'+this.state.poNumber
    //原本普仕特要加國別，現在不加
    deliveryNumber=this.state.poNumber
    amount=Number((this.state.amount/1).toFixed(1))
    printProcess(currency,labelNumber,acNumber,deliveryNumber,amount);
  
  }  
 }
}

//品號的部分要處理一下，讓客人可以少輸幾個字
poNumberChange(e){
  var poNumber=e.target.value;
  this.setState({ 
    poNumber: poNumber,})
  
  if(poNumber.length>10){
    var poPredefinedNumber=poNumber.substr(0, 9)
    if(this.state.poPredefinedNumber!=poPredefinedNumber){
      this.setState({ 
        poPredefinedNumber:poPredefinedNumber });
    }
  }

}

//印製歷史標籤
historyExportFile(){
  var target= this.state.historyRecord
    
  var allPO=''

  ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+this.state.deliveryNation+'/'+this.state.userid+'/'+target).on('value', (snapshot)=> {
    //先把database值拿出來    
        if(snapshot.val()==null){
          this.setState({historyErrorMessage:'找不到資料'})
        }else{
          allPO=snapshot.val()
          var target={ 
            'receiverPhoneNumber':allPO.收件連絡電話,
            'contact':allPO.收件人姓名,
            'address':allPO.收件地址,
            'poNumber':allPO.poNumber, 
            'boxNumber':allPO.總箱數,
            'weight':allPO['總重量-公斤'],
            'amount':allPO.amount,
            'itemNumber':allPO.itemNumber,
            'zipCode':allPO.收件人郵遞區號,

            'historyRecord':'',
          }
          
          for(var i=0;i<allPO.itemNumber;i++){
            var number=i+1
            target['itemName'+number]=allPO['itemName'+number]
            target['itemNumber'+number]=allPO['itemNumber'+number]
            target['itemAmount'+number]=allPO['itemAmount'+number]
          }

          this.setState(target); 
                   
        }
  })

}

//刪除歷史資料
deleteRecord(){
  
  
  if(this.state.deleteRecord!==''){
    
    var target= this.state.deleteRecord
    var record=''

    ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+this.state.deliveryNation+'/'+this.state.userid+'/'+target).once('value', (snapshot)=> {
      if(snapshot.val()==null){
        this.setState({
          deleteRecordMessage:'查無此訂單，請確認國別和單號正確!',
        })
      }else{
        record=snapshot.val()
        ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+this.state.deliveryNation+'/'+this.state.userid+'/'+target).set({}).then((error) => {
          if (error) {
            this.setState({
              deleteRecordMessage:'無法刪除訂單',
            })
          } else {
            
            ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/deleteRecord/'+target).once('value', (snapshot)=> { 
              if(snapshot.val()==null){
                  //如果完全沒有紀錄，那我們就把紀錄寫進去
                  var deleteData=[]
                  deleteData.time=new Date().toLocaleString('zh-TW', {timeZone: 'Asia/Taipei'});
                  deleteData.PONumber=target;
                  deleteData.nation=this.state.deliveryNation;
                  deleteData.order=record

                  ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/deleteRecord/'+target).set(deleteData)
                  this.setState({
                    deleteRecordMessage:'已刪除訂單'+target,
                    deleteRecord:'',
                  })
              }else{
                  var node= ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/deleteRecord/'+target).push()
                  var deleteData=[]
                  deleteData.time=new Date().toLocaleString('zh-TW', {timeZone: 'Asia/Taipei'});
                  deleteData.PONumber=target;
                  deleteData.nation=this.state.deliveryNation;
                  deleteData.order=record

                  ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/deleteRecord/'+target+'/'+node.key).set(deleteData)
                  this.setState({
                    deleteRecordMessage:'已刪除訂單'+target,
                    deleteRecord:''
                  })

              }
            })

            ref.child('/infos/customers/'+this.state.customerNumber+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/contentCalculateBase/'+this.state.deliveryNation+'/'+this.state.userid+'/'+target).set({})
            
            
            
          }
        })
        //原本設計是把date裡面的刪掉，現在要保留對帳
        //ref.child('/infos/dates/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+this.state.deliveryNation+'/'+this.state.customerNumber+'/'+this.state.userid+'/'+target).remove();
        
        
      }
    })
    
   }
}

//把分項金額加起來，確認總額對不對
handleItemAmountChange(e){
  var num=this.state.itemNumber
  var target=e.target.name.slice(10,14)
  
  var totalAmount=0
  for(var i=0;i<num;i++){
    var number=i+1
    if(number==target){
      totalAmount=Number(totalAmount)+Number(e.target.value)
    }else{
      totalAmount=Number(totalAmount)+Number(this.state['itemAmount'+number]) 
    }
    
  }
  this.setState({
    [e.target.name]: e.target.value,
    amount:totalAmount,
  })

}

ExportListFile(){
  var nation=this.state.deliveryNation

  ref.child('/infos/customers/80071'+'/'+this.state.year+'/'+this.state.month+'/'+this.state.day+'/'+nation+'/'+this.state.userid).on('value', (snapshot)=> {
    let PoInfo=snapshot.val();
    let POs = [];
    let title=[
        "跨境通提單號碼",              //A
        "訂單號碼",          //B
        "件數",                  //C
        "收件人",              //D
        "地址",           //E
        "電話",        //F
        "內容",               //G
        "重量",    //H
        "價值 NT$",            //I
        "郵遞區號",           //J
        
      ];

     POs.push(title);

     Object.keys(PoInfo).forEach(function(key) {
           var po = PoInfo[key];
           //console.log(typeof po.內容物);
           
           if (typeof po.內容物 =='string'){
                let poArray = [
                  po.快遞單號,                 
                  po.訂單單號,                 
                  po.總箱數, 
                  po.收件人姓名,
                  po.收件地址,
                  po.收件連絡電話,
                  po.內容物,
                  Number(po['總重量-公斤']),                  
                  po['報關總值'],     
                  po.收件人郵遞區號,                    
                 
                ];
                POs.push(poArray);
            }else{
                
            }
  
   });

    //創造一張新的表
    const wb = XLSX.utils.book_new();
    //下面這句就是把POs放進我們的sheet裡面
    const wsAll = XLSX.utils.aoa_to_sheet(POs);
    //把Sheet放進我們的Excel檔案裡面
    XLSX.utils.book_append_sheet(wb, wsAll, "All POs");

    //請參考https://github.com/SheetJS/js-xlsx#writing-workbooks ,這裡是要讓客人可以選擇儲存路徑
    var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'array'});
    //利用saveAs就可以把這個檔案存出來
    saveAs(new Blob([wbout],{type:"application/octet-stream"}),`${this.state.today}-${this.state.deliveryNation}-標籤輸入總表.xlsx`);
    
    //下面這句語法是xlsx比較簡單的下載語法，不能選擇儲存路徑
    //XLSX.writeFile(wb, `${this.state.Today}-${this.state.CustomerNumber}-SG2訂單.xlsx`);

  })

  

}


render() {

     //下面是報關內容的填寫介面
     var itemNumber=this.state.itemNumber;
     var itemInput = [];
     for(var i=0;i<itemNumber;i++){
         
         var number=i+1
         itemInput.push(
                       <Form.Group key={i}  style={{ height: '2.2em',}} >
                             <label style={{'font-size':'12px' }}>{number}</label>
                             <Form.Field>
                                 <label style={{'font-size':'12px' }}>品名</label>
                                 <input style={{'font-size':'14px', height: '2em',width:100,'font-weight':'bold'}} placeholder='請輸入品名' value={this.state['itemName'+number]} name={'itemName'+number} onChange={this.handleInputChange}/>
                             </Form.Field>
                           
                             <Form.Field>
                                 <label style={{'font-size':'12px' }}>數量</label>
                                 <input style={{'font-size':'14px', height: '2em',width:80 ,'font-weight':'bold'}} placeholder='件數' value={this.state['itemNumber'+number]} name={'itemNumber'+number} onChange={this.handleInputChange}/>
                             </Form.Field>

                             <Form.Field>
                                 <label style={{'font-size':'12px' }}>報關價值$NT</label>
                                 <input style={{'font-size':'14px', height: '2em',width:100,'font-weight':'bold' }} placeholder='台幣金額' value={this.state['itemAmount'+number]} name={'itemAmount'+number} onChange={this.handleItemAmountChange}/>
                             </Form.Field>
                             
                      </Form.Group>
         )    　
     }
    return (

      <div style={{'font-weight':'bold','background-color': 'rgb(224, 221, 27)'}}>
        <div style={{ 'padding-top': '0em','padding-left': '2em','padding-right': '2em'}}>
           <Header as='h1'>您好，普仕達。</Header>
        </div>
              
        <div style={{ 'padding-top': '0.5em','padding-left': '17em','padding-right': '16em'}}>
          <Grid  divided='vertically' centered >
              <Grid.Row columns={1}>  
                  <Grid.Column style={{ 'padding-top':'0em','padding-bottom':'0em', }}>
                  
                    <Segment raised>  
                          <Label as='a' color='red' ribbon>
                          請輸入寄件資料
                          </Label>
                          
                          <Form style={{'font-size':'16px'}}>
                              <Form.Group widths='equal' id='shipperForm' style={{ height: '2em'}}> 
                                  <Form.Field>
                                      <label style={{'font-size':'12px'}}>國家別</label>
                        
                                      <Form.Group style={{ 'padding-right': '0em'}} >
                                      <Form.Field
                                          control={Radio}
                                          label='香港'
                                          value='HK'
                                          checked={this.state.deliveryNation=== 'HK'}
                                          onChange={this.changeDeliveryNation}
                                          style={{'font-size':'18px'}}
                                      />
                                      <Form.Field
                                          control={Radio}
                                          label='馬來西亞'
                                          value='MY'
                                          checked={this.state.deliveryNation=== 'MY'}
                                          onChange={this.changeDeliveryNation}
                                          style={{'font-size':'18px'}}
                                      />
                                      <Form.Field
                                          control={Radio}
                                          label='新加坡'
                                          value='SG'
                                          checked={this.state.deliveryNation=== 'SG'}
                                          onChange={this.changeDeliveryNation}
                                          style={{'font-size':'18px'}}
                                      />
                                      <Form.Field
                                          control={Radio}
                                          label='澳門'
                                          value='MO'
                                          checked={this.state.deliveryNation=== 'MO'}
                                          onChange={this.changeDeliveryNation}
                                          style={{'font-size':'18px'}}
                                      />
                                      
                                      <Form.Field
                                          control={Radio}
                                          label='日本'
                                          value='JP'
                                          checked={this.state.deliveryNation=== 'JP'}
                                          onChange={this.changeDeliveryNation}
                                          style={{'font-size':'18px'}}
                                      />
                                      
                                      </Form.Group>
                                  </Form.Field> 
                              </Form.Group>
                              <Form.Group widths='equal'  style={{ height: '2.5em'}}> 
                                  <Form.Field>
                                      <label style={{'font-size':'12px'}}>收件人電話</label>
                                      <input style={{'font-size':'14px', height: '2em',width:'50%','font-weight':'bold' ,padding: '0.3em'}}  placeholder='電話' value={this.state.receiverPhoneNumber} name="receiverPhoneNumber" onChange={this.handlePhoneNumberChange} />
                                  </Form.Field> 
                              </Form.Group>

                              <Form.Group widths='equal'  style={{ height: '2.5em'}}> 
                                  <Form.Field>
                                      <label style={{'font-size':'12px'}}>收件人姓名</label>
                                      <input style={{'font-size':'14px', height: '2em',width:'50%','font-weight':'bold' ,padding: '0.3em'}} placeholder='姓名' value={this.state.contact}  name="contact" onChange={this.handleInputChange} />
                                  </Form.Field>
                                  {/* 日本功能*/}
                                  {this.state.deliveryNation==='JP'?
                                    <Form.Field>
                                    <label style={{'font-size':'12px'}}>收件人英文姓名</label>
                                    <input style={{'font-size':'14px', height: '2em',width:'50%','font-weight':'bold' ,padding: '0.3em'}} placeholder='英文姓名' value={this.state.contactENG}  name="contactENG" onChange={this.handleInputChange} />
                                    </Form.Field>
                                    :
                                    "" 
                                  }    
                              </Form.Group>

                              
                              {/* 日本功能*/}
                              {this.state.deliveryNation==='JP'?
                                   <Form.Group  style={{ height: '2.5em'}}>
                                      <Form.Field width={10}>
                                        <label style={{'font-size':'12px'}}>收件人地址(不含門牌)</label>
                                        <input style={{'font-size':'14px', height: '2em',width:'100%','font-weight':'bold',padding: '0.3em' }} placeholder='地址' value={this.state.address} name="address" onChange={this.handleInputChange} />
                                      </Form.Field>
                                      <Form.Field  width={6}>
                                        <label style={{'font-size':'12px'}}>門牌、室號(或建築物名稱)</label>
                                        <input style={{'font-size':'14px', height: '2em',width:'50%','font-weight':'bold',padding: '0.3em'}} placeholder='門牌號' value={this.state.doorNumber} name="doorNumber" onChange={this.handleInputChange} />                          
                                      </Form.Field>
                                    </Form.Group>
                                    :
                                    <Form.Group widths='equal' style={{ height: '2.5em'}}>
                                        <Form.Field>
                                          <label style={{'font-size':'12px'}}>收件人地址</label>
                                          <input style={{'font-size':'14px', height: '2em',width:'100%','font-weight':'bold',padding: '0.3em' }} placeholder='地址' value={this.state.address} name="address" onChange={this.handleInputChange}/>
                                        </Form.Field> 
                                    </Form.Group>
                              }
                                  
                              
                             
                              <Form.Group widths='equal' >
                                  {/* 日本功能*/}
                                  {this.state.deliveryNation==='JP' || this.state.deliveryNation==='SG'|| this.state.deliveryNation==='MY'?
                                      <Form.Field>
                                      <label style={{'font-size':'12px'}}>郵遞區號(SG、JP、MY必填)</label>
                                      <input  placeholder='郵遞區號' value={this.state.zipCode} name="zipCode" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                      </Form.Field>
                                  :
                                     ""


                                  }
                                  
                                  <Form.Field>
                                      <label style={{'font-size':'12px'}}>出貨單號</label>
                                      <input  placeholder='單號' value={this.state.poNumber} name="poNumber" onChange={this.poNumberChange} style={{ padding: '0.3em'}}/>
                                  </Form.Field>
                                  <Form.Field>
                                      <label style={{'font-size':'12px'}}>件數</label>
                                      <input placeholder='件數' value={this.state.boxNumber}  name="boxNumber" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                  </Form.Field>
                              
                                  <Form.Field>
                                      <label style={{'font-size':'12px'}}>重量-KGs</label>
                                      <input placeholder='重量' value={this.state.weight}  name="weight" onChange={this.handleInputChange} style={{ padding: '0.3em'}}/>
                                  </Form.Field>

                                  <Form.Field>
                                      <label style={{'font-size':'12px'}}>申報價值-NT$</label>
                                      <Label color='green' style={{'font-size':'15px',}}>{this.state.amount}</Label>
                                      
                                  </Form.Field>
                              </Form.Group>
                              <Form.Group>
                                    <Button icon='plus' label='加一個品項'  size='mini' color='orange' onClick={this.addItem} style={{ 'font-size':'10px'}}/>
                                    <Button icon='minus' label='減一個品項'  size='mini' color='blue' onClick={this.cutItem} style={{ 'font-size':'10px'}}/>
                              </Form.Group>
                             
                              {itemInput}
                              <Form.Group style={{ 'padding-top':'1em'}}>
                                    <Button icon='print' label='印製標籤' size='mini' color='red' onClick={this.print} style={{ 'font-size':'10px'}}/> 
                              </Form.Group>
                              {this.state.printErrorMessage?
                                <Label style={{ width: 130,'padding-right':'1em'}}> {this.state.printErrorMessage}</Label>
                                :
                                <div></div>
                         }
                              
                          </Form>
                    
                    </Segment>
                 
              </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                  <Grid.Column>
                  <Segment raised>
                      <Label as='a' color='red' ribbon>
                          印製歷史標籤
                      </Label>
                      <Form style={{'font-size':'18px'}}>
                      
                        <Form.Group widths='equal' id='shipperForm' style={{ height: '2.5em','padding-top': '0.5em'}}> 
                          <Label color='green'style={{ width: 130,'padding-right':'1em'}}>請先在上面點選國別</Label>
                          <Label color='orange'style={{ width: 130,'padding-right':'1em'}}>再輸入完整出貨單號，重印標籤</Label>
                          <Input
                            action={{
                              content:'輸入',
                              onClick: () => this.historyExportFile()
                            }}
                            name='historyRecord'
                            value={this.state.historyRecord}
                            onChange={this.handleInputChange}
                            
                          />
                        </Form.Group>
                        {this.state.historyErrorMessage?
                                <Label style={{ width: 130,'padding-right':'1em'}}> {this.state.historyErrorMessage}</Label>
                                :
                                <div></div>
                         }
                        
                      </Form>
                  </Segment>
                  </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={1}>
                  <Grid.Column>
                  <Segment raised>
                      <Label as='a' color='pink' ribbon>
                           列印今日快遞總表
                      </Label>
                      <Form style={{'font-size':'18px'}}>
                      
                        <Form.Group widths='equal' id='shipperForm' style={{ height: '2.5em','padding-top': '0.5em'}}> 
                          <Label color='green'style={{ width: 130,'padding-right':'1em'}}>請先在上面點選國別</Label>
                          <Button icon='print' label='Print'  size='mini' color='red' onClick={this.ExportListFile} style={{ 'font-size':'10px'}}/> 
                           
                        </Form.Group>
                      </Form>
                  </Segment>
                  </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={1}>
                  <Grid.Column>
                  <Segment raised>
                      <Label as='a' color='black' ribbon>
                          刪除訂單資料
                      </Label>
                      <Form style={{'font-size':'18px'}}>
                      
                        <Form.Group widths='equal' id='shipperForm' style={{ height: '2.5em','padding-top': '0.5em'}}> 
                          <Label color='green'style={{ width: 130,'padding-right':'1em'}}>請先在上面點選國別</Label>
                          <Label color='orange'style={{ width: 130,'padding-right':'1em'}}>再輸入完整出貨單號，刪除資料</Label>
                          <Input
                            action={{
                              content:'輸入',
                              onClick: () => this.deleteRecord()
                            }}
                            value={this.state.deleteRecord}
                            name='deleteRecord'
                            onChange={this.handleInputChange}
                            
                          />
                           
                        </Form.Group>
                         {this.state.deleteRecordMessage?
                                <Label style={{ width: 130,'padding-right':'1em'}}> {this.state.deleteRecordMessage}</Label>
                                :
                                <div></div>
                         }
                      </Form>
                  </Segment>
                  </Grid.Column>
              </Grid.Row>


              
            </Grid>
        </div>

      </div>




    );
  }
}

export default App;
